import { forEach, keyBy, reduce } from 'lodash';
import {
  Okr,
  OkrItem,
  OkrKeyResult,
  OkrKeyResultItem,
  OkrShortItem,
  OkrTask,
  OkrTimelineTree
} from 'src/app/shared';
import { OkrSelectedItem, TimelineTreeItem } from './okr.model';

enum OkrType {
  Objective = 'Objective',
  KeyResult = 'KeyResult',
  Task = 'Task'
}

const getOkrType = (item: OkrItem): OkrType => {
  if (item.objectiveType) {
    return OkrType.Objective;
  }
  if (item.type) {
    return OkrType.KeyResult;
  }
  return OkrType.Task;
};

export const mapOkrListToTreeList = (list: OkrItem[]) => {
  const tree: Okr[] = [];
  const itemTree: OkrItem[] = [];
  const lookup = keyBy(list, 'id');


  forEach(list, (item) => {
    const parentId: number = reduce(
      item.pathIds,
      (res, curId) => (curId === item.id ? res : curId),
      null
    );
    const p = lookup[parentId];
    if (p) {
      p.children = p.children || [];
      p.children.push(item);
      p.children.sort((a, b) => a.orderNumber - b.orderNumber);
    } else {
      itemTree.push(item);
    }
  });

  const mapTask = (okr: OkrItem, parent: Okr) => {
    // TODO: task linked KeyResult
    if (okr.keyResult) {
      const keyResult = parent.keyResults?.find(
        (e) => e.id === okr.keyResult.id
      );
      const currentItem: OkrKeyResultItem = mapOkrItemToKeyResultItem(okr);

      if (keyResult) {
        keyResult.items.push(currentItem);
        return;
      }

      // add KeyResult
      parent.keyResults.push(okr.keyResult);
      okr.keyResult.items = [currentItem];
      return;
    }
    // TODO: task linked Objective
    parent.tasks.push(mapOkrItemToOkrTask(okr));
  };

  const mapKeyresult = (okr: OkrItem, parentOkr: Okr, parent?: OkrItem) => {
    const keyResult = parentOkr.keyResults?.find(
      (e) => e.id === okr.keyResult?.id
    );
    // TODO: if exists
    if (keyResult) {
      return;
    }
    const currentItem: OkrKeyResult = mapOkrItemToKeyResult(okr);
    currentItem.okr = parent ? mapOkrItemToObjective(parent) : null;
    // TODO: add keyResult
    parentOkr.keyResults.push(currentItem);
  };

  const mapObjective = (okr: OkrItem, parent?: OkrItem) => {
    const currentItem: Okr = mapOkrItemToObjective(okr);
    currentItem.parent = parent ? mapOkrItemToObjective(parent) : null;

    forEach(okr.children, (item) => {
      const type = getOkrType(item);
      if (type === OkrType.Objective) {
        currentItem.children.push(mapObjective(item, okr));
      }
      if (type === OkrType.KeyResult) {
        mapKeyresult(item, currentItem);
      }
      if (type === OkrType.Task) {
        mapTask(item, currentItem);
      }
    });

    currentItem.children = currentItem.children.sort(
      (a, b) => a.orderNumber - b.orderNumber
    );
    return currentItem;
  };

  forEach(itemTree, (item) => {
    const type = getOkrType(item);
    if (type === OkrType.Objective) {
      tree.push(mapObjective(item));
    }
  });
  return tree;
};

export const mapOkrItemListToOKRList = (list: OkrItem[]): OkrSelectedItem[] =>
  list.map(item => {
    const type = getOkrType(item);

    return type === OkrType.Objective
      ? { objective: mapOkrItemToObjective(item) }
      : type === OkrType.KeyResult
      ? { kr: mapOkrItemToKeyResult(item) }
      : type === OkrType.Task
      ? { task: mapOkrItemToOkrTask(item) }
      : {};
  });

export const mapOkrItemToObjective = (objective: OkrItem): Okr => {
  const okr = objective.linkedOkr || objective;
  return {
    id: objective.id,
    objectiveType: okr.objectiveType,
    name: okr.name,
    key: okr.key,
    description: okr.description,
    dueDate: okr.dueDate,
    resultType: okr.resultType,
    start: okr.start,
    current: okr.current,
    expected: okr.expected,
    justify: okr.justify,
    weight: okr.weight,
    progress: okr.progress,
    timeline: okr.timeline,
    childTimelines: objective.childTimelines,
    countChildTimelines: objective.countChildTimelines,
    assignee: okr.assignee,
    stakeHolder: okr.stakeHolder,
    createBy: okr.createBy,
    okrTeams: okr.okrTeams,
    okrGroups: okr.okrGroups,
    krWeight: okr.krWeight,
    metric: objective.metric,
    riskLevel: okr.riskLevel,
    percentValue: okr.percentValue,
    totalValue: okr.totalValue,
    type: okr.type,
    orderNumber: objective.orderNumber,
    isPopulate: okr.isPopulate,
    isEditable: okr.isEditable,
    workspace: okr.timeline?.workspace,
    indicator: okr.indicator,
    category: okr.category,
    hasChild: null,
    attachments: [],
    linkedOkr: objective.linkedOkr ? { ...objective.linkedOkr }: null,
    // ref
    children: [],
    keyResults: [],
    tasks: [],
    parent: null,
    path: [],
    //
  };
};

export const mapOkrItemToKeyResult = (kr: OkrItem): OkrKeyResult => {
  const okr = kr.linkedKeyResult || kr;

  return {
    id: kr.id,
    key: okr.key,
    name: okr.name,
    description: okr.description,
    dueDate: okr.dueDate,
    resultType: okr.resultType,
    start: okr.start,
    current: okr.current,
    expected: okr.expected,
    weight: okr.weight,
    itemsWeight: okr.itemsWeight,
    progress: okr.progress,
    deleted: false,
    timeline: kr.timeline,
    assignee: okr.assignee,
    stakeHolder: okr.stakeHolder,
    createBy: okr.createBy,
    okrTeams: okr.okrTeams,
    okrGroups: okr.okrGroups,
    metric: kr.metric,
    riskLevel: okr.riskLevel,
    percentValue: okr.percentValue,
    totalValue: okr.totalValue,
    attachments: [],
    type: okr.type,
    orderNumber: kr.orderNumber,
    indicator: okr.indicator,
    category: okr.category,
    totalCheckin: null,
    objectiveType: okr.objectiveType,
    justify: okr.justify,
    isEditable: okr.isEditable,
    linkedKeyResult: kr.linkedKeyResult ? { ...kr.linkedKeyResult }: null,
    //
    okr: null,
    items: []
  };
};

export const mapOkrItemToOkrTask = (okr: OkrItem): OkrTask => {
  return {
    id: okr.id,
    taskId: okr.taskId,
    assignee: okr.assignee,
    current: okr.current,
    percentValue: okr.percentValue,
    description: okr.description,
    key: okr.key,
    name: okr.name,
    weight: okr.weight,
    typeIcon: okr.typeIcon,
    metricValue: okr.expected,
    metric: okr.metric,
    orderNumber: okr.orderNumber,
    startMetricValue: okr.start,
    currentMetricValue: okr.current,
    dueDate: okr.dueDate,
    timelineId: okr.timeline?.id,
    isEditable: okr.isEditable,
    projectId: okr.projectId,
  };
};

export const mapOkrItemToKeyResultItem = (okr: OkrItem): OkrKeyResultItem => {
  return {
    id: okr.id,
    current: okr.current,
    weight: okr.weight,
    key: okr.key,
    assignee: okr.assignee,
    task: mapOkrItemToOkrTask(okr),
    orderNumber: okr.orderNumber,
    projectId: okr.projectId,
  };
};

export const filterOkrTreeByTimeline = (okr: Okr, timelineId: number) => {
  if (!okr?.childTimelines?.some((e) => e.id === timelineId)) {
    return;
  }
  const children = [];
  okr.children.forEach((e) => {
    const child = filterOkrTreeByTimeline(e, timelineId);
    if (child) {
      children.push(child);
    }
  });
  const keyResults = okr.keyResults.filter(
    (e) => e.timeline?.id === timelineId
  );
  const tasks = okr.tasks.filter((e) => e.timelineId === timelineId);
  const _okr = { ...okr, children, keyResults, tasks };
  return _okr;
};

export const getOkrCacheKeyBySpace = (cachekey: string, spaceId: number) => {
  return `${cachekey}:space:${spaceId}`;
};

export const mapTimelineTreeToLocalTree = (tree: OkrTimelineTree): TimelineTreeItem => {
  return {
    id: tree.entity?.id,
    title: tree.entity?.title,
    startDate: tree.entity?.startDate,
    endDate: tree.entity?.endDate,
    timelineCategory: tree.entity?.timelineCategory,
    children: tree.children?.map(mapTimelineTreeToLocalTree),
  }
}

export const getOkrFilterCacheKeyBySpace = (
  cachekey: string,
  spaceId: number
) => {
  return `${cachekey}:spaceFilter:${spaceId}`;
};

export const mappingLinkedOkr = (objective: Okr, linked: Okr): Okr => {
  return {
    ...objective,
    ...linked,
    metric: linked.metric || objective.metric,
    id: objective.id,
    linkedOkr: objective.linkedOkr,
    orderNumber: objective.orderNumber,
    children: objective.children,
    keyResults: objective.keyResults,
    tasks: objective.tasks,
    childTimelines: objective.childTimelines,
  };
};

export const mappingLinkedKr = (kr: OkrKeyResult, linked: OkrKeyResult): OkrKeyResult => {
  return {
    ...kr,
    ...linked,
    id: kr.id,
    items: kr.items,
    isEditable: kr.isEditable,
    timeline: kr.timeline,
    orderNumber: kr.orderNumber,
    linkedKeyResult: kr.linkedKeyResult,
  };
};
