<div class="columns-dropdown-style-wrapper">
  <x-dropdown class="columns-dropdown-wrapper"
              [icon]="'add-column'"
              [iconType]="type.LOCAL"
              [tooltip]="'Custom Column'">
    <div class="header mt-2">
      <x-search-input (keywordChange)="onSearchColumn($event)"></x-search-input>
    </div>
    <div cdkDropList
         class="option-list"
         (cdkDropListDropped)="drop($event)">
      <ng-container *ngFor="let column of dropdownColumns">
        <div class="custom-option relative !px-3 py-2"
             cdkDrag
             [cdkDragDisabled]="!column.draggable"
             CdkDragPreview
             cdkDragBoundary=".option-list"
             [ngClass]="{ hide: !(keyword | isVisibleColumn : column),'!px-4':!column.draggable  }"
             (click)="updateColumn(column)">
          <span cdkDragHandle
                *ngIf="column.draggable"
                class="material-icons-outlined drag-indicator icon-md handle-drag-icon"> drag_indicator</span>
          <span class="custom-checkbox-input"
                [ngClass]="{ checked: column?.selected !== false }"></span>
          <span class="checkbox-label ml-2">
            <x-i18n [key]="column.name"></x-i18n>
          </span>
        </div>
      </ng-container>
    </div>
  </x-dropdown>
</div>