import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router, RoutesRecognized } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter, map } from 'rxjs/operators';
import { appDefaultLang, appLanguages, CURRENT_LANGUAGE, LanguageCode } from './shared';
import { environment } from 'src/environments/environment';
import { SeoService } from './core';
import { routeLandingSeo } from './shared';
import { startsWith } from 'lodash';
import { SvgIcons } from './core/constants/svg';
import { TaskQueryParamStore } from './site-management/tasks/_services/task-query-param.store';
import { provideComponentStore } from '@ngrx/component-store';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'xcorp';
  svgIcons = SvgIcons;
  version = environment.version;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private translateService: TranslateService,
    private seoService: SeoService
  ) {

    this.svgIcons.forEach((icon: string) => {
      this.matIconRegistry.addSvgIcon(
        icon,
        this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/icons/${icon}.svg`)
      );
    });

    this._setLanguage();
  }

  ngOnInit() {
    this.setUpAnalytics();
    this.replaceParameterWithLanguage();
  }


  replaceParameterWithLanguage() {
    this.router.events.subscribe(res => {
      if (res instanceof NavigationStart) {
        const language = this.translateService.currentLang;

        if (!startsWith(res.url, `/${language}`)) {
          this.router.navigateByUrl(`/${language}${res.url}`);
        }
      }
    });
  }

  getOgImageUrl(img: string) {
    return `${environment.clientUrl}/assets/img/landing-pages/seo/${img ? img : routeLandingSeo.home.seoImage}`;
  }

  setUpAnalytics() {
    this.router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      map(e => this.activatedRoute),
      map((route) => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter((route) => route.outlet === 'primary')
    ).subscribe(route => {
      const snapShot = route.snapshot;
      const title = snapShot.data?.seoTitle;
      const ogDesc = snapShot.data?.seoDescription || routeLandingSeo.home.seoDescription;
      const ogImage = this.getOgImageUrl(snapShot.data?.seoImage);

      this.seoService.setTitle(title ? `${title}` : 'xCorp');
      this.seoService.updateOgImage(ogImage);
      this.seoService.updateOgDescription(ogDesc);

      // 2. Push data to GTM (dataLayer)
      const pageLocation = window.location.href; // Full URL
      if ((window as any).dataLayer) {
        (window as any).dataLayer.push({
          event: 'custom_page_view',  
          custom_page_title: title ? `${title}` : 'xCorp',       // Custom variable for page title
          custom_page_location: pageLocation // Custom variable for full URL
        });
      } else {
        console.warn('[GTM] dataLayer not found');
      }
    });
  }

  private _setLanguage() {
    this.translateService.addLangs(appLanguages.map(e => e.code));
    this.translateService.setDefaultLang(appDefaultLang);

    const langCodeUrl = window.location.pathname.split('/')?.[1];
    const langCodes = this.translateService.getLangs();
    if (langCodeUrl && langCodes.includes(langCodeUrl)) {
      localStorage.setItem(CURRENT_LANGUAGE, langCodeUrl);
    }

    const currentLanguage = localStorage.getItem(CURRENT_LANGUAGE) || appDefaultLang;
    this.translateService.use(currentLanguage).subscribe();
  }
}
