import { Store } from '@ngrx/store';
import { TableColumn } from '@swimlane/ngx-datatable';
import { filter, Subject, take, takeUntil } from 'rxjs';
import { AppInjector } from 'src/app/app.module';
import { CustomListColumn } from 'src/app/shared';
import {
  selectUserSettingState,
  UserSettingAction
} from 'src/app/site-management/_store/user-setting';
import { UserSettingState } from 'src/app/site-management/_store/user-setting/user-setting.model';

export class ColumnUpdater {
  public columnSettings: CustomListColumn[] = [];
  public isEmpty: boolean;
  public emptyText =
    'All columns are hidden. Adjust the column settings to display them.';

  private _config?: Record<string, boolean>;
  private cacheKey: string;
  private store = AppInjector.get(Store<UserSettingState>);

  destroyed$ = new Subject<void>();

  get config() {
    return this._config;
  }

  set config(config: Record<string, boolean>) {
    this._config = config;

    this.isEmpty = !this.columnSettings.some((e) => config?.[e.key] !== false);
  }

  constructor(
    key: string,
    destroyed: Subject<void>,
    defaultConfig?: Record<string, boolean>
  ) {
    this.cacheKey = key;
    if (destroyed) {
      this.destroyed$ = destroyed;
    }

    if (defaultConfig) {
      this.config = defaultConfig;
    }
  }

  // get userSetting
  getConfig(callback?: (config: Record<string, boolean>) => void) {
    if (!this.cacheKey) {
      callback?.(this.config);
      return;
    }

    this.store.dispatch(
      UserSettingAction.getUserSetting({ payload: this.cacheKey })
    );
    this.store
      .select(selectUserSettingState(this.cacheKey))
      .pipe(
        filter((e) => e),
        take(1),
        takeUntil(this.destroyed$)
      )
      .subscribe((value) => {
        this.config = JSON.parse(value);
        this.columnSettings.forEach(
          (e) => (e.selected = this.config?.[e.key] !== false)
        );
        callback?.(this.config);
      });
  }

  // update config
  setConfig(config: Record<string, boolean>) {
    this.config = config;

    if (!this.cacheKey) {
      return;
    }

    // update cache
    this.store.dispatch(
      UserSettingAction.updateUserSetting({
        payload: {
          key: this.cacheKey,
          value: JSON.stringify(config)
        }
      })
    );
  }

  setColumns(columnSettings: CustomListColumn[]) {
    this.columnSettings = columnSettings?.map((e) => {
      return {
        ...e,
        selected: this.config?.[e.key] !== false
      };
    });
    this.isEmpty = !this.columnSettings.some(
      (e) => this.config?.[e.key] !== false
    );
  }

  setColumnFromNgxTable(
    columns: TableColumn[],
    config?: Record<string, boolean>
  ) {
    this.setColumns(
      columns.map((e) => ({
        name: e.name || (e.prop as string),
        key: e.prop as string,
        selected: config?.[e.prop] !== false
      }))
    );
  }

  // get NgxTable
  getNgxTableColumn(columns: TableColumn[], config?: Record<string, boolean>) {
    const newColumns = columns.filter(
      (column) => (config || this.config)?.[column?.prop] !== false
    );

    if (newColumns.length === 1) {
      newColumns[0].maxWidth = undefined;
    }
    return newColumns;
  }

  // get column cacheKey
  static getCacheKey(screenName: string, tableName: string, id?: string) {
    return `column:${screenName}:${tableName}:${id || ''}`;
  }

  static getColumnConfig(columns: CustomListColumn[]) {
    return columns.reduce(
      (res, cur) => ({ ...res, [cur.key]: cur.selected }),
      {}
    );
  }
}
