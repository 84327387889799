import { DATE_FORMAT, DATE_UI_FORMAT, OKR_CURRENT_VALUE, Okr, OkrChildTimeline, OkrChildrenTimeline, OkrKeyResult, OkrObjectType, OkrResultType, OkrTimelineEntity, OkrTimelineTree, routerObject } from "src/app/shared";
import { OKR_ALL_YEAR_PERIOD, OKR_CUSTOM_PERIOD, OkrChildTimelineForm, OkrType, TimelineLevelType, TimelineTreeItem } from "./okr.model";
import { cloneDeep, union } from 'lodash';
import * as moment from "moment";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import * as queryString from 'query-string';

export class OkrUtils {
  static addTimeline(item: OkrTimelineTree, _level = 0) {
    if (!item) {
      return [];
    }
    let _timelines = [];
    if (item.entity) {
      item.entity._isParent = item.children?.length > 0;
      _timelines = [{ ...item.entity, _level }];
    }
    if (item.children?.length) {
      item.children.forEach(e => _timelines = [..._timelines, ...OkrUtils.addTimeline(e, _level + 1)]);
    }
    return _timelines;
  }

  static getSelectedTree(timelines: OkrTimelineEntity[], trees: OkrTimelineTree[], level = 0) {
    const _treeList: OkrTimelineTree[] = [];
    trees?.forEach(e => {
      if (timelines.some(timeline => timeline.id === e.entity?.id)) {
        const item: OkrTimelineTree = { entity: cloneDeep(e.entity), children: [] };
        item.entity._level = level;
        item.children = this.getSelectedTree(timelines, e.children, level + 1);
        if (item.children?.length) {
          item.children.forEach(e => e.entity._parentId = item.entity.id);
        }
        _treeList.push(item);
      }
      else {
        (this.getSelectedTree(timelines, e.children, level) || []).forEach(e => _treeList.push(e));
      }
    });
    return _treeList;
  }

  static getOkrTimelinePeriod(_year: number) {
    const periods = [];
    const now = new Date();
    const year = _year || now.getFullYear();

    const start = moment(year, 'YYYY').startOf('year').format(DATE_FORMAT);
    const end = moment(year, 'YYYY').endOf('year').format(DATE_FORMAT);
    const custom ={
      year,
      start,
      end,
      key: OKR_CUSTOM_PERIOD
    };

    if (!_year) {
      return [custom];
    }

    periods.push({
      year,
      start,
      end,
      key: OKR_ALL_YEAR_PERIOD
    });

    for (let i = 0; i < 4; ++i) {
      const start = moment(`${year}-${i * 3 + 1}-01`);
      periods.push({
        year,
        start,
        end: moment(start).endOf('quarter'),
        key: OkrUtils.getPeriodLabel(i + 1, year)
      });
    }
    periods.push(custom);
    return periods;
  }

  static getPeriodLabel(index: number, year: number) {
    return `Q${index}'${year}`;
  }

  static calculateStartValue(timelineValue: OkrChildTimelineForm[], index: number) {
    if (!timelineValue?.[index]) {
      return;
    }
    const currentTimeline = timelineValue[index].timeline;
    const next = timelineValue.reduce((res, cur) => {
      if (currentTimeline._parentId !== cur.timeline?._parentId || currentTimeline.id === cur.timeline?.id) {
        return res;
      }
      const startDate = moment(cur.timeline.startDate);
      const condition = startDate.isAfter(moment(currentTimeline.endDate)) && (!res || startDate.isBefore(moment(res?.timeline?.startDate)));
      if (condition) {
        return cur;
      }
      return res;
    }, null);
    if (next) {
      next.start = timelineValue[index].current;
    }
    const parentIndex = timelineValue.findIndex(e => e.timeline.id === timelineValue[index].timeline?._parentId);
    if (parentIndex !== -1) {
      timelineValue[parentIndex].current = timelineValue.reduce((res, cur) => cur.timeline?._parentId === timelineValue[index].timeline?._parentId ? res + cur.current : res, 0);
      return OkrUtils.calculateStartValue(timelineValue, parentIndex);
    }
  }

  static sortTimeline(tree: OkrTimelineTree) {
    if (tree.children?.length) {
      tree.children = tree.children.sort((a, b) => {
        return moment(a.entity.startDate).diff(moment(b.entity.startDate));
      })
      tree.children.forEach(e => OkrUtils.sortTimeline(e));
    }
    return tree;
  }


  static generateQuarter(startMonth, endMonth, year): TimelineTreeItem {
    const startDate = moment(year, 'YYYY').month(startMonth - 1).startOf('month');
    const endDate = moment(year, 'YYYY').month(endMonth - 1).endOf('month');

    return {
      title: `Quarter ${Math.ceil(startMonth / 3)}`,
      startDate: startDate.format(DATE_FORMAT),
      endDate: endDate.format(DATE_FORMAT),
      children: [],
      timelineCategory: TimelineLevelType.QUARTERLY
    };
  }

  static generateWeeks(startDate: moment.Moment, endDate: moment.Moment, parentName?: string): TimelineTreeItem[] {
    const weeks: TimelineTreeItem[] = [];
    let currentWeekStart: moment.Moment = startDate.clone();
    while (currentWeekStart.isSameOrBefore(endDate)) {
      let weekStart = currentWeekStart.clone().startOf('week');
      let weekEnd = currentWeekStart.clone().endOf('week');

      if (weekStart.isBefore(startDate)) {
        if (weekStart.year() === startDate.year()) {
          currentWeekStart.add(1, 'week');
          continue;
        }
        weekStart = startDate.clone();
      }

      if (weekEnd.isAfter(endDate) && weekEnd.year() !== endDate.year()) {
        weekEnd = endDate.clone();
      }

      let _week = weekStart.week();
      _week = (weekStart.month() === 11 && _week === 1) ? weekStart.isoWeek() + 1 : _week;
      const week = {
        title: `Week ${_week}`,
        startDate: weekStart.format(DATE_FORMAT),
        endDate: weekEnd.format(DATE_FORMAT),
        timelineCategory: TimelineLevelType.WEEKLY
      };

      weeks.push(week);
      currentWeekStart = weekStart;
      currentWeekStart.add(1, 'week');
    }

    return weeks;
  }

  static generateMonths(startDate: moment.Moment, endDate: moment.Moment): TimelineTreeItem[] {
    const months = [];
    let currentMonth = startDate.clone();

    while (currentMonth.isSameOrBefore(endDate)) {
      const month = {
        title: `Month ${currentMonth.month() + 1}`,
        startDate: currentMonth.startOf('month').format(DATE_FORMAT),
        endDate: currentMonth.endOf('month').format(DATE_FORMAT),
        children: [],
        timelineCategory: TimelineLevelType.MONTHLY
      };

      months.push(month);
      currentMonth.add(1, 'month');
    }

    return months;
  }

  static generateCustomTimeline(start: Date, end: Date): TimelineTreeItem {
    const startDate = moment(start);
    const endDate = moment(end);
    return {
      title: `${startDate.year()} - ${endDate.year()}`,
      startDate: startDate.format(DATE_FORMAT),
      endDate: endDate.format(DATE_FORMAT),
      children: [],
      timelineCategory: TimelineLevelType.MULTI_YEAR
    };
  }

  static generateYear(year: number): TimelineTreeItem {
    return {
      title: `${year}`,
      startDate: moment(year, 'YYYY').startOf('year').format(DATE_FORMAT),
      endDate: moment(year, 'YYYY').endOf('year').format(DATE_FORMAT),
      children: [],
      timelineCategory: TimelineLevelType.YEARLY
    };
  }

  static generateTreeTimeline(year: number, options: TimelineLevelType[]): TimelineTreeItem {
    const checkDaily = (tree: TimelineTreeItem) => {
      if (options.includes(TimelineLevelType.DAILY)) {
        OkrUtils.addDaily(tree);
      }
      return tree;
    }
    const tree = OkrUtils.generateYear(year);

    // semi-annual => quarterly => monthly => weekly
    if (options.includes(TimelineLevelType.SEMIANNUAL)) {
      for (let half = 1; half <= 2; half++) {
        const startMonth = (half - 1) * 6 + 1;
        const endMonth = startMonth + 5;
        const semiAnnualNode = OkrUtils.generateSemiAnnualTree(startMonth, endMonth, year, options);

        tree.children.push(semiAnnualNode);
      }
      return tree;
    }

    // quarterly => monthly => weekly
    if (options.includes(TimelineLevelType.QUARTERLY)) {
      for (let quarter = 1; quarter <= 4; quarter++) {
        const startMonth = (quarter - 1) * 3 + 1;
        const endMonth = startMonth + 2;
        const quarterNode = OkrUtils.generateQuarterTree(startMonth, endMonth, year, options);

        tree.children.push(quarterNode);
      }
      return tree;
    }

    // monthly => weekly
    if (options.includes(TimelineLevelType.MONTHLY)) {
      tree.children = this.generateMonths(moment(tree.startDate, DATE_FORMAT), moment(tree.endDate, DATE_FORMAT));

      if (options.includes(TimelineLevelType.WEEKLY)) {
        tree.children.forEach(monthNode => {
          monthNode.children = this.generateWeeks(moment(monthNode.startDate, DATE_FORMAT), moment(monthNode.endDate, DATE_FORMAT).endOf('month'), monthNode.title);
        });
      }

      return checkDaily(tree);
    }

    // weekly
    if (options.includes(TimelineLevelType.WEEKLY)) {
      tree.children = this.generateWeeks(moment(tree.startDate, DATE_FORMAT), moment(tree.endDate, DATE_FORMAT), tree.title);
    }

    return checkDaily(tree);
  }

  static addDaily(tree: TimelineTreeItem) {
    if (!tree.children?.length) {
      tree.children = OkrUtils.generateDays(tree.startDate, tree.endDate);
      return tree;
    }
    tree.children?.forEach(e => OkrUtils.addDaily(e));
    return tree;
  }

  static generateDays(startDate: string, endDate: string): TimelineTreeItem[] {
    const days = [];
    const start = moment(startDate, DATE_FORMAT);
    const end = moment(endDate, DATE_FORMAT);
    let currentDay = start;

    while (currentDay.isSameOrBefore(end)) {
      const day = {
        title: `${currentDay.format(DATE_UI_FORMAT)}`,
        startDate: currentDay.format(DATE_FORMAT),
        endDate: currentDay.format(DATE_FORMAT),
        timelineCategory: TimelineLevelType.DAILY
      }
      days.push(day);
      currentDay.add(1, 'day');
    }
    return days;
  }

  static generateSemiAnnual(startMonth: number, endMonth: number, year: number): TimelineTreeItem {
    const startDate = moment(year, 'YYYY').month(startMonth - 1).startOf('month');
    const endDate = moment(year, 'YYYY').month(endMonth - 1).endOf('month');

    return {
      title: `${startMonth <= 6 ? 'Jan-Jun' : 'Jul-Dec'}`,
      startDate: startDate.format(DATE_FORMAT),
      endDate: endDate.format(DATE_FORMAT),
      children: [],
      timelineCategory: TimelineLevelType.SEMIANNUAL
    };
  }

  static generateSemiAnnualTree(startMonth: number, endMonth: number, year: number, options: TimelineLevelType[]): TimelineTreeItem {
    const semiAnnualNode = OkrUtils.generateSemiAnnual(startMonth, endMonth, year);

    if (options.includes(TimelineLevelType.QUARTERLY)) {
      for (let month = startMonth; month <= endMonth; month += 3) {
        const startQuarterMonth = month;
        const endQuarterMonth = startQuarterMonth + 2;
        const quarterNode = OkrUtils.generateQuarterTree(startQuarterMonth, endQuarterMonth, year, options);
        semiAnnualNode.children.push(quarterNode);
      }
      return semiAnnualNode;
    }

    if (options.includes(TimelineLevelType.MONTHLY)) {
      semiAnnualNode.children = this.generateMonths(moment(semiAnnualNode.startDate, DATE_FORMAT), moment(semiAnnualNode.endDate, DATE_FORMAT));

      if (options.includes(TimelineLevelType.WEEKLY)) {
        semiAnnualNode.children.forEach(monthNode => {
          monthNode.children = this.generateWeeks(moment(monthNode.startDate, DATE_FORMAT), moment(monthNode.endDate, DATE_FORMAT).endOf('month'), monthNode.title);
        });
      }

      return semiAnnualNode;
    }

    if (options.includes(TimelineLevelType.WEEKLY)) {
      semiAnnualNode.children = this.generateWeeks(moment(semiAnnualNode.startDate, DATE_FORMAT), moment(semiAnnualNode.endDate, DATE_FORMAT), semiAnnualNode.title);
    }

    return options.includes(TimelineLevelType.DAILY) ? OkrUtils.addDaily(semiAnnualNode) : semiAnnualNode;
  }

  static generateQuarterTree(startMonth: number, endMonth: number, year: number, options: TimelineLevelType[]) {
    const quarterNode = OkrUtils.generateQuarter(startMonth, endMonth, year);

    if (options.includes(TimelineLevelType.MONTHLY)) {
      quarterNode.children = OkrUtils.generateMonths(moment(quarterNode.startDate, DATE_FORMAT), moment(quarterNode.endDate, DATE_FORMAT).endOf('month'));
    }
    if (options.includes(TimelineLevelType.WEEKLY)) {
      // monthly , weekly
      if (quarterNode.children?.length) {
        quarterNode.children.forEach(monthNode => {
          monthNode.children = OkrUtils.generateWeeks(moment(monthNode.startDate, DATE_FORMAT), moment(monthNode.endDate, DATE_FORMAT), monthNode.title);
        });
      }
      // weekly
      else {
        quarterNode.children = OkrUtils.generateWeeks(moment(quarterNode.startDate, DATE_FORMAT), moment(quarterNode.endDate, DATE_FORMAT), quarterNode.title?.split('-')?.[0]?.trim());
      }
    }
    return options.includes(TimelineLevelType.DAILY) ? OkrUtils.addDaily(quarterNode) : quarterNode;
  }


  static getPayload(event: CdkDragDrop<any>, rows: any[]) {
    if (event.currentIndex === event.previousIndex) {
      return;
    }
    const object = rows[event.previousIndex];
    const newIndex = rows.findIndex((e) => e.id === rows[event.currentIndex].id);
    moveItemInArray(rows, event.previousIndex, event.currentIndex);
    const preObject = rows[newIndex - 1] ?? null;
    const nextObject = rows[newIndex + 1] ?? null;
    return {
      object,
      preObject,
      nextObject,
    };
  }

  static checkOkrObjectType(item, okr: Okr) {
    if (!item) {
      return null;
    }
    if (okr.children.some(e => e.id === item.id) && item.objectiveType && item.resultType) {
      return OkrObjectType.OKR;
    }
    if (okr.keyResults.some(e => e.id === item.id) && !item.objectiveType && item.resultType) {
      return OkrObjectType.KR;
    }
    return OkrObjectType.TASK;
  }

  static getChildrenTimeline(item: TimelineTreeItem) {
    let items = [];
    if (!item.children?.length) {
      return [item];
    }
    item.children?.forEach(e => {
      items = [...items, ...OkrUtils.getChildrenTimeline(e)];
    })
    return items;
  }

  static selectTimelineOption(index: number, timelines: OkrTimelineEntity[], selecteds: OkrTimelineEntity[] = []) {
    let _timelines = [...selecteds, timelines[index]];
    let level = timelines[index]._level;
    for (let i = index - 1; i >= 0; i--) {
      let item = timelines[i];
      if (item._level === 0) {
        _timelines = union(_timelines, [item]);
        break;
      } else if (item._level < level) {
        level = item._level;
        _timelines = union(_timelines, [item]);
      }
    }
    for (let i = index + 1; i < timelines?.length; i++) {
      let item = timelines[i];
      if (item._level <= timelines[index]._level) {
        break;
      } else {
        _timelines.push(item);
      }
    }
    return _timelines;
  }

  static selectTimelineParentOption(index: number, timelines: OkrTimelineEntity[]) {
    let _timelines = [timelines[index]];
    let level = timelines[index]._level;
    for (let i = index - 1; i >= 0; i--) {
      let item = timelines[i];
      if (item._level === 0) {
        _timelines = union(_timelines, [item]);
        break;
      } else if (item._level < level) {
        level = item._level;
        _timelines = union(_timelines, [item]);
      }
    }
    return _timelines;
  }

  static selectTimelineChildOption(index: number, timelines: OkrTimelineEntity[]) {
    let _timelines = [timelines[index]];
    for (let i = index + 1; i < timelines?.length; i++) {
      let item = timelines[i];
      if (item._level <= timelines[index]._level) {
        break;
      } else {
        _timelines.push(item);
      }
    }
    return _timelines;
  }

  static getChildTimelinesPayload(childTimelines: OkrChildrenTimeline[], reset = false): OkrChildTimeline[] {
    return childTimelines?.map(e=> ({
      id: e.id,
      current: reset ? 0 : (e.current || 0),
      expected: reset ? 0 : (e.expected || 0),
      start: reset ? 0 : (e.start || 0),
      weight: reset ? 0 : (e.weight || 0),
      justify: reset ? 0 : (e.justify || 0),
    })) || [];
  }

  static getYears(range: number) {
    const years: number[] = [];
    const now = new Date();
    const year = now.getFullYear();
    for (let i = -range; i <= range; ++i) {
      years.push(year + i);
    }
    return years;
  }

  static getOkrLink(okr: Okr, timelineId: number) {
    const params = queryString.stringify({ timeline: timelineId })
    return `${window.location.origin}${routerObject.okrBoard.fullPath}/${okr.key}?${params}`;
  }

  static getKrLink(kr: OkrKeyResult) {
    return `${window.location.origin}${routerObject.okrBoard.fullPath}/key-result/${kr.key}`;
  }

  static onOkrDisabledByChildren(timelineId: number, okr: Okr) {
    const children = okr?.children?.filter(child => child.childTimelines.some(e => e.id === timelineId));
    const allFilledOkr = children?.length && children.every(child => {
      const timeline = child.childTimelines.find(e => e.id === timelineId);
      if (timeline.start || timeline.expectedActual || timeline.startActual || timeline.current || timeline.expected) {
        return true;
      }
    });
    const allFilledKeyResult = okr.keyResults?.length && okr.keyResults?.every(child => {
        if (child.start || child.current || child.expected) {
          return true;
        }
      });
    const allFilledTask = okr.tasks?.length && okr.tasks?.every(child => {
        return child.startMetricValue || child.currentMetricValue || child.metricValue;
      })

    if (allFilledOkr || allFilledKeyResult || allFilledTask) {
      return true;
    }
    return false;
  }

  static isIncludeCurrentDay(startDate, endDate) {
    return moment().isBetween(
      moment(startDate, DATE_FORMAT).startOf('day'),
      moment(endDate, DATE_FORMAT).endOf('day'),
      null,
      '[]'
    );
  }

  static isContainTimeline(start, end, parentStart, parentEnd) {
    const _start = moment(start, DATE_FORMAT);
    const _end = moment(end, DATE_FORMAT);
    const _parentStart = moment(parentStart, DATE_FORMAT);
    const _parentEnd = moment(parentEnd, DATE_FORMAT);
    return _start.isBetween(
      _parentStart.startOf('day'),
      _parentEnd.endOf('day'),
      null,
      '[]'
    ) || _end.isBetween(
      _parentStart.startOf('day'),
      _parentEnd.endOf('day'),
      null,
      '[]'
    );
  }

  static getTimeElapsed(startDate: string, endDate: string) {
    const start = moment(startDate).startOf('day');
    const end = moment(endDate).endOf('day');
    const current = moment();

    if (end.isBefore(current)) {
      return 100;
    }
    if (start.isAfter(current)) {
      return 0;
    }

    const totalDuration = end.diff(start, 'minutes');
    const elapsedDuration = current.diff(start, 'minutes');

    const percentage = (elapsedDuration / totalDuration) * 100;
    return percentage;
  }

  static isDay(startDate: string, endDate: string) {
    return startDate === endDate;
  }
  static isWeek(start: string, end: string) {
    const startDate = moment(start, DATE_FORMAT);
    const endDate = moment(end, DATE_FORMAT);
    const startOfWeek = startDate.clone().startOf('week');
    const endOfWeek = endDate.clone().endOf('week');
    const isStartOfWeek = (startOfWeek.year() === startDate.year() - 1 && startDate.startOf('year').format(DATE_FORMAT) === start) || startOfWeek.startOf('week').format(DATE_FORMAT)  === start;
    const isEndOfWeek =  (endOfWeek.year() === endDate.year() + 1 && endDate.endOf('year').format(DATE_FORMAT)  === end) || endDate.endOf('week').format(DATE_FORMAT) === end;
    return startDate.week() === endDate.week() && isStartOfWeek && isEndOfWeek;
  }
  static isMonth(start: string, end: string) {
    const startDate = moment(start, DATE_FORMAT);
    const endDate = moment(end, DATE_FORMAT);
    const isStartOfMonth = startDate.startOf('month').format(DATE_FORMAT) === start;
    const isEndOfMonth = endDate.endOf('month').format(DATE_FORMAT) === end;
    return startDate.month() === endDate.month() && isStartOfMonth && isEndOfMonth && startDate.year() === endDate.year();
  }
  static isQuarter(start: string, end: string) {
    const startDate = moment(start, DATE_FORMAT);
    const endDate = moment(end, DATE_FORMAT);
    const isStartOfQuarter = startDate.startOf('quarter').format(DATE_FORMAT) === start;
    const isEndOfQuarter = endDate.endOf('quarter').format(DATE_FORMAT) === end;
    return startDate.quarter() === endDate.quarter() && isStartOfQuarter && isEndOfQuarter && startDate.year() === endDate.year();
  }

  static isContainDay(startDate: string, endDate: string, currentDay: string) {
    if (startDate === currentDay || endDate === currentDay) {
      return true;
    }
    return moment(currentDay, DATE_FORMAT).isBetween(
      moment(startDate, DATE_FORMAT).startOf('day'),
      moment(endDate, DATE_FORMAT).endOf('day'),
      null,
      '[]'
    );
  }

  static isYearTimeline(startDate: string, endDate: string) {
    const start = moment(startDate, DATE_FORMAT);
    const end = moment(endDate, DATE_FORMAT);
    const isStartOfYear = moment().startOf('year').format(DATE_FORMAT) === start.format(DATE_FORMAT);
    const isEndOfYear = moment().endOf('year').format(DATE_FORMAT) === end.format(DATE_FORMAT);

    return isStartOfYear && isEndOfYear;
  }

  static onFilterTimeline(item: TimelineTreeItem, start: Date, end: Date) {
    const startDate = moment(item.startDate, DATE_FORMAT);
    const endDate = moment(item.endDate, DATE_FORMAT);
    if (moment(end).isBefore(startDate,'d') || endDate.isBefore(start, 'd')) {
      return null;
    }
    if (startDate.isBefore(start)) {
      item.startDate = moment(start).format(DATE_FORMAT);
    }
    if (endDate.isAfter(end)) {
      item.endDate = moment(end).format(DATE_FORMAT);
    }
    item.children = item.children?.filter(e => OkrUtils.onFilterTimeline(e, start, end));
    return item;
  }

  static endDateOfYear(year: number) {
    return moment().year(year).endOf('year').toDate();
  }

  static startDateOfYear(year: number) {
    return moment().year(year).startOf('year').toDate();
  }

  static getLinkedTimeline(timeline: OkrTimelineEntity, linked: Okr) {
    const childTimeline = linked.childTimelines.find(
      (e) =>
        e.startDate === timeline.startDate && e.endDate === timeline.endDate
    );
    return {
      ...timeline,
      id: childTimeline?.id ?? timeline.id,
      title: childTimeline?.title ?? timeline.title
    };
  }
}

export function defaultCurrentValue(value: any) {
  const _value = value === '' ? null : value;

  return _value ?? OKR_CURRENT_VALUE;
}
