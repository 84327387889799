import {
  ConnectionPositionPair,
  Overlay,
  OverlayConfig
} from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { ComponentPortal } from '@angular/cdk/portal';
import { BaseOverlay } from './abtract-overlay';
import { debounceTime } from 'rxjs/operators';
import { PositionOverlay, WithOffset } from 'src/@xcorp/constant';
import { XTrigger } from '../trigger/trigger.service';

@Injectable()
export class XOverlayService<T, K> extends BaseOverlay<T, K> {
  private overlayListeners: (() => void)[] = [];
  hoverComponent: boolean;
  hasComponentObserver: boolean;
  instance: T;

  constructor(protected overlay: Overlay) {
    super(overlay);
  }

  /**
   * Init component
   * @returns ComponentPortal
   */
  component(): ComponentPortal<T> {
    return null;
  }

  /**
   * Init Position
   * @returns ConnectionPositionPair[]
   */
  position(): ConnectionPositionPair[] {
    return [
      WithOffset(PositionOverlay.bottomCenter, 0, 10),
      WithOffset(PositionOverlay.topCenter, 0, -10)
    ];
  }

  /**
   * Init default config
   * @param element HTMLElement
   * @param position ConnectionPositionPair[]
   * @returns OverlayConfig
   */
  config(
    element: HTMLElement,
    position?: ConnectionPositionPair[]
  ): OverlayConfig {
    return {
      hasBackdrop: false,
      disposeOnNavigation: true,
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(element)
        .withPositions(position || this.position())
        .withFlexibleDimensions(true)
        .withViewportMargin(40)
        .withGrowAfterOpen(true)
        .withPush(false)
    };
  }

  /**
   * Open overlay
   * @param element
   * @param data
   * @param position
   * @returns void
   */
  open(
    element: HTMLElement,
    data: K,
    position?: ConnectionPositionPair[],
    trigger?: XTrigger<any>
  ): void {
    if (this.preventShow || this.isOpen) {
      return;
    }

    this.isOpen = true;
    this._element = element;

    this._overlayRef?.dispose(); // clean profile overlay when open new overlay
    this._overlayRef = this.overlay.create(this.config(element, position)); // create overlay with config

    this._componentRef = this._overlayRef.attach(this.component()); // add component to overlay

    this._componentRef.setInput('data', data);
    this._componentRef.setInput('overlay', this);
    this._componentRef.setInput('trigger', trigger);

    this.instance = this._componentRef.instance;

    this.observeEvents();
    this.onOpenComplete();
  }

  onOpenComplete() {
    if (this.hasComponentObserver) {
      this.observerComponent();
    }
  }

  observerComponent() {
    const overlayElement = this._overlayRef.overlayElement;
    this.overlayListeners.forEach(unlistener => unlistener());
    this.overlayListeners = [];

    const mouseEnterListener = () => {
      this.hoverComponent = true;
    };

    const mouseLeaveListener = () => {
      this.hoverComponent = false;
      this.hide();
    };

    overlayElement.addEventListener('mouseenter', mouseEnterListener);
    overlayElement.addEventListener('mouseleave', mouseLeaveListener);
    this.overlayListeners.push(() => overlayElement.removeEventListener('mouseenter', mouseEnterListener));
    this.overlayListeners.push(() => overlayElement.removeEventListener('mouseleave', mouseLeaveListener));
  }

  /**
   * Observe events on overlay
   */
  observeEvents() {
    this._overlayRef
      .outsidePointerEvents()
      .pipe(debounceTime(200))
      .subscribe(() => {
        this._overlayRef.dispose();
        this.isOpen = false;
        this.afterHide();
      });
  }

  /**
   * Hide overlay
   */
  hide() {
    if (!this.preventHide && this._overlayRef) {
      this._overlayRef.dispose();
    }

    this.isOpen = false;
    this.afterHide();
  }

  /**
   * Re-update position
   */
  updatePosition(position) {
    this._overlayRef?.updatePositionStrategy(
      this.overlay
        .position()
        .flexibleConnectedTo(this._element)
        .withPositions(position)
        .withFlexibleDimensions(true)
        .withViewportMargin(40)
        .withGrowAfterOpen(true)
        .withPush(false)
    );
    this._overlayRef?.updatePosition();
  }

  /**
   * Re-update position and data
   */
  updatePositionWithData(data) {
    this._overlayRef?.updatePosition();
    this._componentRef?.setInput('data', data);
  }

  afterHide() {}
}
