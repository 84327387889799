import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { I18nComponent } from '../i18n/i18n.component';

@Component({
  selector: 'x-split-i18n',
  templateUrl: './split-i18n.component.html',
  styleUrls: ['./split-i18n.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslateModule, CommonModule, I18nComponent]
})
export class SplitI18nComponent implements OnChanges {
  @Input() key = '';
  @Input() params = {};
  @Input() splitBy = '/';

  keyArray = [];

  constructor() {}

  ngOnChanges(): void {
    this.keyArray = this.key.split(this.splitBy);
  }
}
