import { Injectable, TemplateRef } from '@angular/core';
import { XOverlayService } from '../../services/overlay/x-overlay.service';
import { DropdownMenuComponent } from './dropdown-menu/dropdown-menu.component';
import { ComponentPortal } from '@angular/cdk/portal';
import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { Placement } from 'src/@xcorp/utils';
import { PositionOverlay, WithOffset } from 'src/@xcorp/constant';
import { debounceTime, filter } from 'rxjs';

@Injectable({
  providedIn: 'any'
})
export class DropdownService extends XOverlayService<DropdownMenuComponent, TemplateRef<any>> {
  placement: Placement = Placement.left;
  afterHideCallback: () => void;

  override component(): ComponentPortal<DropdownMenuComponent> {
    return new ComponentPortal(DropdownMenuComponent);
  }

  override position(): ConnectionPositionPair[] {
    const bottomRight = WithOffset(PositionOverlay.bottomRight, -40, 10);
    const topRight = WithOffset(PositionOverlay.topRight, -40, -10);
    const bottomLeft = WithOffset(PositionOverlay.bottomLeft, 0, 10);
    const topLeft = WithOffset(PositionOverlay.topLeft, 0, -10);

    if (this.placement === Placement.right) {
      return [
        bottomRight, bottomLeft, topRight, topLeft
      ];
    }

    return [
      bottomLeft, bottomRight, topLeft, topRight
    ];
  }

  override observeEvents() {
    this._overlayRef
      .outsidePointerEvents()
      .pipe(
        filter((event: MouseEvent) => {
          const target = event.target as HTMLElement;
          return !target.closest('.bs-datepicker');
        }),
        debounceTime(0)
      )
      .subscribe(this.hide.bind(this));
  }

  override afterHide() {
    this.afterHideCallback?.();
  }
}
