import { NgModule } from '@angular/core';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatIconModule } from '@angular/material/icon';
import { Overlay, OverlayModule } from '@angular/cdk/overlay';
import { CdkScrollableModule } from '@angular/cdk/scrolling';
import { MatMenuModule } from '@angular/material/menu';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { DEFAULT_DIALOG_CONFIG, DialogConfig, DialogModule } from '@angular/cdk/dialog';
import {MatTooltipModule} from '@angular/material/tooltip';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from '@angular/material/dialog';

const DIALOG_DEFAULT_TOP = '1.75rem';
const DIALOG_PANEL_CLASS = 'modal-dialog';

const NG_MATERIAL = [
  DragDropModule,
  MatIconModule,
  MatMenuModule,
  OverlayModule,
  CdkScrollableModule,
  MatDatepickerModule,
  MatNativeDateModule,
  DialogModule,
  MatTooltipModule,
  MatDialogModule,
];

export function dialogConfigFactory(overlay: Overlay): DialogConfig {
  const positionStrategy = overlay.position()
    .global()
    .top(DIALOG_DEFAULT_TOP)
    .centerHorizontally();

  return {
    positionStrategy: positionStrategy,
    hasBackdrop: true,
    panelClass: DIALOG_PANEL_CLASS,
  };
}

@NgModule({
  imports: [...NG_MATERIAL],
  exports: [...NG_MATERIAL],
  providers: [
    {
      provide: DEFAULT_DIALOG_CONFIG,
      useFactory: dialogConfigFactory,
      deps: [Overlay]
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        position: {
          top: DIALOG_DEFAULT_TOP
        }
      },
    },
  ]
})
export class NgMaterialModule {}
