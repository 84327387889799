<div #avatar class="appAvatar">
  <div
    class="user-avatar-wrapper"
    #div
    *ngIf="isWebLayout"
    tooltip-overlay
    [templateRef]="tooltipTemplate"
    [viewContainerRef]="appAvatar"
    [tooltipDisabled]="disabled"
    [hideOnMouseLeave]="true"
    [customConfig]="tooltipOverlayConfig"
    panelClass="user-container"
  >
    <ng-container *ngIf="user?.avatar">
      <div
        class="avatar relative"
        [ngClass]="{ big: isBig }"
        (click)="onShowUser(div)"
      >
        <div class="avatar-wrapper">
          <img [src]="user?.avatar" alt="" />
        </div>
        <span *ngIf="!user?.avatar">{{ user?.fullName | getShortName }}</span>
        <span
          class="user-badge"
          *ngIf="badgeColor"
          [style.backgroundColor]="badgeColor"
        ></span>
      </div>
    </ng-container>

    <ng-container *ngIf="!user?.avatar">
      <div
        class="avatar"
        *ngIf="user?.fullName"
        [ngClass]="{ big: isBig }"
        (click)="onShowUser(div)"
      >
        <span>{{ user?.fullName | getShortName }}</span>
        <span
          class="user-badge"
          *ngIf="badgeColor"
          [style.backgroundColor]="badgeColor"
        ></span>
      </div>

      <span
        class="unassigned-avatar material-icons text-gray"
        *ngIf="!user?.fullName"
        [ngClass]="{ 'big-unassigned': isBig }"
        title="Unassigned"
        (click)="onShowUser(div)"
      >
        account_circle
      </span>
    </ng-container>

    <div *ngIf="showFullName" class="fullname ml-2">
      <span
        *ngIf="!user?.id || !visibility[permissionCode.ORGANIZATION_USER_VIEW]"
        >{{ user?.fullName }}</span
      >
      <a
        [routerLink]="disabled ? null : '/organization/users/' + user?.id"
        *ngIf="user?.id && visibility[permissionCode.ORGANIZATION_USER_VIEW]"
        >{{ user?.fullName }}</a
      >
    </div>
  </div>
</div>

<div class="user-avatar-wrapper" #div *ngIf="!isWebLayout">
  <div
    class="avatar"
    [ngClass]="{ big: isBig }"
    (click)="onConfirmShowUser($event)"
  >
    <img [src]="user.avatar" alt="" *ngIf="user?.avatar" />
    <span *ngIf="!user?.avatar" class="alt-avatar">{{
      user?.fullName | getShortName
    }}</span>
  </div>

  <div *ngIf="showFullName" class="fullname ml-2">
    <span
      *ngIf="!user?.id || !visibility[permissionCode.ORGANIZATION_USER_VIEW]"
      >{{ user?.fullName }}</span
    >
    <a
      *ngIf="
        user?.id &&
        visibility[permissionCode.ORGANIZATION_USER_VIEW] &&
        !showConfirm
      "
      (click)="onConfirmShowUser($event)"
      >{{ user?.fullName }}</a
    >
    <a
      *ngIf="
        user?.id &&
        visibility[permissionCode.ORGANIZATION_USER_VIEW] &&
        showConfirm
      "
      (click)="onShowUserProfile($event)"
      class="show-confirm bg-primary"
    >
      <span class="mr-2"
        ><app-i18n [key]="'View User Profile'"></app-i18n
      ></span>
      <span class="material-icons-outlined"> east </span>
    </a>
  </div>
</div>

<ng-template #tooltipTemplate>
  <div class="m-2 d-flex align-items-center">
    <div
      class="avatar-wrapper"
      [ngClass]="{ unassigned: !user?.avatar && user?.fullName }"
    >
      <img
        class="icon-md rounded-circle"
        [src]="user?.avatar"
        *ngIf="user?.avatar; else shortName"
      />
    </div>
    <div class="ml-1">{{ user?.fullName || unassignedTitle }}</div>
  </div>
</ng-template>

<ng-template #shortName>
  <span class="name" *ngIf="user?.fullName">{{
    user?.fullName | getShortName
  }}</span>
  <span
    class="unassigned-avatar material-icons text-gray"
    *ngIf="!user?.fullName"
  >
    account_circle
  </span>
</ng-template>
