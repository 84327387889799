import { TASK_DISTRIBUTION } from './../../../site-management/_store/dashboard/constants/dashboard.const';
import { TEAM_MEMBER_PERFORMANCE_REPORT } from './../../../site-management/_store/dashboard/constants/dashboard.const';
export enum SortDirection {
  ascending = 'asc',
  descending = 'desc'
}

export enum CreatedAtSortType {
  asc = 'createdAt,asc',
  desc = 'createdAt,desc'
}

export enum PageSize {
  loadAll = 10000
}

export enum TableName {
  LEAVE_REQUEST = 'LEAVE_REQUEST',
  PROJECT_LIST = 'PROJECT_LIST',
  PROJECT_MEMBER = 'PROJECT_MEMBER',
  PROJECT_TASK_TYPE = 'PROJECT_TASK_TYPE',
  PROJECT_PRIORITY = 'PROJECT_PRIORITY',
  PROJECT_STORYLINE = 'PROJECT_STORYLINE',
  PROJECT_LABEL = 'PROJECT_LABEL',
  BACKLOGS_TASK = 'BACKLOGS_TASK',
  TASK_TEMPLATE_TASK = 'TASK_TEMPLATE_TASK',
  TASK_FINDER_TASK = 'TASK_FINDER_TASK',
  EPICS_TASK = 'EPICS_TASK',
  STORYLINE_TASK = 'STORYLINE_TASK',
  JOB_TITLE = 'JOB_TITLE',
  LOCATION = 'LOCATION',
  SHIFT = 'SHIFT',
  TEAM = 'TEAM',
  GROUP = 'GROUP',
  TEAM_MEMBER = 'TEAM_MEMBER',
  USER_MEMBER = 'USER_MEMBER',
  GROUP_MEMBER = 'GROUP_MEMBER',
  OKR_SPACE_MEMBER = 'OKR_SPACE_MEMBER',
  OKR_SPACE = 'OKR_SPACE',
  OKR_TIMELINE = 'OKR_TIMELINE',
  OKR_METRIC = 'OKR_METRIC',
  OKR_CONVERSION = 'OKR_CONVERSION',
  OKR_BOARD = 'OKR_BOARD',
  AUDIT_LOG = 'AUDIT_LOG',
  LOCATION_SETTING_LIST = 'LOCATION_SETTING_LIST',
  DOCUMENT_TEMPLATE = 'DOCUMENT_TEMPLATE',
  POLICY_LIST = 'POLICY_LIST',
  BENEFIT_RULE_LIST = 'BENEFIT_RULE_LIST',
  SALARY_PROFILE = 'SALARY_PROFILE',
  PAY_SCHEDULE = 'PAY_SCHEDULE',
  PAYROLL = 'PAYROLL',
  HR_MEMBER = 'HR_MEMBER',
  ASSET_MANAGEMENT = 'ASSET_MANAGEMENT',
  LEAVE_REPORT = 'LEAVE_REPORT',
  LEAVE_BALANCE = 'LEAVE_BALANCE',
  LEAVE_APPROVAL = 'LEAVE_APPROVAL',
  LEAVE_CATEGORY = 'LEAVE_CATEGORY',
  LEAVE_TYPE = 'LEAVE_TYPE',
  RELEASE = 'RELEASE',
  RELEASE_BASELINE_TABLE = 'RELEASE_BASELINE_TABLE',
  RELEASE_STORYLINE_TABLE = 'RELEASE_STORYLINE_TABLE',
  GIT = 'GIT',
  GIT_DETAIL = 'GIT_DETAIL',
  SLACK_INTEGRATION = 'SLACK_INTEGRATION',
  JIRA_INTEGRATION = 'JIRA_INTEGRATION',
  TASK_MGMT_PROJECT_SLACK_INTEGRATION = "TASK_MGMT_PROJECT_SLACK_INTEGRATION",
  ORGANIZATION_SLACK_INTEGRATION = 'ORGANIZATION_SLACK_INTEGRATION',
  TASK_DISTRIBUTION = 'TASK_DISTRIBUTION',
  //User Dashboard
  TEAM_MEMBER_PERFORMANCE_REPORT = 'TEAM_MEMBER_PERFORMANCE_REPORT',
  PROJECT_STATUS_REPORT = 'PROJECT_STATUS_REPORT',
}