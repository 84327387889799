import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { SearchOverlayConfig } from 'src/app/shared/_models/app/search-overlay.model';

@Component({
  selector: 'app-search-overlay-upsert',
  template: `
  <div>
      base overlay upsert component!
  </div>`
})
export class SearchOverlayBaseComponent<T> implements OnInit, OnChanges, AfterViewInit {
  @ViewChild('selectedTemplate') selectedTemplate: any;
  @ViewChild('optionTemplate') optionTemplate: any;
  @ViewChild('footerTemplate') footerTemplate: any;

  @Input() canEdit = true;
  @Input() searchFn: (keyword: string) => any;
  @Input() value: T | T[];
  @Input() showFullname: boolean = false;
  @Output() change: EventEmitter<T | T[]> = new EventEmitter<T | T[]>();

  config: SearchOverlayConfig<T> = new SearchOverlayConfig<T>();

  constructor(
  ) {
    this.config.search = this.searchFn || this.getSearchFn();
  }

  getSearchFn() { return null; }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value) {
      this.config.selecteds.splice(0, this.config.selecteds.length);

      if (Array.isArray(this.value)) {
        this.config.selecteds = [...this.config.selecteds, ...this.value];
      } else if (this.value) {
        this.config.selecteds = [...this.config.selecteds, this.value];
      }
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.config.selectedTemplate = this.selectedTemplate;
      this.config.optionTemplate = this.optionTemplate;
      this.config.footerTemplate = this.footerTemplate;
    }, 0);
  }

  onOpen() {
    if (this.config.items.length < 1) {
      this.config.searchTypeahead$.next('');
    }
  }

  onChange(selecteds: T | T[]) {

  }
}
