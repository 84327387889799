<div class="language-wrapper" [ngClass]="style">
  <div class="btn-group ml-auto" dropdown [insideClick]="true">
    <button
      id="button-alignment"
      dropdownToggle
      type="button"
      class="btn-current-lang"
    >
      <span class="lang-name mr-2">{{ currentLanguage.name }}</span>
      <ng-container *ngIf="style == 'landing'">
        <img
          class="icon-language"
          src="assets/icons/header/EN.svg"
          alt="EN"
          *ngIf="currentLanguage.codeUI === LanguageCodeUI.en"
        />
        <img
          class="icon-language"
          src="assets/icons/header/VI.svg"
          alt="VI"
          *ngIf="currentLanguage.codeUI === LanguageCodeUI.vi"
        />
      </ng-container>

      <ng-container *ngIf="style !== 'landing'">
        <span class="material-icons-outlined icon-lang"> language </span>
        <span class="lang-code">{{ currentLanguage.codeUI }}</span>
      </ng-container>

      <span class="material-icons-outlined icon-expand custom-icon">
        expand_more
      </span>
    </button>

    <div class="copy-right">
      <span class="px-2">|</span><span>&copy; XCORP {{ currentYear }}</span>
    </div>

    <div
      id="dropdown-alignment"
      *dropdownMenu
      [ngClass]="{ 'dropdown-menu-top-left': placement === 'top' }"
      class="dropdown-menu mt-0 dropdown-menu-right"
      role="menu"
      aria-labelledby="button-alignment"
    >
      <!-- <div class="m-3">
        <app-search-input (search)="onSearchText($event)"></app-search-input>
      </div> -->
      <li role="menuitem" *ngFor="let lang of languaguesDisplay">
        <button
          class="dropdown-item d-flex align-items-center"
          [ngClass]="{ selected: currentLanguage.code === lang.code }"
          (click)="switchLanguage(lang)"
        >
          <div class="lang-code mr-2">
            {{ lang.codeUI }}
          </div>

          <div class="lang-name">
            {{ lang.name }}
          </div>
        </button>
      </li>
    </div>
  </div>
</div>
