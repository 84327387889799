import { LicenseResponse } from "../../_models/license.model";
import { pricingModuleDesc } from "../../_utils/consts";

export const PackageInfo = [
  {
    name: pricingModuleDesc.free.name,
    desc: [
      {
        available: true,
        desc: 'Limited to 5 projects'
      },
      {
        available: true,
        desc: 'For teams under 20 people'
      },
      {
        available: true,
        desc: 'Project management with Kanban'
      },
      {
        available: false,
        desc: 'OKR'
      },
      {
        available: true,
        desc: 'Leave management'
      },
      {
        available: false,
        desc: 'Timesheet'
      },
      {
        available: false,
        desc: 'Document management'
      },
      {
        available: false,
        desc: 'Human resources management'
      },
      {
        available: false,
        desc: 'Activity history'
      },
    ]
  },
  {
    name: pricingModuleDesc.taskManagement.name,
    desc: [
      {
        available: true,
        desc: 'Unlimited projects'
      },
      {
        available: true,
        desc: 'Project management with Scrum and Kanban'
      },
      {
        available: false,
        desc: 'OKR'
      },
      {
        available: true,
        desc: 'Leave management for teams under 20 people'
      },
      {
        available: true,
        desc: 'Timesheet'
      },
      {
        available: true,
        desc: 'Document management'
      },
      {
        available: false,
        desc: 'Human resources management'
      },
      {
        available: true,
        desc: 'Activity history for 1 month'
      },
    ]
  },
  {
    name: pricingModuleDesc.companyManagement.name,
    desc: [
      {
        available: true,
        desc: 'Unlimited projects'
      },
      {
        available: true,
        desc: 'Project management with Scrum and Kanban'
      },
      {
        available: false,
        desc: 'OKR'
      },
      {
        available: true,
        desc: 'Leave management'
      },
      {
        available: true,
        desc: 'Timesheet'
      },
      {
        available: true,
        desc: 'Document management'
      },
      {
        available: true,
        desc: 'Human resources management'
      },
      {
        available: true,
        desc: 'Activity history for 1 month'
      },
    ]
  },
  {
    name: pricingModuleDesc.professional.name,
    desc: [
      {
        available: true,
        desc: 'Unlimited projects'
      },
      {
        available: true,
        desc: 'Project management with Scrum and Kanban'
      },
      {
        available: true,
        desc: 'Leave management'
      },
      {
        available: true,
        desc: 'Timesheet'
      },
      {
        available: true,
        desc: 'Document management'
      },
      {
        available: true,
        desc: 'Human resources management'
      },
      {
        available: true,
        desc: 'Activity history for 1 month'
      },
    ]
  },
  {
    name: pricingModuleDesc.enterprise.name,
    desc: [
      {
        available: true,
        desc: 'Includes all features of the PROFESSIONAL package plus enhanced security optimization and various customizations upon request'
      },
      {
        available: true,
        desc: 'Dedicated infrastructure system'
      },
      {
        available: true,
        desc: 'SSO integration'
      },
      {
        available: true,
        desc: 'Direct support'
      },
      {
        available: true,
        desc: 'Support for automatic data synchronization into OKR'
      },
      {
        available: true,
        desc: 'Support for API integration with enterprise systems'
      },
      {
        available: true,
        desc: 'Custom report customization support'
      },
    ]
  }
];

export const EnterprisePackageInfo : LicenseResponse = {
    id: 5,
    name: 'Enterprise',
    limitedUser: null,
    limitedStorage: null,
    rate: null,
    trialLimitDays: null,
    limitedProject: null,
    leaveTracking: true,
    leaveTrackingLimitUser: null,
    dailyReport: true,
    hr: true,
    okr: true,
    taskManagement: true,
    allowProjectType: null,
    taskManagementLimitUser: null,
    testManagement: true,
    timesheet: true,
    documentManagement: true,
}