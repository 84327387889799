import { Pipe, PipeTransform } from '@angular/core';
import { FieldHistory } from 'src/app/shared';

@Pipe({
  name: 'isAuditCellEmpty'
})

export class IsAuditCellEmptyPipe implements PipeTransform {
  transform(cell: any, fieldTask: string) {
    if (typeof cell === 'boolean') {
      return false;
    }
    return (!cell && cell !== 0 && fieldTask !== FieldHistory.self) || cell?.length === 0;
  }
}
