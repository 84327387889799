import { NgModule } from '@angular/core';
import { DueDatePipe } from './dueDate.pipe';
import { FunctionPipe } from './function.pipe';
import { DateFormatPipe } from './date-format.pipe';
import { ShortNamePipe } from './getShortName.pipe';
import { IsVisibleColumnPipe } from './isVisibleColumn.pipe';
import { RoundToOneDecimalPipe } from './roundToOneDecimal.pipe';

const PIPES = [
  DueDatePipe,
  FunctionPipe,
  DateFormatPipe,
  ShortNamePipe,
  IsVisibleColumnPipe,
  RoundToOneDecimalPipe
]

@NgModule({
  declarations: [
    ...PIPES,
  ],
  exports: [
    ...PIPES,
  ]
})
export class XPipesModule { }
