import { OkrType } from 'src/app/site-management/okr/_model';
import {
  List2Res,
  OkrWorkspace,
  PageableModel,
  PageableRes,
  SelectOption,
  Task,
  TaskAttachment,
  TaskAttachmentType
} from '..';
import {
  DatePeriod,
  OkrMyFollowUp,
  OkrRiskLevel,
  OkrObjectiveType,
  OkrResultType,
  OkrIndicator,
  OkrCategory
} from '../_utils';
import { FilterBy, FilterItem } from './app/filter.model';
import { AttachmentType, BaseAttachment } from './attachment.model';
import { Group } from './group.model';
import { Metrics } from './metrics.model';
import { OkrKeyResult, OkrKeyResultItem } from './okr-key-result.model';
import { OkrTimeline, OkrTimelineEntity } from './okr-timeline.model';
import { Team } from './team.model';
import { User } from './user.model';

export class OkrBase {
  id: number;
  start: number;
  current: number;
  expected: number;
  weight: number;
  objectiveType: OkrObjectiveType;
  resultType: OkrResultType;
  progress: number;
  totalValue: number;
  percentValue: number;
  justify: number;
  isEditable?: boolean;
}

export interface OkrShortItem {
  id: number;
  name: string;
  key: string;
  objectiveType: OkrObjectiveType;
}

export interface OkrItem {
  id: number;
  taskId: number;
  typeIcon: string;
  type: OkrType;
  objectiveType: OkrObjectiveType;
  name: string;
  key: string;
  keyResult: OkrKeyResult;
  description: string;
  dueDate: string;
  resultType: OkrResultType;
  start: number;
  current: number;
  expected: number;
  weight: number;
  justify: number;
  krWeight: number;
  progress: number;
  pathIds: number[];
  timeline: OkrTimeline;
  assignee: User;
  stakeHolder: User;
  createBy: User;
  okrTeams: Team[];
  okrGroups: Group[];
  riskLevel: OkrRiskLevel;
  percentValue: number;
  totalValue: number;
  metric: Metrics;
  orderNumber: number;
  isEditable: boolean;
  isPopulate: boolean;
  childTimelines: OkrChildrenTimeline[];
  countChildTimelines: number;
  indicator: OkrIndicator;
  category: OkrCategory;
  itemsWeight: number;
  projectId?: number;
  isLink?: boolean;
  linkedOkr?: Okr;
  linkedKeyResult?: OkrKeyResult;
  //
  children?: OkrItem[];
}

export class Okr extends OkrBase {
  id: number;
  objectiveType: OkrObjectiveType;
  name: string;
  key: string;
  description: string;
  dueDate: string;
  resultType: OkrResultType;
  start: number;
  current: number;
  expected: number;
  weight: number;
  progress: number;
  timeline: OkrTimeline;
  childTimelines: OkrChildrenTimeline[];
  countChildTimelines: number;
  assignee: User;
  stakeHolder: User;
  createBy: User;
  okrTeams: Team[];
  okrGroups: Group[];
  keyResults: OkrKeyResult[];
  tasks: OkrTask[];
  children: Okr[];
  parent: Okr;
  path: Okr[];
  krWeight: number;
  metric: Metrics;
  riskLevel: OkrRiskLevel;
  hasChild: boolean;
  percentValue: number;
  totalValue: number;
  attachments: OkrAttachment[];
  type: OkrType;
  orderNumber?: number;
  indicator?: OkrIndicator;
  workspace: OkrWorkspace;
  category: OkrCategory;
  totalCheckin?: number;
  isPopulate?: boolean;
  linkedOkr?: Okr;
  rootTimelineId?: number;
  percentFx?: string;
  riskFx?: string;
}

export interface OkrChildrenTimeline {
  id: number;
  title: string;
  startDate: string;
  endDate: string;
  current: number;
  expected: number;
  start: number;
  weight: number;
  expectedActual: number;
  startActual: number;
  percentValue: number;
  justify: number;
  riskLevel: OkrRiskLevel;
  totalCheckin?: number;
  isLock?: boolean;
}

export enum OkrSearchType {
  kr = 'keyresult',
  okr = 'okr',
  item = 'item', // Releated to KR
  task = 'task' // Releated to OKR
}

export interface OkrSearchResponse {
  id: number;
  taskId: number;
  typeIcon: string;
  type: OkrSearchType;
  objectiveType: OkrObjectiveType;
  name: string;
  key: string;
  keyResult: OkrKeyResult;
  description: string;
  dueDate: string;
  resultType: OkrResultType;
  start: number;
  current: number;
  expected: number;
  weight: number;
  justify: number;
  krWeight: number;
  progress: number;
  path: Okr[];
  timeline: OkrTimeline;
  assignee: User;
  stakeHolder: User;
  createBy: User;
  okrTeams: Team[];
  okrGroups: Group[];
  riskLevel: OkrRiskLevel;
  totalCheckin?: number;
  isEditable?: boolean;
}

export interface OkrAssignee {
  id: number;
  fullName: string;
  avatar: string;
}

export interface OkrTask {
  id: number;
  taskId: number;
  assignee: User;
  current: number;
  percentValue: number;
  description: string;
  key: string;
  name: string;
  weight: number;
  typeIcon: string;
  metricValue: number;
  metric: Metrics;
  orderNumber?: number;
  startMetricValue: number;
  currentMetricValue: number;
  dueDate: string;
  timelineId: number;
  isEditable?: boolean;
  projectId?: number;
  totalCheckin?: number;
}

export class OkrCreateRequest {
  objectiveType: OkrObjectiveType;
  name: string;
  description?: string;
  parentId?: number;
  assigneeId?: number;
  stakeholderId?: number;
  teamIds?: number[];
  groupIds?: number[];
  workspaceId?: number;
  dueDate?: string;
  resultType: string;
  // start: number;
  // current: number;
  // expected: number;
  weight?: number;
  krWeight?: number;
  keyResults?: OkrChildCreateRequest[];
  childrens?: OkrChildCreateRequest[];
  tasks?: OkrChildCreateRequest[];
  metricId?: number;
  justify?: number;
  childTimelines: OkrChildTimeline[];
  type: OkrType;
  indicator?: OkrIndicator;
  category?: OkrCategory;
  batch?: {
    teams: number[];
    groups: number[];
    assignees: number[];
  };
  percentFx?: string;
  riskFx?: string;

  static default(): OkrCreateRequest {
    return {
      objectiveType: null,
      name: null,
      description: null,
      parentId: null,
      workspaceId: null,
      assigneeId: null,
      stakeholderId: null,
      teamIds: [],
      groupIds: [],
      dueDate: null,
      resultType: null,
      type: null,
      justify: null,
      keyResults: [],
      childrens: [],
      tasks: [],
      metricId: null,
      childTimelines: [],
      indicator: null,
      percentFx: null,
      riskFx: null,
    };
  }
}

export interface OkrUpdateRequest {
  objectiveType: string;
  name: string;
  description: string;
  parentId: number;
  assigneeId: number;
  stakeholderId: number;
  teamIds: number[];
  groupIds: number[];
  workspaceId: number;
  dueDate: string;
  resultType: string;
  weight: number;
  krWeight: number;
  justify: number;
  currentTimeline?: OkrUpdateTimeline;
  type: OkrType;
  timelineIds?: number[];
  startPlan?: number;
  expectedPlan?: number;
  note?: string;
  indicator?: OkrIndicator;
  category?: OkrCategory;
  resetCheckin?: boolean;
  percentFx?: string;
  riskFx?: string;
}

export interface OkrUpdateTimeline {
  id: number;
  current: number;
  expected: number;
  justify: number;
  start: number;
}

export interface OkrLinkTaskRequest {
  weight: number;
  taskId: number;
  assigneeId: number;
  task?: Task;
  timelineId?: number;
}

export interface OkrChildRequest {
  id: number;
  assigneeId: number;
  weight: number;
  current: number;
}

export interface OkrKeyResultRequest {
  id: number;
  assigneeId: number;
  weight: number;
  current: number;
}

export interface OkrChildKrCreateRequest {
  weight: number;
  taskId: number;
  assigneeId: number;
}

export interface OkrChildKrUpdateRequest {
  current: number;
  weight: number;
  assigneeId: number;
}

export interface OkrChildUpdateRequest {
  assigneeId: number;
  current: number;
  weight: number;
}

export interface OkrChildCreateRequest {
  id?: number;
  taskId?: number;
  current?: number;
  weight?: number;
  assigneeId?: number;
}

export interface OkrChildTaskUpdateRequest {
  weight: number;
  taskId: number;
  assigneeId: number;
}

export interface OkrDetailRequest {
  timelineId: number;
}

export interface OkrTreeRequest {
  timelineId: number;
  objectId: number;
  objectType: OkrObjectType;
}

export interface OkrChildTimeline {
  id: number;
  current: number;
  expected: number;
  start: number;
  weight?: number;
  justify?: number;
}

export interface OkrSearchRequest {
  startDate?: string;
  endDate?: string;
  timelineId?: number;
  searchText?: string;
  objectiveType?: OkrObjectiveType;
  userIds?: number[];
  groupIds?: number[];
  teamIds?: number[];
  userStatus?: string;
  metricFilters?: OkrMetricSearchRequest[];
  indicators?: OkrIndicator[];
}

export interface OkrSearchCompareRequest {
  timelineCompareId?: number;
}

export interface OkrMetricSearchRequest {
  metricId: number;
  type: OkrType;
}

export interface OkrTreeItem {
  type: OkrSearchType;
  row: Okr | OkrKeyResult | OkrKeyResultItem | Task;
}

// export enum OkrTreeRowAction {
//   addOkr = 'addOkr',
//   addKr = 'addKr',
//   linkTask = 'linkTask',
//   unlinkTask = 'unlinkTask',
//   createTask = 'createTask',
//   edit = 'edit',
//   delete = 'delete',
//   deleteKr = 'deleteKr',
//   unlink = 'unlink',
// }

// export interface OkrTreeRowActionData {
//   type: OkrSearchType;
//   row: (Okr| OkrKeyResult);
//   action: OkrTreeRowAction;
// }

export class OkrFilterBy {
  followUp?: FilterItem<SelectOption<OkrMyFollowUp>>;
  objectiveType?: FilterItem<SelectOption<OkrObjectiveType>>;
  assignee?: FilterItem<User>;
  group?: FilterItem<Group>;
  team?: FilterItem<Team>;
  dueDate?: FilterItem<SelectOption<DatePeriod>>;
  userStatus?: FilterItem<{ name: string; value: any }>;
  indicator?: FilterItem<{ name: string; value: OkrIndicator }>;
  // okrStatus: FilterItem<SelectOption<OkrRiskLevel>>;
}

export interface RiskLevelData {
  label: string;
  colorCode: string;
  icon: string;
  blockIcon: string;
}

export interface OkrDataChange {
  type: OkrSearchType;
  item: Okr | OkrKeyResult;
}

export class OkrAttachment extends BaseAttachment {}

export interface OkrUpsertFile {
  file?: File;
  type?: AttachmentType;
  id?: number;
  typeOfObject?: string;
  previewUrl?: string;
}

export enum OkrPatchField {
  DESCRIPTION = 'description'
}

export interface OkrPatch {
  [OkrPatchField.DESCRIPTION]?: string;
}

export interface OkrReorderRequest {
  parentId?: number;
  parentType?: OkrObjectType;
  objectId?: number;
  objectType?: OkrObjectType;
  preObjectId?: number;
  preObjectType?: OkrObjectType;
  nextObjectId?: number;
  nextObjectType?: OkrObjectType;
}

export enum OkrObjectType {
  OKR = 'OKR',
  KR = 'KR',
  TASK = 'TASK',
  KR_TASK = 'KR_ITEM'
}

export interface OkrReportRequest {
  workspaceId: number;
  timelineId: number;
  type?: string;
  compareTimelineId?: number;
}

export interface OkrObjectiveRequest {
  widgetTypeRequest?: string;
  chartType?: string;
  groupIds?: number[];
  teamIds?: number[];
  userIds?: number[];
  indicators?: OkrIndicator[];
  categories?: OkrCategory[];
  objectiveType?: OkrObjectiveType;
  timelineIds: number[];
}

export interface OkrChartSectionRequest {
  widgetTypeRequest?: string,
  chartType?: string,
  timelineId: number,
  compareTimelineId?: number,
  workspaceId: number,
  ids?: number[],
  viewMode?: string,
  objectiveType: string,
  indicator?: string,
  riskLevel: string,
  timelineCategory: string,
  timelineCategoryCompare?: string
  viewBy?: string
}

export interface OkrMetricsRequest {
  timelineIds: number[];
}

export interface OkrReport {
  metrics: OkrMetricReport[];
  types: OkrObjectReport[];
}

export interface OkrMetricReport {
  metric: Metrics;
  type: OkrType;
  totalStart: number;
  totalCurrent: number;
  totalExpected: number;
  percent: number;
  // FE
  isHide?: boolean;
  compare?: {
    isIncrease: boolean;
    value: number;
  };
}

export interface OkrMetricReport {
  metric: Metrics;
  type: OkrType;
  totalStart: number;
  totalCurrent: number;
  totalExpected: number;
  percent: number;
  // FE
  isHide?: boolean;
  compare?: {
    isIncrease: boolean;
    value: number;
  };
}

export interface OkrMetricReportForCompare {
  data: OkrMetricReport[];
  compareData: OkrMetricReport[];
}

export interface OkrObjectReport {
  objectiveType: OkrObjectiveType;
  items: OkrObjectItemReport[];
}

export interface OkrObjectItemReport {
  riskLevel: OkrRiskLevel;
  totalObjects: number;
}

export interface OkrInactiveUserReport {
  user: User;
  current: number;
  total: number;
}

export enum CheckInObjectType {
  OKR = 'OKR',
  KR = 'KEY_RESULT',
  TASK = 'TASK'
}

export enum OkrRiskLevelPercent {
  COMPLETED = 100,
  ON_TRACK = 95,
  LOW_RISK = 90,
  MEDIUM_RISK = 80,
  HIGH_RISK = 0
}

export const OKR_CURRENT_VALUE = null;
export const OKR_START_VALUE = 0;
export const OKR_EXPECTED_100_VALUE = 100;
export const OKR_EXPECTED_0_VALUE = 0;
export const OKR_PERCENT_0_VALUE = 0;
export const OKR_JUSTIFY_VALUE = 0;

export interface OkrObjectiveReportRequest {
  workspaceId: number;
  timelineId: number[];
}

export interface OkrNoCheckinReportRequest {
  request: any;
  pageable: PageableModel;
}

export interface OkrObjectiveDetailTableReportRequest {
  request: any;
  pageable: PageableModel;
}

export interface OkrObjectiveReport {
  objectiveType: OkrObjectiveType;
  items: OkrObjectItemReport[];
}

export interface OkrDashboardObjectiveReport {
  chartType: string;
  labels: string[];
  seriesData: OkrObjectiveSeriesData[];
  legend: string[];
  metadata: any;
}

export interface OkrObjectiveSeriesData {
  name: string;
  type: string;
  data: number[];
  additionalAttributes: any;
}

export interface OkrNoCheckinReport {
  content: OkrUserReport[];
  pageable: PageableRes;
  totalElements: number;
  totalPages: number;
}

export interface OkrTableDetailReport {
  content: OkrTableDetailReportDataCompare;
  pageable: PageableRes;
  totalElements: number;
  totalPages: number;
}

export interface OkrTableDetailReportDataForShow {
  id: number;
  name: string;
  progressValue: number;
  progressColor: string;
  complete: compareValue;
  onTrack: compareValue;
  highRisk: compareValue;
  mediumRisk: compareValue;
  lowRisk: compareValue;
  noCheckin: compareValue;
}

export interface OkrTableDetailReportDataCompare {
  defaults: OkrTableDetailReportData[];
  compares: OkrTableDetailReportData[];
}

export interface OkrTableDetailReportData {
  name: string;
  complete: number;
  onTrack: number;
  highRisk: number;
  mediumRisk: number;
  lowRisk: number;
  noCheckin: number;
  id: number;
}

export interface OkrStatusOkrReport {
  content: OkrStatusOkrData[];
}

export interface OkrStatusOkrData {
  statusName: string;
  variance: OkrStatusOkrDataVariance;
  objectives: number;
}

export interface OkrStatusOkrDataVariance {
  isIncrease: boolean;
  percent: number;
}

export interface OkrBestContributeReport {
  content: OkrUserReport[];
}

export interface OkrBestContributeAndNoCheckinReport {
  content: OkrUserReport[] | OkrUserReport[];
  pageable?: PageableRes;
  totalElements?: number;
  totalPages?: number;
}

export interface OkrBestContributeReportData {
  user: User;
  value: number;
  percent: number;
  rank: number;
}

export interface ChartModel {
  chartType: string;
  labels: string[];
  seriesData: ChartModelSeriesData[];
  legend: string[];
  metadata: ChartModelMetaData;
  additionalData?: {
    [key: string]: any;
  };
}

interface ChartModelSeriesData {
  name: string;
  type: string;
  additionalAttributes: any;
  data: any[];
}

interface ChartModelMetaData {
  title: string;
  compatibleChartTypes: string[];
  description: string;
  xaxisTitle: string;
  yaxisTitle: string;
}

export enum ProjectReportChartTypeEnum {
  LINE_CHART = 'LINE_CHART',
  PIE_CHART = 'PIE_CHART',
  BAR_CHART = 'BAR_CHART'
}

export interface OkrLineChartReport {
  content: OkrLineChartReportData;
}

export interface OkrLineChartReportData {
  lineChartLabels: string[];
  lineChartData: LineChartModel[];
}

export interface LineChartModel {
  label: string;
  data: any[];
  color: string;
  legend: string;
}

export interface compareValue {
  value: number;
  isIncrease: boolean;
  percent: number;
  fieldName?: string;
}

export interface OkrNoCheckinReportRes<T = any> {
  response: T;
  widgetTypeResponse: string;
}

export interface OkrTotalNoCheckinReportRes{
  totalNoCheckInUsers: number
}

export interface OkrUserReport {
  user: User;
  current: number;
  total: number;
  rank?: number;
}

export interface OkrBestContributorsReportRes<T = any> {
  response: T;
  widgetTypeResponse: string;
}

export interface OkrObjectiveDetailTableReportRes<T = any> {
  compares: T;
  defaults: T;
  widgetTypeResponse: string;
}

export interface OkrDashboardMetric {
  metricReports: MetricReport[];
  widgetTypeResponse: string;
}

export interface MetricReport {
  timeline: OkrTimelineEntity;
  metrics: OkrMetricReport[];
}

export interface OkrPreferenceModalResponse {
  pageSize: number;
  columns: OkrDashboardWidgetColumn[];
}

export interface OkrDashboardWidgetColumn {
  columnTitle?: string;
  fieldName?: string;
  columnClassName?: string;
  sortAble?: boolean;
  sort?: string;
  show?: boolean;
  hideAble?: boolean;
  sortFieldName?: string;
}

export enum OkrLinkObjectiveType {
  OBJECTIVE = 'OBJECTIVE',
  KEY_RESULT = 'KEY_RESULT'
}

export interface OkrLinkObjectivePayload {
  id?: number;
  type?: OkrLinkObjectiveType;
}

export interface OkrAutoLinkPayload {
  ids: number[];
}

export interface OkrAutoLinkValue {
  id: number;
  name: string;
  objectiveType: OkrObjectiveType;
  workspace: OkrWorkspace;
}

export interface OkrDashboardDetailTeamChartResponse {
  response: List2Res<OkrDashboardDetailTeamChart>
  widgetTypeResponse: string;
}

export interface OkrDashboardDetailTeamChart {
  teamId: number;
  teamName: string;
  userTeamId: number;
  userTeamName: string;
  overDue: number;
  noCheckin: number;
  complete: number;
  inProgress: number;
}

export interface OkrDashboardDetailTeamChartForUI {
  id: number;
  name: string;
  userTeamId: number;
  userTeamName: string;
  overDue: number;
  noCheckin: number;
  complete: number;
  inProgress: number;
  type: string;
}

export interface OkrDashboardDetailTeamChartDetailResponse {
  response: List2Res<OkrDashboardDetailTeamChartDetail>
  widgetTypeResponse: string;
}
export interface OkrDashboardDetailTeamChartDetail {
  id: number;
  key: string;
  objectiveName?: string;
  due: string;
  name?: string;
  avatar?: string;
  userNameOrTeamName?: string;
}

export interface OkrDashboardDetailSearchTeamChartResponse {
  responses: Team[] | User[],
  widgetTypeResponse: string
}
