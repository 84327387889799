<div class="pricing-info-wrapper {{ type }}">
  <div class="period-wrapper">
    <div class="package-title">
      <app-i18n [key]="'Package'"></app-i18n>
    </div>

    <div class="price-permonth-wrapper">
      <div class="pay-options">
        <ng-container *ngFor="let item of prices; let index = index">
          <div
            class="pay-option"
            [ngClass]="{ active: selectedPrice?.id === item.id }"
            (click)="onPriceChange(item)"
          >
            <span><app-i18n [key]="item.label"></app-i18n></span>
            <span class="discount" *ngIf="item.discountText">{{
              item.discountText
            }}</span>
          </div>
        </ng-container>
      </div>

      <div class="no-credit-needed">
        <img
          class="img-star"
          src="../../../assets/img/landing-pages/pricing/star.svg"
        />
        <span><app-i18n [key]="'No credit card required'"></app-i18n></span>
      </div>
    </div>
  </div>

  <div class="modules-wrapper">
    <ng-container *ngFor="let m of modules; let index = index">
      <div
        class="m-item m-item-{{ index }}"
        [ngClass]="{ selected: selectedModule?.id === m.id }"
        (click)="onClickModuleItem(m)"
      >
        <div class="m-header">
          <div class="m-info">
            <div class="m-name"><app-i18n [key]="m.name"></app-i18n></div>
          </div>
          <div class="m-rate-wrapper">
            <div class="m-rate-info">
              <div
                class="m-rate"
                [ngClass]="{ 'free-rate': m.name === 'Free' }"
              >
                <span>{{ getRate(m.rate).trim() | translate }}</span>
                <span class="per-person" *ngIf="m.rate"
                  >/<app-i18n [key]="'Person'"></app-i18n
                ></span>

                <div
                  class="original-rate"
                  *ngIf="m.rate && selectedPrice?.discount > 0"
                >
                  ${{ m.rate }}
                </div>
              </div>
              <div class="m-rate-desc">
                <span class="limited-storage">
                  <app-i18n [key]="'Storage Capacity'"></app-i18n>
                  <app-i18n
                    class="ml-1 font-semibold"
                    [key]="'storageCapacity'"
                    [params]="{ value: m.limitedStorage }"
                    *ngIf="m.limitedStorage"
                  ></app-i18n>
                  <app-i18n
                    class="ml-1 font-semibold"
                    [key]="'contactStorageCapacity'"
                    *ngIf="!m.limitedStorage"
                  ></app-i18n>
                </span>
              </div>
            </div>

            <button
              class="x-btn-landing x-btn-landing-warning"
              (click)="onSelectModule(m)"
              *ngIf="type === PricingInfoType.pricingPage"
            >
              <span><app-i18n [key]="getBtnText(m.name)"></app-i18n></span>
            </button>

            <button
              class="x-btn-landing x-btn-landing-warning"
              (click)="onSelectModule(m)"
              *ngIf="type === PricingInfoType.changePlanPage"
            >
              <span
                ><app-i18n
                  [key]="m.id === selectedModule?.id ? 'selected' : 'Select'"
                ></app-i18n
              ></span>
            </button>

            <label
              class="x-btn-landing x-btn-landing-warning text-center"
              *ngIf="type === PricingInfoType.signupPage"
            >
              <span
                ><app-i18n
                  [key]="
                    m.name === 'Free' ? 'Free Forever' : 'FREE for 30 days'
                  "
                ></app-i18n
              ></span>
            </label>
          </div>
        </div>

        <div class="feature-list">
          <ng-container *ngFor="let f of m.packageInfo">
            <div
              class="f-item"
              [ngClass]="{ active: f.available, inactive: !f.available }"
            >
              <img
                class="f-item-icon"
                [src]="
                  f.available
                    ? '../../../../assets/img/landing-pages/pricing/done.svg'
                    : '../../../../assets/img/landing-pages/pricing/close.svg'
                "
              />
              <span>
                <app-i18n [key]="f.desc"></app-i18n>
              </span>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</div>
