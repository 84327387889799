import { AbstractControl, AsyncValidatorFn, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Observable, of, catchError, switchMap, map, first, timer } from 'rxjs';
import { AuthService } from 'src/app/authentication/_services/auth.service';

export class UsernameValidator {
  private static readonly MIN_LENGTH = 4;
  private static readonly MAX_LENGTH = 255;

  /**
   * Validates that the username contains only allowed characters
   * Allowed characters are: letters (a-z, A-Z), numbers, dot, underscore, and hyphen
   */
  static allowedCharactersValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value as string;
      if (!value) return null;

      const regex = /^[a-zA-Z0-9._@-]+$/;
      const valid = regex.test(value);

      return valid ? null : { usernameInvalidChars: true };
    };
  }

  /**
   * Validator to check if a username already exists.
   * 
   * @param {AuthService} _auth - The authentication service to verify the username.
   * @param {string} [domain] - The domain to check the username within.
   * @param {string} [initialValue] - The initial value of the username (to exclude from check if unchanged).
   * @returns {AsyncValidatorFn} - The async validator function.
   */
  static existingUsername(_auth: AuthService, initialValue?: string, domain?: string): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.value) {
        return of(null);
      }
      
      return timer(300).pipe(
        switchMap(() => 
          control.value && control.value !== initialValue 
            ? _auth.verifyExistUsername(control.value, domain) 
            : of(null)
        ),
        map(response => response?.exist ? { usernameExisted: true } : null),
        catchError(() => of(null)),
        first()
      );
    };
  }

  /**
   * Validates that the username contains at least one alphabet character
   */
  static containsAlphabetValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value as string;
      if (!value) return null;

      // Regex to check for at least one alphabet character
      const regex = /[a-zA-Z]/;
      const hasAlphabet = regex.test(value);

      return hasAlphabet ? null : { usernameNoAlphabet: true };
    };
  }

   /**
    * Validates that the username meets the length requirements
    * Minimum 4 characters, maximum 255 characters
    */
   static lengthValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value as string;
      if (!value) return null;

      if (value.length < UsernameValidator.MIN_LENGTH) {
        return { usernameTooShort: true };
      }
      
      if (value.length > UsernameValidator.MAX_LENGTH) {
        return { usernameTooLong: true };
      }

      return null;
    };
  }

  /**
   * Combined validator that checks both alphabet and allowed characters requirement
   */
  static validate(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const validators = [
        Validators.required,
        UsernameValidator.containsAlphabetValidator(),
        UsernameValidator.allowedCharactersValidator(),
        UsernameValidator.lengthValidator(),
      ];

      for (const validator of validators) {
        const error = validator(control);
        if (error) return error;
      }

      return null;
    };
  }
}
