import { Metrics, OkrDashBoardFilter } from 'src/app/shared';
import {
  calendarSettingColorDefault,
  getCalendarActionSetting
} from '../../work-calendar/models/work-calendar';
import { OkrDashboardDetailWidget } from '../okr-dashboard-detail/enitity/widget.entity';
import { OkrDashboardWidget } from '../okr-dashboard/enitity/widget.entity';
import { DashboardConfig, DashboardType } from '../dashboard';
import { Widget, WidgetConfig, WidgetTab } from 'src/@xcorp/modules/widget';

export interface UserSetting {
  settingKey: UserSettingKey;
  settingValue: string;
}

export class UserSettingRequest {
  key: string;
  value: string;
}

export enum UserSettingKey {
  swimlane = 'UserSettingSwimlane',
  epic = 'UserSettingEpic',
  userDashboard = 'UserSettingUserDashboard',
  projects = 'UserSettingProjects',
  release = 'UserSettingRelease',
  taskColumns = 'UserSettingTaskColumns',
  taskTemplate = 'UserSettingTaskTemplate',

  storyLine = 'storyLine',
  activeBoard = 'activeBoard',
  releaseDetail = 'releaseDetail',
  backlogs = 'backlogs',

  // search system
  menuSearch = 'menuSearch',
  userSearch = 'userSearch',
  ticketSearch = 'ticketSearch',
  cvSearch = 'cvSearch',
  groupSearch = 'groupSearch',
  teamSearch = 'teamSearch',
  projectSearch = 'projectSearch',
  salarySearch = 'salarySearch',
  scheduleSearch = 'scheduleSearch',
  payrollSearch = 'payrollSearch',
  fileSearch = 'fileSearch',

  // calendar
  calendarSetting = 'workCalendarSetting',

  // Leave module
  leaveRequest = 'UserSettingLeaveRequest',
  leaveApprove = 'UserSettingLeaveApprove',

  // showPopupJiraAccountFlg
  hidePopupJiraAccountFlg = 'hidePopupJiraAccountFlg',

  // document
  documentMode = 'UserSettingDocumentMode',

  // okr
  myOkr = 'UserSettingMyOkrSettings',
  okrDashboard = 'OkrDashboard',
  okrCache = 'UserSettingOkrBoardCache',
  okrFilterCache = 'UserSettingOkrFilterCache',
  okrDashboardDetail = 'OkrDashboardDetail',

  // gantt
  ganttCache = 'GanttCache',
  activeBoardGanttCache = 'ActiveSprintGanttCache',
  releaseGanttCache = 'ReleaseDetailGanttCache',

  // dashboard
  dashboard = 'Dashboard'
}

export interface UserSettingOkrDashboard {
  widgets: OkrDashboardWidget[];
  unpinMetrics: Metrics[];
  filter?: OkrDashBoardFilter;
}

export interface UserSettingOkrDashboardDetail {
  widgets: OkrDashboardDetailWidget[];
}

export interface UserSettingState {
  [key: string]: any;
  [UserSettingKey.okrDashboard]?: UserSettingOkrDashboard;
}

export const defaultUserSettingState = {
  [UserSettingKey.calendarSetting]: JSON.stringify({
    actions: getCalendarActionSetting(),
    colors: calendarSettingColorDefault()
  })
};

export interface UserSettingDashboard {
  tabs: WidgetTab[];
  widgets: Widget<DashboardType>[];
  configs?: WidgetConfig<DashboardConfig>[];
  activeTab?: WidgetTab;
}
