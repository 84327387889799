export interface Metrics {
  id: number;
  code: string;
  name: string;
  unit: string;
  description?: string;
  isPercent?: boolean;
}

export const PercentMetric = {
  name: 'Percent',
  unit: '%',
}

export const DefaultMetric = {
  name: 'Number',
  unit: 'point',
  code: 'POINT',
}

export class MetricUpsertRequest {
  name: number;
  unit: number;
  description: number;
  isPercent: boolean;
}

