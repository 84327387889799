import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { LicenseService } from 'src/app/pages/landing-page/_services/license.service';
import { PRICING_DATA_KEY, PRICING_PERIODS_KEY, routerObject } from '../..';
import { LicensePeriodResponse, LicenseResponse } from '../../_models';
import { EnterprisePackageInfo, PackageInfo } from './pricing-info';
import { Router } from '@angular/router';

export class PriceInfo extends LicensePeriodResponse {
  value: number;
  label: string;
  discount: number;
  discountText: string;
}

export enum PricingInfoType {
  pricingPage = 'pricingPage',
  signupPage = 'signupPage',
  changePlanPage = 'changePlanPage'
}
@Component({
  selector: 'app-pricing-info',
  templateUrl: './pricing-info.component.html',
  styleUrls: ['./pricing-info.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PricingInfoComponent implements OnInit {
  @Input() type: PricingInfoType = PricingInfoType.pricingPage;
  @Input() selectedModule: LicenseResponse;
  @Input() selectedPrice: PriceInfo;
  @Input() selectedPriceId: number;
  @Input() applyDate = '';
  @Input() isLandingPage = false;
  @Output() selectPlan = new EventEmitter<{
    license: LicenseResponse;
    licensePeriod: PriceInfo;
  }>();
  @Output() selectPrice = new EventEmitter<PriceInfo>();

  prices: PriceInfo[] = [];
  modules: LicenseResponse[] = [];
  PricingInfoType = PricingInfoType;
  constructor(private licenseService: LicenseService, private router: Router) {}

  ngOnInit(): void {
    this.modules =
      JSON.parse(localStorage.getItem(PRICING_DATA_KEY) || null) || [];
    this.mappingPackageInfo();
    this.prices =
      JSON.parse(localStorage.getItem(PRICING_PERIODS_KEY) || null) || [];
    this.licenseService.getLicense().subscribe(
      (data) => {
        this.modules = data;
        this.mappingPackageInfo();
        localStorage.setItem(PRICING_DATA_KEY, JSON.stringify(data));
      },
      (error) => {
        this.modules = [];
      }
    );

    this.licenseService.getLicensePeriod().subscribe(
      (data) => {
        this.prices = data?.map((e) => {
          const newPrices = {
            ...e,
            value: e.period,
            label: '',
            discount: e.discount,
            discountText: e.discount > 0 ? `-${e.discount}%` : ''
          };

          switch (e.period) {
            case 1:
              newPrices.label = '1 Month Price';
              break;
            case 6:
              newPrices.label = '6 Months Price';
              break;
            case 12:
              newPrices.label = 'Yearly Price';
              break;
          }

          return newPrices;
        });
        this.prices.reverse();
        if (this.selectedPriceId) {
          this.selectedPrice = this.prices.find(
            (e) => e.id === this.selectedPriceId
          );
        }
        if (!this.selectedPrice) {
          this.selectedPrice = this.prices.find((e) => e.value === 12);
        }
        localStorage.setItem(PRICING_PERIODS_KEY, JSON.stringify(this.prices));
      },
      (error) => {
        this.prices = [];
      }
    );
  }

  getRate(rate: number) {
    if (rate === 0) {
      return 'Free';
    }

    if (rate === null) {
      return 'Contact';
    }

    return `$${(
      rate -
      (rate * (this.selectedPrice?.discount || 0)) / 100
    ).toFixed(2)}`;
  }

  getBtnText(name: string) {
    if (name === 'Free') {
      return 'Register Free';
    }

    if (name === EnterprisePackageInfo.name) {
      return 'Contact Now';
    }

    return name === 'Free' ? 'Register Free' : 'Try FREE for 30 days';
  }

  onSelectModule(license: LicenseResponse) {
    if (license?.name === EnterprisePackageInfo.name) {
      this.router.navigate([routerObject.contactUs.fullPath]);
      return;
    }

    this.selectedModule = license;
    this.selectPlan.emit({ license, licensePeriod: this.selectedPrice });
  }

  onPriceChange(item: PriceInfo) {
    this.selectedPrice = item;
    this.selectPrice.emit(this.selectedPrice);
  }

  onClickModuleItem(license: LicenseResponse) {
    if (this.type === 'signupPage') {
      this.onSelectModule(license);
    }
  }

  compareWith(item, selected) {
    return item?.id === selected?.id;
  }

  mappingPackageInfo() {
    const enterprisePackageExists = this.modules?.some(
      (e) => e.name === EnterprisePackageInfo.name
    );

    if (!enterprisePackageExists && this.isLandingPage) {
      const newId = Math.max(...this.modules.map((e) => e.id)) + 1;
      this.modules = [...this.modules, { ...EnterprisePackageInfo, id: newId }];
    }

    if (enterprisePackageExists && !this.isLandingPage) {
      this.modules = this.modules.filter(
        (e) => e.name !== EnterprisePackageInfo.name
      );
    }

    this.modules = this.modules.map((e) => ({
      ...e,
      packageInfo: PackageInfo.find((f) => f.name === e.name)?.desc
    }));
  }
}
