import { PermissionSettingItem } from '../../_models';

export const ROLE_PERMISSION_CACHE_KEY = 'expercRolePermissionCache';
export const USER_PERMISSIONS_KEY = 'expercPermissions'

export const permissionCode = {
  TASK_MANAGEMENT_PROJECTS_EDIT_ALL_PROJECTS:
    'TASK_MANAGEMENT_PROJECTS_EDIT_ALL_PROJECTS',
  CUSTOMERS_EDIT: 'CUSTOMERS_EDIT',
  CUSTOMERS: 'CUSTOMERS',
  CUSTOMERS_VIEW: 'CUSTOMERS_VIEW',
  ORGANIZATION_USER_DETAIL: 'ORGANIZATION_USER_DETAIL',
  ORGANIZATION_USER_DETAIL_PERSONAL: 'ORGANIZATION_USER_DETAIL_PERSONAL',
  ORGANIZATION_USER_DETAIL_PERSONAL_BASIC_INFO:
    'ORGANIZATION_USER_DETAIL_PERSONAL_BASIC_INFO',
  ORGANIZATION_USER_DETAIL_PERSONAL_BASIC_INFO_VIEW:
    'ORGANIZATION_USER_DETAIL_PERSONAL_BASIC_INFO_VIEW',
  ORGANIZATION_USER_DETAIL_PERSONAL_BASIC_INFO_EDIT:
    'ORGANIZATION_USER_DETAIL_PERSONAL_BASIC_INFO_EDIT',
  ORGANIZATION_USER_DETAIL_CONTACT: 'ORGANIZATION_USER_DETAIL_CONTACT',
  ORGANIZATION_USER_DETAIL_CONTACT_VIEW:
    'ORGANIZATION_USER_DETAIL_CONTACT_VIEW',
  ORGANIZATION_USER_DETAIL_CONTACT_EDIT:
    'ORGANIZATION_USER_DETAIL_CONTACT_EDIT',
  ORGANIZATION_USER_DETAIL_BANK: 'ORGANIZATION_USER_DETAIL_BANK',
  ORGANIZATION_USER_DETAIL_BANK_VIEW: 'ORGANIZATION_USER_DETAIL_BANK_VIEW',
  ORGANIZATION_USER_DETAIL_BANK_EDIT: 'ORGANIZATION_USER_DETAIL_BANK_EDIT',
  ORGANIZATION_USER_DETAIL_WORK_INFO: 'ORGANIZATION_USER_DETAIL_WORK_INFO',
  ORGANIZATION_USER_DETAIL_WORK_INFO_LOCATION_GROUP_TEAM:
    'ORGANIZATION_USER_DETAIL_WORK_INFO_LOCATION_GROUP_TEAM',
  ORGANIZATION_USER_DETAIL_WORK_INFO_LOCATION_GROUP_TEAM_VIEW:
    'ORGANIZATION_USER_DETAIL_WORK_INFO_LOCATION_GROUP_TEAM_VIEW',
  ORGANIZATION_USER_DETAIL_WORK_INFO_LOCATION_GROUP_TEAM_EDIT:
    'ORGANIZATION_USER_DETAIL_WORK_INFO_LOCATION_GROUP_TEAM_EDIT',
  ORGANIZATION_USER_DETAIL_WORK_INFO_JOB_DATES:
    'ORGANIZATION_USER_DETAIL_WORK_INFO_JOB_DATES',
  ORGANIZATION_USER_DETAIL_WORK_INFO_JOB_DATES_VIEW:
    'ORGANIZATION_USER_DETAIL_WORK_INFO_JOB_DATES_VIEW',
  ORGANIZATION_USER_DETAIL_WORK_INFO_JOB_DATES_EDIT:
    'ORGANIZATION_USER_DETAIL_WORK_INFO_JOB_DATES_EDIT',
  ORGANIZATION_USER_DETAIL_WORK_INFO_SECTION_INSURANCE:
    'ORGANIZATION_USER_DETAIL_WORK_INFO_SECTION_INSURANCE',
  ORGANIZATION_USER_DETAIL_WORK_INFO_SECTION_INSURANCE_VIEW:
    'ORGANIZATION_USER_DETAIL_WORK_INFO_SECTION_INSURANCE_VIEW',
  ORGANIZATION_USER_DETAIL_WORK_INFO_SECTION_INSURANCE_EDIT:
    'ORGANIZATION_USER_DETAIL_WORK_INFO_SECTION_INSURANCE_EDIT',
  ORGANIZATION_USER_DETAIL_LEAVE_HISTORY:
    'ORGANIZATION_USER_DETAIL_LEAVE_HISTORY',
  ORGANIZATION_USER_DETAIL_LEAVE_HISTORY_LEAVE_HISTORY:
    'ORGANIZATION_USER_DETAIL_LEAVE_HISTORY_LEAVE_HISTORY',
  ORGANIZATION_USER_DETAIL_LEAVE_HISTORY_LEAVE_HISTORY_VIEW:
    'ORGANIZATION_USER_DETAIL_LEAVE_HISTORY_LEAVE_HISTORY_VIEW',
  ORGANIZATION_USER_DETAIL_LEAVE_HISTORY_LEAVE_HISTORY_EDIT:
    'ORGANIZATION_USER_DETAIL_LEAVE_HISTORY_LEAVE_HISTORY_EDIT',
  ORGANIZATION_USER_DETAIL_REPORT_HISTORY:
    'ORGANIZATION_USER_DETAIL_REPORT_HISTORY',
  ORGANIZATION_USER_DETAIL_REPORT_HISTORY_REPORT_HISTORY:
    'ORGANIZATION_USER_DETAIL_REPORT_HISTORY_REPORT_HISTORY',
  ORGANIZATION_USER_DETAIL_REPORT_HISTORY_REPORT_HISTORY_VIEW:
    'ORGANIZATION_USER_DETAIL_REPORT_HISTORY_REPORT_HISTORY_VIEW',
  ORGANIZATION_USER_DETAIL_REPORT_HISTORY_REPORT_HISTORY_EDIT:
    'ORGANIZATION_USER_DETAIL_REPORT_HISTORY_REPORT_HISTORY_EDIT',
  ORGANIZATION_USER_DETAIL_POLICIES: 'ORGANIZATION_USER_DETAIL_POLICIES',
  ORGANIZATION_USER_DETAIL_POLICIES_POLICIES:
    'ORGANIZATION_USER_DETAIL_POLICIES_POLICIES',
  ORGANIZATION_USER_DETAIL_POLICIES_POLICIES_VIEW:
    'ORGANIZATION_USER_DETAIL_POLICIES_POLICIES_VIEW',
  ORGANIZATION_USER_DETAIL_POLICIES_POLICIES_EDIT:
    'ORGANIZATION_USER_DETAIL_POLICIES_POLICIES_EDIT',
  ORGANIZATION_USER_DETAIL_ONBOARD_MISSIONS:
    'ORGANIZATION_USER_DETAIL_ONBOARD_MISSIONS',
  ORGANIZATION_USER_DETAIL_ONBOARD_MISSIONS_ONBOARD_MISSIONS:
    'ORGANIZATION_USER_DETAIL_ONBOARD_MISSIONS_ONBOARD_MISSIONS',
  ORGANIZATION_USER_DETAIL_ONBOARD_MISSIONS_ONBOARD_MISSIONS_VIEW:
    'ORGANIZATION_USER_DETAIL_ONBOARD_MISSIONS_ONBOARD_MISSIONS_VIEW',
  ORGANIZATION_USER_DETAIL_ONBOARD_MISSIONS_ONBOARD_MISSIONS_EDIT:
    'ORGANIZATION_USER_DETAIL_ONBOARD_MISSIONS_ONBOARD_MISSIONS_EDIT',
  ORGANIZATION_USER_DETAIL_SALARY_SETTING:
    'ORGANIZATION_USER_DETAIL_SALARY_SETTING',
  ORGANIZATION_USER_DETAIL_SALARY_SETTING_SALARY_SETTING:
    'ORGANIZATION_USER_DETAIL_SALARY_SETTING_SALARY_SETTING',
  ORGANIZATION_USER_DETAIL_SALARY_SETTING_SALARY_SETTING_VIEW:
    'ORGANIZATION_USER_DETAIL_SALARY_SETTING_SALARY_SETTING_VIEW',
  ORGANIZATION_USER_DETAIL_SALARY_SETTING_SALARY_SETTING_EDIT:
    'ORGANIZATION_USER_DETAIL_SALARY_SETTING_SALARY_SETTING_EDIT',
  ORGANIZATION_USER_DETAIL_NOTIFICATION:
    'ORGANIZATION_USER_DETAIL_NOTIFICATION',
  ORGANIZATION_USER_DETAIL_NOTIFICATION_NOTIFICATION:
    'ORGANIZATION_USER_DETAIL_NOTIFICATION_NOTIFICATION',
  ORGANIZATION_USER_DETAIL_NOTIFICATION_NOTIFICATION_VIEW:
    'ORGANIZATION_USER_DETAIL_NOTIFICATION_NOTIFICATION_VIEW',
  ORGANIZATION_USER_DETAIL_NOTIFICATION_NOTIFICATION_EDIT:
    'ORGANIZATION_USER_DETAIL_NOTIFICATION_NOTIFICATION_EDIT',
  HR_PAYROLL: 'HR_PAYROLL',
  LEAVE_SETTING_TYPE: 'LEAVE_SETTING_TYPE',
  USER_DASHBOARD: 'USER_DASHBOARD',
  USER_DASHBOARD_YOUR_TASKS: 'USER_DASHBOARD_YOUR_TASKS',
  USER_DASHBOARD_YOUR_TASKS_VIEW: 'USER_DASHBOARD_YOUR_TASKS_VIEW',
  USER_DASHBOARD_YOUR_TASKS_EDIT: 'USER_DASHBOARD_YOUR_TASKS_EDIT',
  USER_DASHBOARD_OVERDUE_TASKS: 'USER_DASHBOARD_OVERDUE_TASKS',
  USER_DASHBOARD_OVERDUE_TASKS_VIEW: 'USER_DASHBOARD_OVERDUE_TASKS_VIEW',
  USER_DASHBOARD_OVERDUE_TASKS_EDIT: 'USER_DASHBOARD_OVERDUE_TASKS_EDIT',
  USER_DASHBOARD_UNSET_DUE_DATE_TASKS: 'USER_DASHBOARD_UNSET_DUE_DATE_TASKS',
  USER_DASHBOARD_UNSET_DUE_DATE_TASKS_VIEW:
    'USER_DASHBOARD_UNSET_DUE_DATE_TASKS_VIEW',
  USER_DASHBOARD_UNSET_DUE_DATE_TASKS_EDIT:
    'USER_DASHBOARD_UNSET_DUE_DATE_TASKS_EDIT',
  USER_DASHBOARD_BOOKMARKED_TASKS: 'USER_DASHBOARD_BOOKMARKED_TASKS',
  USER_DASHBOARD_BOOKMARKED_TASKS_VIEW: 'USER_DASHBOARD_BOOKMARKED_TASKS_VIEW',
  USER_DASHBOARD_BOOKMARKED_TASKS_EDIT: 'USER_DASHBOARD_BOOKMARKED_TASKS_EDIT',
  LEAVE: 'LEAVE',
  LEAVE_DASHBOARD: 'LEAVE_DASHBOARD',
  LEAVE_DASHBOARD_WHO_IS_WFH_TODAY: 'LEAVE_DASHBOARD_WHO_IS_WFH_TODAY',
  LEAVE_DASHBOARD_WHO_IS_WFH_TODAY_VIEW:
    'LEAVE_DASHBOARD_WHO_IS_WFH_TODAY_VIEW',
  LEAVE_DASHBOARD_WHO_IS_WFH_TODAY_EDIT:
    'LEAVE_DASHBOARD_WHO_IS_WFH_TODAY_EDIT',
  LEAVE_DASHBOARD_LEAVE_DASHBOARD: 'LEAVE_DASHBOARD_LEAVE_DASHBOARD',
  LEAVE_DASHBOARD_LEAVE_DASHBOARD_VIEW: 'LEAVE_DASHBOARD_LEAVE_DASHBOARD_VIEW',
  LEAVE_DASHBOARD_LEAVE_DASHBOARD_EDIT: 'LEAVE_DASHBOARD_LEAVE_DASHBOARD_EDIT',
  LEAVE_DASHBOARD_WHO_IS_OFF_TODAY: 'LEAVE_DASHBOARD_WHO_IS_OFF_TODAY',
  LEAVE_DASHBOARD_WHO_IS_OFF_TODAY_VIEW:
    'LEAVE_DASHBOARD_WHO_IS_OFF_TODAY_VIEW',
  LEAVE_DASHBOARD_WHO_IS_OFF_TODAY_EDIT:
    'LEAVE_DASHBOARD_WHO_IS_OFF_TODAY_EDIT',
  LEAVE_DASHBOARD_BALANCE_DETAIL: 'LEAVE_DASHBOARD_BALANCE_DETAIL',
  LEAVE_DASHBOARD_BALANCE_DETAIL_VIEW: 'LEAVE_DASHBOARD_BALANCE_DETAIL_VIEW',
  LEAVE_DASHBOARD_BALANCE_DETAIL_EDIT: 'LEAVE_DASHBOARD_BALANCE_DETAIL_EDIT',
  LEAVE_MY_REQUEST: 'LEAVE_MY_REQUEST',
  LEAVE_MY_REQUEST_VIEW: 'LEAVE_MY_REQUEST_VIEW',
  LEAVE_MY_REQUEST_EDIT: 'LEAVE_MY_REQUEST_EDIT',
  LEAVE_APPROVAL: 'LEAVE_APPROVAL',
  LEAVE_APPROVAL_VIEW: 'LEAVE_APPROVAL_VIEW',
  LEAVE_APPROVAL_EDIT: 'LEAVE_APPROVAL_EDIT',
  LEAVE_BALANCE: 'LEAVE_BALANCE',
  LEAVE_BALANCE_VIEW: 'LEAVE_BALANCE_VIEW',
  LEAVE_BALANCE_EDIT: 'LEAVE_BALANCE_EDIT',
  LEAVE_REPORT: 'LEAVE_REPORT',
  LEAVE_REPORT_VIEW: 'LEAVE_REPORT_VIEW',
  LEAVE_REPORT_EDIT: 'LEAVE_REPORT_EDIT',
  LEAVE_SETTING: 'LEAVE_SETTING',
  LEAVE_SETTING_CATEGORY: 'LEAVE_SETTING_CATEGORY',
  LEAVE_SETTING_CATEGORY_VIEW: 'LEAVE_SETTING_CATEGORY_VIEW',
  LEAVE_SETTING_CATEGORY_EDIT: 'LEAVE_SETTING_CATEGORY_EDIT',
  LEAVE_SETTING_TYPE_VIEW: 'LEAVE_SETTING_TYPE_VIEW',
  LEAVE_SETTING_TYPE_EDIT: 'LEAVE_SETTING_TYPE_EDIT',
  LEAVE_SETTING_CARRY_OVER: 'LEAVE_SETTING_CARRY_OVER',
  LEAVE_SETTING_CARRY_OVER_VIEW: 'LEAVE_SETTING_CARRY_OVER_VIEW',
  LEAVE_SETTING_CARRY_OVER_EDIT: 'LEAVE_SETTING_CARRY_OVER_EDIT',
  WORK_TRACKING: 'WORK_TRACKING',
  WORK_TRACKING_DASHBOARD: 'WORK_TRACKING_DASHBOARD',
  WORK_TRACKING_DASHBOARD_VIEW: 'WORK_TRACKING_DASHBOARD_VIEW',
  WORK_TRACKING_DASHBOARD_EDIT: 'WORK_TRACKING_DASHBOARD_EDIT',
  WORK_TRACKING_MY_TRACKING: 'WORK_TRACKING_MY_TRACKING',
  WORK_TRACKING_MY_TRACKING_VIEW: 'WORK_TRACKING_MY_TRACKING_VIEW',
  WORK_TRACKING_MY_TRACKING_EDIT: 'WORK_TRACKING_MY_TRACKING_EDIT',
  WORK_TRACKING_SETTING: 'WORK_TRACKING_SETTING',
  WORK_TRACKING_SETTING_CHOOSE_LEAVE_TYPE:
    'WORK_TRACKING_SETTING_CHOOSE_LEAVE_TYPE',
  WORK_TRACKING_SETTING_CHOOSE_LEAVE_TYPE_VIEW:
    'WORK_TRACKING_SETTING_CHOOSE_LEAVE_TYPE_VIEW',
  WORK_TRACKING_SETTING_CHOOSE_LEAVE_TYPE_EDIT:
    'WORK_TRACKING_SETTING_CHOOSE_LEAVE_TYPE_EDIT',
  WORK_TRACKING_SETTING_SHIFT_PROFILE_SETTING:
    'WORK_TRACKING_SETTING_SHIFT_PROFILE_SETTING',
  WORK_TRACKING_SETTING_SHIFT_PROFILE_SETTING_VIEW:
    'WORK_TRACKING_SETTING_SHIFT_PROFILE_SETTING_VIEW',
  WORK_TRACKING_SETTING_SHIFT_PROFILE_SETTING_EDIT:
    'WORK_TRACKING_SETTING_SHIFT_PROFILE_SETTING_EDIT',
  TIMESHEET: 'TIMESHEET',
  TIMESHEET_MY_LOGWORK: 'TIMESHEET_MY_LOGWORK',
  TIMESHEET_MY_LOGWORK_VIEW: 'TIMESHEET_MY_LOGWORK_VIEW',
  TIMESHEET_MY_LOGWORK_EDIT: 'TIMESHEET_MY_LOGWORK_EDIT',
  TIMESHEET_REPORTS: 'TIMESHEET_REPORTS',
  TIMESHEET_REPORTS_VIEW: 'TIMESHEET_REPORTS_VIEW',
  TIMESHEET_REPORTS_EDIT: 'TIMESHEET_REPORTS_EDIT',
  TIMESHEET_SETTING_ADMIN: 'TIMESHEET_SETTING_ADMIN',
  TASK_MANAGEMENT: 'TASK_MANAGEMENT',
  TASK_MANAGEMENT_GIANTT: 'TASK_MANAGEMENT_GIANTT',
  TASK_MANAGEMENT_GIANTT_VIEW: 'TASK_MANAGEMENT_GIANTT_VIEW',
  TASK_MANAGEMENT_GIANTT_EDIT: 'TASK_MANAGEMENT_GIANTT_EDIT',
  TASK_MANAGEMENT_PROJECTS: 'TASK_MANAGEMENT_PROJECTS',
  TASK_MANAGEMENT_PROJECTS_VIEW: 'TASK_MANAGEMENT_PROJECTS_VIEW',
  TASK_MANAGEMENT_PROJECTS_EDIT: 'TASK_MANAGEMENT_PROJECTS_EDIT',
  TASK_MANAGEMENT_TASK: 'TASK_MANAGEMENT_TASK',
  TASK_MANAGEMENT_TASK_VIEW: 'TASK_MANAGEMENT_TASK_VIEW',
  TASK_MANAGEMENT_TASK_EDIT: 'TASK_MANAGEMENT_TASK_EDIT',
  TASK_MANAGEMENT_ACTIVE_SPRINT_KANBAN_BOARD:
    'TASK_MANAGEMENT_ACTIVE_SPRINT_KANBAN_BOARD',
  TASK_MANAGEMENT_ACTIVE_SPRINT_KANBAN_BOARD_VIEW:
    'TASK_MANAGEMENT_ACTIVE_SPRINT_KANBAN_BOARD_VIEW',
  TASK_MANAGEMENT_RELEASE: 'TASK_MANAGEMENT_RELEASE',
  TASK_MANAGEMENT_RELEASE_RELEASE: 'TASK_MANAGEMENT_RELEASE_RELEASE',
  TASK_MANAGEMENT_RELEASE_RELEASE_VIEW: 'TASK_MANAGEMENT_RELEASE_RELEASE_VIEW',
  TASK_MANAGEMENT_RELEASE_RELEASE_EDIT: 'TASK_MANAGEMENT_RELEASE_RELEASE_EDIT',
  TASK_MANAGEMENT_RELEASE_RELEASE_DETAIL:
    'TASK_MANAGEMENT_RELEASE_RELEASE_DETAIL',
  TASK_MANAGEMENT_RELEASE_RELEASE_DETAIL_VIEW:
    'TASK_MANAGEMENT_RELEASE_RELEASE_DETAIL_VIEW',
  TASK_MANAGEMENT_RELEASE_RELEASE_DETAIL_EDIT:
    'TASK_MANAGEMENT_RELEASE_RELEASE_DETAIL_EDIT',
  TASK_MANAGEMENT_RELEASE_RELEASE_NOTE: 'TASK_MANAGEMENT_RELEASE_RELEASE_NOTE',
  TASK_MANAGEMENT_RELEASE_RELEASE_NOTE_VIEW:
    'TASK_MANAGEMENT_RELEASE_RELEASE_NOTE_VIEW',
  TASK_MANAGEMENT_RELEASE_RELEASE_NOTE_EDIT:
    'TASK_MANAGEMENT_RELEASE_RELEASE_NOTE_EDIT',
  TASK_MANAGEMENT_BACKLOG: 'TASK_MANAGEMENT_BACKLOG',
  TASK_MANAGEMENT_BACKLOG_VIEW: 'TASK_MANAGEMENT_BACKLOG_VIEW',
  TASK_MANAGEMENT_SPRINT: 'TASK_MANAGEMENT_SPRINT',
  TASK_MANAGEMENT_SPRINT_VIEW: 'TASK_MANAGEMENT_SPRINT_VIEW',
  TASK_MANAGEMENT_SPRINT_EDIT: 'TASK_MANAGEMENT_SPRINT_EDIT',
  TASK_MANAGEMENT_EPIC: 'TASK_MANAGEMENT_EPIC',
  TASK_MANAGEMENT_EPIC_VIEW_EPIC_SCREEN: 'TASK_MANAGEMENT_EPIC_VIEW_EPIC_SCREEN',
  TASK_MANAGEMENT_EPIC_DETAIL: 'TASK_MANAGEMENT_EPIC_DETAIL',
  TASK_MANAGEMENT_EPIC_DETAIL_VIEW: 'TASK_MANAGEMENT_EPIC_DETAIL_VIEW',
  TASK_MANAGEMENT_EPIC_DETAIL_EDIT: 'TASK_MANAGEMENT_EPIC_DETAIL_EDIT',
  TASK_MANAGEMENT_PROJECT_SETTING: 'TASK_MANAGEMENT_PROJECT_SETTING',
  TASK_MANAGEMENT_PROJECT_SETTING_GENERAL:
    'TASK_MANAGEMENT_PROJECT_SETTING_GENERAL',
  TASK_MANAGEMENT_PROJECT_SETTING_GENERAL_VIEW:
    'TASK_MANAGEMENT_PROJECT_SETTING_GENERAL_VIEW',
  TASK_MANAGEMENT_PROJECT_SETTING_GENERAL_EDIT:
    'TASK_MANAGEMENT_PROJECT_SETTING_GENERAL_EDIT',
  TASK_MANAGEMENT_PROJECT_SETTING_PROJECT_MEMBERS:
    'TASK_MANAGEMENT_PROJECT_SETTING_PROJECT_MEMBERS',
  TASK_MANAGEMENT_PROJECT_SETTING_PROJECT_MEMBERS_VIEW:
    'TASK_MANAGEMENT_PROJECT_SETTING_PROJECT_MEMBERS_VIEW',
  TASK_MANAGEMENT_PROJECT_SETTING_PROJECT_MEMBERS_EDIT:
    'TASK_MANAGEMENT_PROJECT_SETTING_PROJECT_MEMBERS_EDIT',
  TASK_MANAGEMENT_PROJECT_SETTING_BOARD_SETTINGS:
    'TASK_MANAGEMENT_PROJECT_SETTING_BOARD_SETTINGS',
  TASK_MANAGEMENT_PROJECT_SETTING_BOARD_SETTINGS_VIEW:
    'TASK_MANAGEMENT_PROJECT_SETTING_BOARD_SETTINGS_VIEW',
  TASK_MANAGEMENT_PROJECT_SETTING_BOARD_SETTINGS_EDIT:
    'TASK_MANAGEMENT_PROJECT_SETTING_BOARD_SETTINGS_EDIT',
  TASK_MANAGEMENT_PROJECT_SETTING_TASK_TYPE:
    'TASK_MANAGEMENT_PROJECT_SETTING_TASK_TYPE',
  TASK_MANAGEMENT_PROJECT_SETTING_TASK_TYPE_VIEW:
    'TASK_MANAGEMENT_PROJECT_SETTING_TASK_TYPE_VIEW',
  TASK_MANAGEMENT_PROJECT_SETTING_TASK_TYPE_EDIT:
    'TASK_MANAGEMENT_PROJECT_SETTING_TASK_TYPE_EDIT',
  TASK_MANAGEMENT_PROJECT_SETTING_PRIORITY:
    'TASK_MANAGEMENT_PROJECT_SETTING_PRIORITY',
  TASK_MANAGEMENT_PROJECT_SETTING_PRIORITY_VIEW:
    'TASK_MANAGEMENT_PROJECT_SETTING_PRIORITY_VIEW',
  TASK_MANAGEMENT_PROJECT_SETTING_PRIORITY_EDIT:
    'TASK_MANAGEMENT_PROJECT_SETTING_PRIORITY_EDIT',
  TASK_MANAGEMENT_PROJECT_SETTING_STORYLINE:
    'TASK_MANAGEMENT_PROJECT_SETTING_STORYLINE',
  TASK_MANAGEMENT_PROJECT_SETTING_STORYLINE_VIEW:
    'TASK_MANAGEMENT_PROJECT_SETTING_STORYLINE_VIEW',
  TASK_MANAGEMENT_PROJECT_SETTING_STORYLINE_EDIT:
    'TASK_MANAGEMENT_PROJECT_SETTING_STORYLINE_EDIT',
  TASK_MANAGEMENT_PROJECT_SETTING_LABEL:
    'TASK_MANAGEMENT_PROJECT_SETTING_LABEL',
  TASK_MANAGEMENT_PROJECT_SETTING_LABEL_VIEW:
    'TASK_MANAGEMENT_PROJECT_SETTING_LABEL_VIEW',
  TASK_MANAGEMENT_PROJECT_SETTING_LABEL_EDIT:
    'TASK_MANAGEMENT_PROJECT_SETTING_LABEL_EDIT',
  TASK_MANAGEMENT_PROJECT_SETTING_HISTORY:
    'TASK_MANAGEMENT_PROJECT_SETTING_HISTORY',
  TASK_MANAGEMENT_PROJECT_SETTING_HISTORY_VIEW:
    'TASK_MANAGEMENT_PROJECT_SETTING_HISTORY_VIEW',
  TASK_MANAGEMENT_PROJECT_SETTING_HISTORY_EDIT:
    'TASK_MANAGEMENT_PROJECT_SETTING_HISTORY_EDIT',
  TASK_MANAGEMENT_STORYLINE: 'TASK_MANAGEMENT_STORYLINE',
  TASK_MANAGEMENT_STORYLINE_VIEW_STORYLINE_SCREEN: 'TASK_MANAGEMENT_STORYLINE_VIEW_STORYLINE_SCREEN',
  TASK_MANAGEMENT_STORYLINE_DETAIL: 'TASK_MANAGEMENT_STORYLINE_DETAIL',
  TASK_MANAGEMENT_STORYLINE_DETAIL_VIEW: 'TASK_MANAGEMENT_STORYLINE_DETAIL_VIEW',
  TASK_MANAGEMENT_STORYLINE_DETAIL_EDIT: 'TASK_MANAGEMENT_STORYLINE_DETAIL_EDIT',
  TASK_MANAGEMENT_TASK_TEMPLATE: 'TASK_MANAGEMENT_TASK_TEMPLATE',
  TASK_MANAGEMENT_TASK_TEMPLATE_VIEW_TASK_TEMPLATE_SCREEN: 'TASK_MANAGEMENT_TASK_TEMPLATE_VIEW_TASK_TEMPLATE_SCREEN',
  TASK_MANAGEMENT_TASK_TEMPLATE_DETAIL: 'TASK_MANAGEMENT_TASK_TEMPLATE_DETAIL',
  TASK_MANAGEMENT_TASK_TEMPLATE_DETAIL_VIEW: 'TASK_MANAGEMENT_TASK_TEMPLATE_DETAIL_VIEW',
  TASK_MANAGEMENT_TASK_TEMPLATE_DETAIL_EDIT: 'TASK_MANAGEMENT_TASK_TEMPLATE_DETAIL_EDIT',
  DOCUMENT: 'DOCUMENT',
  DOCUMENT_PROJECTS: 'DOCUMENT_PROJECTS',
  DOCUMENT_PROJECTS_VIEW: 'DOCUMENT_PROJECTS_VIEW',
  DOCUMENT_PROJECTS_EDIT: 'DOCUMENT_PROJECTS_EDIT',
  DOCUMENT_COMPANY: 'DOCUMENT_COMPANY',
  DOCUMENT_COMPANY_VIEW: 'DOCUMENT_COMPANY_VIEW',
  DOCUMENT_COMPANY_EDIT: 'DOCUMENT_COMPANY_EDIT',
  DOCUMENT_SYSTEM_FIELD: 'DOCUMENT_SYSTEM_FIELD',
  DOCUMENT_SYSTEM_FIELD_VIEW: 'DOCUMENT_SYSTEM_FIELD_VIEW',
  DOCUMENT_SYSTEM_FIELD_EDIT: 'DOCUMENT_SYSTEM_FIELD_EDIT',
  DOCUMENT_RECENT: 'DOCUMENT_RECENT',
  DOCUMENT_RECENT_VIEW: 'DOCUMENT_RECENT_VIEW',
  DOCUMENT_RECENT_EDIT: 'DOCUMENT_RECENT_EDIT',
  DOCUMENT_RECYCLE_BIN: 'DOCUMENT_RECYCLE_BIN',
  DOCUMENT_RECYCLE_BIN_VIEW: 'DOCUMENT_RECYCLE_BIN_VIEW',
  DOCUMENT_RECYCLE_BIN_EDIT: 'DOCUMENT_RECYCLE_BIN_EDIT',
  DOCUMENT_FILE_PROVIDER: 'DOCUMENT_FILE_PROVIDER',
  DOCUMENT_FILE_PROVIDER_VIEW: 'DOCUMENT_FILE_PROVIDER_VIEW',
  DOCUMENT_FILE_PROVIDER_EDIT: 'DOCUMENT_FILE_PROVIDER_EDIT',
  DOCUMENT_MANAGE_STORAGE: 'DOCUMENT_MANAGE_STORAGE',
  DOCUMENT_MANAGE_STORAGE_VIEW: 'DOCUMENT_MANAGE_STORAGE_VIEW',
  DOCUMENT_MANAGE_STORAGE_EDIT: 'DOCUMENT_MANAGE_STORAGE_EDIT',
  OKR: 'OKR',
  OKR_OKR_SETTINGS: 'OKR_OKR_SETTINGS',
  OKR_OKR_SETTINGS_SPACES: 'OKR_OKR_SETTINGS_SPACES',
  OKR_OKR_SETTINGS_SPACES_VIEW: 'OKR_OKR_SETTINGS_SPACES_VIEW',
  OKR_OKR_SETTINGS_SPACES_EDIT: 'OKR_OKR_SETTINGS_SPACES_EDIT',
  OKR_OKR_SETTINGS_TIMELINE: 'OKR_OKR_SETTINGS_TIMELINE',
  OKR_OKR_SETTINGS_TIMELINES_VIEW: 'OKR_OKR_SETTINGS_TIMELINES_VIEW',
  OKR_OKR_SETTINGS_TIMELINES_EDIT: 'OKR_OKR_SETTINGS_TIMELINES_EDIT',
  OKR_OKR_SETTINGS_METRICS: 'OKR_OKR_SETTINGS_METRICS',
  OKR_OKR_SETTINGS_METRICS_VIEW: 'OKR_OKR_SETTINGS_METRICS_VIEW',
  OKR_OKR_SETTINGS_METRICS_EDIT: 'OKR_OKR_SETTINGS_METRICS_EDIT',
  ORGANIZATION: 'ORGANIZATION',
  ORGANIZATION_LOCATION: 'ORGANIZATION_LOCATION',
  ORGANIZATION_LOCATION_VIEW: 'ORGANIZATION_LOCATION_VIEW',
  ORGANIZATION_LOCATION_EDIT: 'ORGANIZATION_LOCATION_EDIT',
  ORGANIZATION_TEAM: 'ORGANIZATION_TEAM',
  ORGANIZATION_TEAM_VIEW: 'ORGANIZATION_TEAM_VIEW',
  ORGANIZATION_TEAM_EDIT: 'ORGANIZATION_TEAM_EDIT',
  ORGANIZATION_GROUP: 'ORGANIZATION_GROUP',
  ORGANIZATION_GROUP_VIEW: 'ORGANIZATION_GROUP_VIEW',
  ORGANIZATION_GROUP_EDIT: 'ORGANIZATION_GROUP_EDIT',
  ORGANIZATION_USER: 'ORGANIZATION_USER',
  ORGANIZATION_USER_VIEW: 'ORGANIZATION_USER_VIEW',
  ORGANIZATION_USER_EDIT: 'ORGANIZATION_USER_EDIT',
  ORGANIZATION_SHIFT: 'ORGANIZATION_SHIFT',
  ORGANIZATION_SHIFT_SHIFT: 'ORGANIZATION_SHIFT_SHIFT',
  ORGANIZATION_SHIFT_SHIFT_VIEW: 'ORGANIZATION_SHIFT_SHIFT_VIEW',
  ORGANIZATION_SHIFT_SHIFT_EDIT: 'ORGANIZATION_SHIFT_SHIFT_EDIT',
  ORGANIZATION_SHIFT_SHIFT_PROFILE: 'ORGANIZATION_SHIFT_SHIFT_PROFILE',
  ORGANIZATION_SHIFT_SHIFT_PROFILE_VIEW:
    'ORGANIZATION_SHIFT_SHIFT_PROFILE_VIEW',
  ORGANIZATION_SHIFT_SHIFT_PROFILE_EDIT:
    'ORGANIZATION_SHIFT_SHIFT_PROFILE_EDIT',
  ORGANIZATION_ROLE_PERMISSION: 'ORGANIZATION_ROLE_PERMISSION',
  ORGANIZATION_ROLE_PERMISSION_VIEW: 'ORGANIZATION_ROLE_PERMISSION_VIEW',
  ORGANIZATION_ROLE_PERMISSION_EDIT: 'ORGANIZATION_ROLE_PERMISSION_EDIT',
  ORGANIZATION_HOLIDAY: 'ORGANIZATION_HOLIDAY',
  ORGANIZATION_HOLIDAY_VIEW: 'ORGANIZATION_HOLIDAY_VIEW',
  ORGANIZATION_HOLIDAY_EDIT: 'ORGANIZATION_HOLIDAY_EDIT',
  ORGANIZATION_INTEGRATION: 'ORGANIZATION_INTEGRATION',
  ORGANIZATION_INTEGRATION_SLACK: 'ORGANIZATION_INTEGRATION_SLACK',
  ORGANIZATION_INTEGRATION_SLACK_VIEW: 'ORGANIZATION_INTEGRATION_SLACK_VIEW',
  ORGANIZATION_INTEGRATION_SLACK_EDIT: 'ORGANIZATION_INTEGRATION_SLACK_EDIT',
  ORGANIZATION_INTEGRATION_JIRA: 'ORGANIZATION_INTEGRATION_JIRA',
  ORGANIZATION_INTEGRATION_JIRA_VIEW: 'ORGANIZATION_INTEGRATION_JIRA_VIEW',
  ORGANIZATION_INTEGRATION_JIRA_EDIT: 'ORGANIZATION_INTEGRATION_JIRA_EDIT',
  ORGANIZATION_BILLING: 'ORGANIZATION_BILLING',
  ORGANIZATION_BILLING_CURRENT_PLAN: 'ORGANIZATION_BILLING_CURRENT_PLAN',
  ORGANIZATION_BILLING_CURRENT_PLAN_VIEW:
    'ORGANIZATION_BILLING_CURRENT_PLAN_VIEW',
  ORGANIZATION_BILLING_CURRENT_PLAN_EDIT:
    'ORGANIZATION_BILLING_CURRENT_PLAN_EDIT',
  ORGANIZATION_BILLING_NEXT_CHARGE_ESTIMATE:
    'ORGANIZATION_BILLING_NEXT_CHARGE_ESTIMATE',
  ORGANIZATION_BILLING_NEXT_CHARGE_ESTIMATE_VIEW:
    'ORGANIZATION_BILLING_NEXT_CHARGE_ESTIMATE_VIEW',
  ORGANIZATION_BILLING_NEXT_CHARGE_ESTIMATE_EDIT:
    'ORGANIZATION_BILLING_NEXT_CHARGE_ESTIMATE_EDIT',
  ORGANIZATION_BILLING_INFORMATION: 'ORGANIZATION_BILLING_INFORMATION',
  ORGANIZATION_BILLING_INFORMATION_VIEW:
    'ORGANIZATION_BILLING_INFORMATION_VIEW',
  ORGANIZATION_BILLING_INFORMATION_EDIT:
    'ORGANIZATION_BILLING_INFORMATION_EDIT',
  ORGANIZATION_BILLING_HISTORY: 'ORGANIZATION_BILLING_HISTORY',
  ORGANIZATION_BILLING_HISTORY_VIEW: 'ORGANIZATION_BILLING_HISTORY_VIEW',
  ORGANIZATION_BILLING_HISTORY_EDIT: 'ORGANIZATION_BILLING_HISTORY_EDIT',
  ORGANIZATION_BILLING_PAYMENT_METHOD: 'ORGANIZATION_BILLING_PAYMENT_METHOD',
  ORGANIZATION_BILLING_PAYMENT_METHOD_VIEW:
    'ORGANIZATION_BILLING_PAYMENT_METHOD_VIEW',
  ORGANIZATION_BILLING_PAYMENT_METHOD_EDIT:
    'ORGANIZATION_BILLING_PAYMENT_METHOD_EDIT',
  ORGANIZATION_BILLING_BILLING: 'ORGANIZATION_BILLING_BILLING',
  ORGANIZATION_BILLING_BILLING_VIEW: 'ORGANIZATION_BILLING_BILLING_VIEW',
  ORGANIZATION_BILLING_BILLING_EDIT: 'ORGANIZATION_BILLING_BILLING_EDIT',
  ORGANIZATION_ORGANIZATION_TREE: 'ORGANIZATION_ORGANIZATION_TREE',
  ORGANIZATION_ORGANIZATION_TREE_ORGANIZATION_TREE:
    'ORGANIZATION_ORGANIZATION_TREE_ORGANIZATION_TREE',
  ORGANIZATION_ORGANIZATION_TREE_ORGANIZATION_TREE_VIEW:
    'ORGANIZATION_ORGANIZATION_TREE_ORGANIZATION_TREE_VIEW',
  ORGANIZATION_ORGANIZATION_TREE_ORGANIZATION_TREE_EDIT:
    'ORGANIZATION_ORGANIZATION_TREE_ORGANIZATION_TREE_EDIT',
  ORGANIZATION_ORGANIZATION_TREE_COMPANY_STRUCTURE:
    'ORGANIZATION_ORGANIZATION_TREE_COMPANY_STRUCTURE',
  ORGANIZATION_ORGANIZATION_TREE_COMPANY_STRUCTURE_VIEW:
    'ORGANIZATION_ORGANIZATION_TREE_COMPANY_STRUCTURE_VIEW',
  ORGANIZATION_ORGANIZATION_TREE_COMPANY_STRUCTURE_EDIT:
    'ORGANIZATION_ORGANIZATION_TREE_COMPANY_STRUCTURE_EDIT',
  ORGANIZATION_ORGANIZATION_TREE_JOB_TITLE:
    'ORGANIZATION_ORGANIZATION_TREE_JOB_TITLE',
  ORGANIZATION_ORGANIZATION_TREE_JOB_TITLE_VIEW:
    'ORGANIZATION_ORGANIZATION_TREE_JOB_TITLE_VIEW',
  ORGANIZATION_ORGANIZATION_TREE_JOB_TITLE_EDIT:
    'ORGANIZATION_ORGANIZATION_TREE_JOB_TITLE_EDIT',
  HR: 'HR',
  HR_HIRING_BOARD: 'HR_HIRING_BOARD',
  HR_HIRING_BOARD_HIRING_BOARD: 'HR_HIRING_BOARD_HIRING_BOARD',
  HR_HIRING_BOARD_HIRING_BOARD_VIEW: 'HR_HIRING_BOARD_HIRING_BOARD_VIEW',
  HR_HIRING_BOARD_HIRING_BOARD_EDIT: 'HR_HIRING_BOARD_HIRING_BOARD_EDIT',
  HR_HIRING_BOARD_CV_DETAIL: 'HR_HIRING_BOARD_CV_DETAIL',
  HR_HIRING_BOARD_CV_DETAIL_VIEW: 'HR_HIRING_BOARD_CV_DETAIL_VIEW',
  HR_HIRING_BOARD_CV_DETAIL_EDIT: 'HR_HIRING_BOARD_CV_DETAIL_EDIT',
  HR_POLICY: 'HR_POLICY',
  HR_POLICY_POLICY: 'HR_POLICY_POLICY',
  HR_POLICY_POLICY_VIEW: 'HR_POLICY_POLICY_VIEW',
  HR_POLICY_POLICY_EDIT: 'HR_POLICY_POLICY_EDIT',
  HR_POLICY_BENEFIT_RULE: 'HR_POLICY_BENEFIT_RULE',
  HR_POLICY_BENEFIT_RULE_VIEW: 'HR_POLICY_BENEFIT_RULE_VIEW',
  HR_POLICY_BENEFIT_RULE_EDIT: 'HR_POLICY_BENEFIT_RULE_EDIT',
  HR_ONBOARD_MANAGEMENT: 'HR_ONBOARD_MANAGEMENT',
  HR_ONBOARD_MANAGEMENT_VIEW: 'HR_ONBOARD_MANAGEMENT_VIEW',
  HR_ONBOARD_MANAGEMENT_EDIT: 'HR_ONBOARD_MANAGEMENT_EDIT',
  HR_SETTINGS: 'HR_SETTINGS',
  HR_SETTINGS_HR_MEMBER: 'HR_SETTINGS_HR_MEMBER',
  HR_SETTINGS_HR_MEMBER_VIEW: 'HR_SETTINGS_HR_MEMBER_VIEW',
  HR_SETTINGS_HR_MEMBER_EDIT: 'HR_SETTINGS_HR_MEMBER_EDIT',
  HR_SETTINGS_ONBOARD_MEMBER: 'HR_SETTINGS_ONBOARD_MEMBER',
  HR_SETTINGS_ONBOARD_MEMBER_VIEW: 'HR_SETTINGS_ONBOARD_MEMBER_VIEW',
  HR_SETTINGS_ONBOARD_MEMBER_EDIT: 'HR_SETTINGS_ONBOARD_MEMBER_EDIT',
  HR_SETTINGS_ONBOARD_MANAGEMENT: 'HR_SETTINGS_ONBOARD_MANAGEMENT',
  HR_SETTINGS_ONBOARD_MANAGEMENT_VIEW: 'HR_SETTINGS_ONBOARD_MANAGEMENT_VIEW',
  HR_SETTINGS_ONBOARD_MANAGEMENT_EDIT: 'HR_SETTINGS_ONBOARD_MANAGEMENT_EDIT',
  HR_SETTINGS_HIRING_BOARD: 'HR_SETTINGS_HIRING_BOARD',
  HR_SALARY_PROFILE: 'HR_SALARY_PROFILE',
  HR_SALARY_PROFILE_VIEW: 'HR_SALARY_PROFILE_VIEW',
  HR_SALARY_PROFILE_EDIT: 'HR_SALARY_PROFILE_EDIT',
  HR_PAY_SCHEDULE: 'HR_PAY_SCHEDULE',
  HR_PAY_SCHEDULE_VIEW: 'HR_PAY_SCHEDULE_VIEW',
  HR_PAY_SCHEDULE_EDIT: 'HR_PAY_SCHEDULE_EDIT',
  HR_PAYROLL_VIEW: 'HR_PAYROLL_VIEW',
  HR_PAYROLL_EDIT: 'HR_PAYROLL_EDIT',
  AUDIT_LOG: 'AUDIT_LOG',
  AUDIT_LOG_VIEW: 'AUDIT_LOG_VIEW',
  AUDIT_LOG_EDIT: 'AUDIT_LOG_EDIT',
  SETTINGS: 'SETTINGS',
  SETTINGS_DYNAMIC_FIELD: 'SETTINGS_DYNAMIC_FIELD',
  SETTINGS_DYNAMIC_FIELD_VIEW: 'SETTINGS_DYNAMIC_FIELD_VIEW',
  SETTINGS_DYNAMIC_FIELD_EDIT: 'SETTINGS_DYNAMIC_FIELD_EDIT',
  SETTINGS_LANGUAGE: 'SETTINGS_LANGUAGE',
  SETTINGS_LANGUAGE_VIEW: 'SETTINGS_LANGUAGE_VIEW',
  SETTINGS_LANGUAGE_EDIT: 'SETTINGS_LANGUAGE_EDIT',
  SETTINGS_NOTIFICATIONS: 'SETTINGS_NOTIFICATIONS',
  SETTINGS_NOTIFICATIONS_VIEW: 'SETTINGS_NOTIFICATIONS_VIEW',
  SETTINGS_NOTIFICATIONS_EDIT: 'SETTINGS_NOTIFICATIONS_EDIT',
  SETTINGS_DOCUMENT_TEMPLATE: 'SETTINGS_DOCUMENT_TEMPLATE',
  SETTINGS_DOCUMENT_TEMPLATE_VIEW: 'SETTINGS_DOCUMENT_TEMPLATE_VIEW',
  SETTINGS_DOCUMENT_TEMPLATE_EDIT: 'SETTINGS_DOCUMENT_TEMPLATE_EDIT',
  SETTINGS_EVENT_LOCATION: 'SETTINGS_EVENT_LOCATION',
  SETTINGS_EVENT_LOCATION_VIEW: 'SETTINGS_EVENT_LOCATION_VIEW',
  SETTINGS_EVENT_LOCATION_EDIT: 'SETTINGS_EVENT_LOCATION_EDIT',
  HR_SETTINGS_HIRING_BOARD_VIEW: 'HR_SETTINGS_HIRING_BOARD_VIEW',
  HR_SETTINGS_HIRING_BOARD_EDIT: 'HR_SETTINGS_HIRING_BOARD_EDIT',

  PAGE: 'PAGE',
  PAGE_MY_PROFILE: 'PAGE_MY_PROFILE',
  PAGE_MY_PROFILE_EDIT: 'PAGE_MY_PROFILE_EDIT',
  PAGE_MY_PROFILE_VIEW_ADVANCE: 'PAGE_MY_PROFILE_VIEW_ADVANCE',
  PAGE_MY_PROFILE_EDIT_ADVANCE: 'PAGE_MY_PROFILE_EDIT_ADVANCE',
  PAGE_MY_PROFILE_PERSONAL_EDIT: 'PAGE_MY_PROFILE_PERSONAL_EDIT',
  PAGE_MY_PROFILE_PERSONAL_VIEW_ADVANCE: 'PAGE_MY_PROFILE_PERSONAL_VIEW_ADVANCE',
  PAGE_MY_PROFILE_PERSONAL_EDIT_ADVANCE: 'PAGE_MY_PROFILE_PERSONAL_EDIT_ADVANCE',
  PAGE_MY_PROFILE_PERSONAL_BASIC_INFO_EDIT: 'PAGE_MY_PROFILE_PERSONAL_BASIC_INFO_EDIT',
  PAGE_MY_PROFILE_PERSONAL_BASIC_INFO_VIEW_ADVANCE: 'PAGE_MY_PROFILE_PERSONAL_BASIC_INFO_VIEW_ADVANCE',
  PAGE_MY_PROFILE_PERSONAL_BASIC_INFO_EDIT_ADVANCE: 'PAGE_MY_PROFILE_PERSONAL_BASIC_INFO_EDIT_ADVANCE',
  PAGE_MY_PROFILE_CONTACT_EDIT: 'PAGE_MY_PROFILE_CONTACT_EDIT',
  PAGE_MY_PROFILE_CONTACT_VIEW_ADVANCE: 'PAGE_MY_PROFILE_CONTACT_VIEW_ADVANCE',
  PAGE_MY_PROFILE_CONTACT_EDIT_ADVANCE: 'PAGE_MY_PROFILE_CONTACT_EDIT_ADVANCE',
  PAGE_MY_PROFILE_BANK_EDIT: 'PAGE_MY_PROFILE_BANK_EDIT',
  PAGE_MY_PROFILE_BANK_VIEW_ADVANCE: 'PAGE_MY_PROFILE_BANK_VIEW_ADVANCE',
  PAGE_MY_PROFILE_BANK_EDIT_ADVANCE: 'PAGE_MY_PROFILE_BANK_EDIT_ADVANCE',
  PAGE_MY_PROFILE_PERSONAL: 'PAGE_MY_PROFILE_PERSONAL',
  PAGE_MY_PROFILE_PERSONAL_BASIC_INFO: 'PAGE_MY_PROFILE_PERSONAL_BASIC_INFO',
  PAGE_MY_PROFILE_CONTACT: 'PAGE_MY_PROFILE_CONTACT',
  PAGE_MY_PROFILE_BANK: 'PAGE_MY_PROFILE_BANK',
  PAGE_MY_PROFILE_WORK_INFO: 'PAGE_MY_PROFILE_WORK_INFO',
  PAGE_MY_PROFILE_WORK_INFO_EDIT:'PAGE_MY_PROFILE_WORK_INFO_EDIT',
  PAGE_MY_PROFILE_WORK_INFO_VIEW_ADVANCE: 'PAGE_MY_PROFILE_WORK_INFO_VIEW_ADVANCE',
  PAGE_MY_PROFILE_WORK_INFO_EDIT_ADVANCE: 'PAGE_MY_PROFILE_WORK_INFO_EDIT_ADVANCE',
  PAGE_MY_PROFILE_WORK_INFO_LOCATION_GROUP_TEAM: 'PAGE_MY_PROFILE_WORK_INFO_LOCATION_GROUP_TEAM',
  PAGE_MY_PROFILE_WORK_INFO_LOCATION_GROUP_TEAM_EDIT: 'PAGE_MY_PROFILE_WORK_INFO_LOCATION_GROUP_TEAM_EDIT',
  PAGE_MY_PROFILE_WORK_INFO_LOCATION_GROUP_TEAM_VIEW_ADVANCE: 'PAGE_MY_PROFILE_WORK_INFO_LOCATION_GROUP_TEAM_VIEW_ADVANCE',
  PAGE_MY_PROFILE_WORK_INFO_LOCATION_GROUP_TEAM_EDIT_ADVANCE: 'PAGE_MY_PROFILE_WORK_INFO_LOCATION_GROUP_TEAM_EDIT_ADVANCE',
  PAGE_MY_PROFILE_WORK_INFO_JOB_DATES: 'PAGE_MY_PROFILE_WORK_INFO_JOB_DATES',
  PAGE_MY_PROFILE_WORK_INFO_JOB_DATES_EDIT: 'PAGE_MY_PROFILE_WORK_INFO_JOB_DATES_EDIT',
  PAGE_MY_PROFILE_WORK_INFO_JOB_DATES_VIEW_ADVANCE: 'PAGE_MY_PROFILE_WORK_INFO_JOB_DATES_VIEW_ADVANCE',
  PAGE_MY_PROFILE_WORK_INFO_JOB_DATES_EDIT_ADVANCE: 'PAGE_MY_PROFILE_WORK_INFO_JOB_DATES_EDIT_ADVANCE',
  PAGE_MY_PROFILE_LEAVE_HISTORY: 'PAGE_MY_PROFILE_LEAVE_HISTORY',
  PAGE_MY_PROFILE_LEAVE_HISTORY_EDIT: 'PAGE_MY_PROFILE_LEAVE_HISTORY_EDIT',
  PAGE_MY_PROFILE_LEAVE_HISTORY_VIEW_ADVANCE: 'PAGE_MY_PROFILE_LEAVE_HISTORY_VIEW_ADVANCE',
  PAGE_MY_PROFILE_LEAVE_HISTORY_EDIT_ADVANCE: 'PAGE_MY_PROFILE_LEAVE_HISTORY_EDIT_ADVANCE',
  PAGE_MY_PROFILE_LEAVE_HISTORY_LEAVE_HISTORY: 'PAGE_MY_PROFILE_LEAVE_HISTORY_LEAVE_HISTORY',
  PAGE_MY_PROFILE_LEAVE_HISTORY_LEAVE_HISTORY_EDIT: 'PAGE_MY_PROFILE_LEAVE_HISTORY_LEAVE_HISTORY_EDIT',
  PAGE_MY_PROFILE_LEAVE_HISTORY_LEAVE_HISTORY_VIEW_ADVANCE: 'PAGE_MY_PROFILE_LEAVE_HISTORY_LEAVE_HISTORY_VIEW_ADVANCE',
  PAGE_MY_PROFILE_LEAVE_HISTORY_LEAVE_HISTORY_EDIT_ADVANCE: 'PAGE_MY_PROFILE_LEAVE_HISTORY_LEAVE_HISTORY_EDIT_ADVANCE',
  PAGE_MY_PROFILE_REPORT_HISTORY: 'PAGE_MY_PROFILE_REPORT_HISTORY',
  PAGE_MY_PROFILE_REPORT_HISTORY_EDIT: 'PAGE_MY_PROFILE_REPORT_HISTORY_EDIT',
  PAGE_MY_PROFILE_REPORT_HISTORY_VIEW_ADVANCE: 'PAGE_MY_PROFILE_REPORT_HISTORY_VIEW_ADVANCE',
  PAGE_MY_PROFILE_REPORT_HISTORY_EDIT_ADVANCE: 'PAGE_MY_PROFILE_REPORT_HISTORY_EDIT_ADVANCE',
  PAGE_MY_PROFILE_REPORT_HISTORY_REPORT_HISTORY: 'PAGE_MY_PROFILE_REPORT_HISTORY_REPORT_HISTORY',
  PAGE_MY_PROFILE_REPORT_HISTORY_REPORT_HISTORY_EDIT: 'PAGE_MY_PROFILE_REPORT_HISTORY_REPORT_HISTORY_EDIT',
  PAGE_MY_PROFILE_REPORT_HISTORY_REPORT_HISTORY_VIEW_ADVANCE: 'PAGE_MY_PROFILE_REPORT_HISTORY_REPORT_HISTORY_VIEW_ADVANCE',
  PAGE_MY_PROFILE_REPORT_HISTORY_REPORT_HISTORY_EDIT_ADVANCE: 'PAGE_MY_PROFILE_REPORT_HISTORY_REPORT_HISTORY_EDIT_ADVANCE',
  PAGE_MY_PROFILE_POLICIES: 'PAGE_MY_PROFILE_POLICIES',
  PAGE_MY_PROFILE_POLICIES_EDIT: 'PAGE_MY_PROFILE_POLICIES_EDIT',
  PAGE_MY_PROFILE_POLICIES_VIEW_ADVANCE: 'PAGE_MY_PROFILE_POLICIES_VIEW_ADVANCE',
  PAGE_MY_PROFILE_POLICIES_EDIT_ADVANCE: 'PAGE_MY_PROFILE_POLICIES_EDIT_ADVANCE',
  PAGE_MY_PROFILE_POLICIES_POLICIES: 'PAGE_MY_PROFILE_POLICIES_POLICIES',
  PAGE_MY_PROFILE_POLICIES_POLICIES_EDIT: 'PAGE_MY_PROFILE_POLICIES_POLICIES_EDIT',
  PAGE_MY_PROFILE_POLICIES_POLICIES_VIEW_ADVANCE: 'PAGE_MY_PROFILE_POLICIES_POLICIES_VIEW_ADVANCE',
  PAGE_MY_PROFILE_POLICIES_POLICIES_EDIT_ADVANCE: 'PAGE_MY_PROFILE_POLICIES_POLICIES_EDIT_ADVANCE',
  PAGE_MY_PROFILE_ONBOARD_MISSIONS: 'PAGE_MY_PROFILE_ONBOARD_MISSIONS',
  PAGE_MY_PROFILE_ONBOARD_MISSIONS_EDIT: 'PAGE_MY_PROFILE_ONBOARD_MISSIONS_EDIT',
  PAGE_MY_PROFILE_ONBOARD_MISSIONS_VIEW_ADVANCE: 'PAGE_MY_PROFILE_ONBOARD_MISSIONS_VIEW_ADVANCE',
  PAGE_MY_PROFILE_ONBOARD_MISSIONS_EDIT_ADVANCE: 'PAGE_MY_PROFILE_ONBOARD_MISSIONS_EDIT_ADVANCE',
  PAGE_MY_PROFILE_ONBOARD_MISSIONS_ONBOARD_MISSIONS: 'PAGE_MY_PROFILE_ONBOARD_MISSIONS_ONBOARD_MISSIONS',
  PAGE_MY_PROFILE_ONBOARD_MISSIONS_ONBOARD_MISSIONS_EDIT: 'PAGE_MY_PROFILE_ONBOARD_MISSIONS_ONBOARD_MISSIONS_EDIT',
  PAGE_MY_PROFILE_ONBOARD_MISSIONS_ONBOARD_MISSIONS_VIEW_ADVANCE: 'PAGE_MY_PROFILE_ONBOARD_MISSIONS_ONBOARD_MISSIONS_VIEW_ADVANCE',
  PAGE_MY_PROFILE_ONBOARD_MISSIONS_ONBOARD_MISSIONS_EDIT_ADVANCE: 'PAGE_MY_PROFILE_ONBOARD_MISSIONS_ONBOARD_MISSIONS_EDIT_ADVANCE',
  PAGE_MY_PROFILE_SALARY_SETTING: 'PAGE_MY_PROFILE_SALARY_SETTING',
  PAGE_MY_PROFILE_SALARY_SETTING_EDIT: 'PAGE_MY_PROFILE_SALARY_SETTING_EDIT',
  PAGE_MY_PROFILE_SALARY_SETTING_VIEW_ADVANCE: 'PAGE_MY_PROFILE_SALARY_SETTING_VIEW_ADVANCE',
  PAGE_MY_PROFILE_SALARY_SETTING_EDIT_ADVANCE: 'PAGE_MY_PROFILE_SALARY_SETTING_EDIT_ADVANCE',
  PAGE_MY_PROFILE_SALARY_SETTING_SALARY_SETTING: 'PAGE_MY_PROFILE_SALARY_SETTING_SALARY_SETTING',
  PAGE_MY_PROFILE_SALARY_SETTING_SALARY_SETTING_EDIT: 'PAGE_MY_PROFILE_SALARY_SETTING_SALARY_SETTING_EDIT',
  PAGE_MY_PROFILE_SALARY_SETTING_SALARY_SETTING_VIEW_ADVANCE: 'PAGE_MY_PROFILE_SALARY_SETTING_SALARY_SETTING_VIEW_ADVANCE',
  PAGE_MY_PROFILE_SALARY_SETTING_SALARY_SETTING_EDIT_ADVANCE: 'PAGE_MY_PROFILE_SALARY_SETTING_SALARY_SETTING_EDIT_ADVANCE',
  PAGE_MY_PROFILE_NOTIFICATION: 'PAGE_MY_PROFILE_NOTIFICATION',
  PAGE_MY_PROFILE_NOTIFICATION_EDIT: 'PAGE_MY_PROFILE_NOTIFICATION_EDIT',
  PAGE_MY_PROFILE_NOTIFICATION_VIEW_ADVANCE: 'PAGE_MY_PROFILE_NOTIFICATION_VIEW_ADVANCE',
  PAGE_MY_PROFILE_NOTIFICATION_EDIT_ADVANCE: 'PAGE_MY_PROFILE_NOTIFICATION_EDIT_ADVANCE',
  PAGE_MY_PROFILE_NOTIFICATION_NOTIFICATION: 'PAGE_MY_PROFILE_NOTIFICATION_NOTIFICATION',
  PAGE_MY_PROFILE_NOTIFICATION_NOTIFICATION_EDIT: 'PAGE_MY_PROFILE_NOTIFICATION_NOTIFICATION_EDIT',
  PAGE_MY_PROFILE_NOTIFICATION_NOTIFICATION_VIEW_ADVANCE: 'PAGE_MY_PROFILE_NOTIFICATION_NOTIFICATION_VIEW_ADVANCE',
  PAGE_MY_PROFILE_NOTIFICATION_NOTIFICATION_EDIT_ADVANCE: 'PAGE_MY_PROFILE_NOTIFICATION_NOTIFICATION_EDIT_ADVANCE',
  DOCUMENT_PROJECTS_EDIT_OTHER: 'DOCUMENT_PROJECTS_EDIT_OTHER',
  DOCUMENT_PROJECTS_VIEW_OTHER: 'DOCUMENT_PROJECTS_VIEW_OTHER',
  DOCUMENT_COMPANY_VIEW_OTHER: 'DOCUMENT_COMPANY_VIEW_OTHER',
  DOCUMENT_COMPANY_EDIT_OTHER: 'DOCUMENT_COMPANY_EDIT_OTHER',
  DOCUMENT_SYSTEM_FIELD_VIEW_OTHER: 'DOCUMENT_SYSTEM_FIELD_VIEW_OTHER',
  DOCUMENT_SYSTEM_FIELD_EDIT_OTHER: 'DOCUMENT_SYSTEM_FIELD_EDIT_OTHER',
  DOCUMENT_RECENT_VIEW_OTHER: 'DOCUMENT_RECENT_VIEW_OTHER',
  DOCUMENT_RECENT_EDIT_OTHER: 'DOCUMENT_RECENT_EDIT_OTHER',
  DOCUMENT_RECYCLE_BIN_VIEW_OTHER: 'DOCUMENT_RECYCLE_BIN_VIEW_OTHER',
  DOCUMENT_RECYCLE_BIN_EDIT_OTHER: 'DOCUMENT_RECYCLE_BIN_EDIT_OTHER',
  DOCUMENT_FILE_PROVIDER_VIEW_OTHER: 'DOCUMENT_FILE_PROVIDER_VIEW_OTHER',
  DOCUMENT_FILE_PROVIDER_EDIT_OTHER: 'DOCUMENT_FILE_PROVIDER_EDIT_OTHER',
  DOCUMENT_MANAGE_STORAGE_VIEW_OTHER: 'DOCUMENT_MANAGE_STORAGE_VIEW_OTHER',
  DOCUMENT_MANAGE_STORAGE_EDIT_OTHER: 'DOCUMENT_MANAGE_STORAGE_EDIT_OTHER',
  WORK_TRACKING_DASHBOARD_VIEW_OTHER: 'WORK_TRACKING_DASHBOARD_VIEW_OTHER',
  WORK_TRACKING_DASHBOARD_EDIT_OTHER: 'WORK_TRACKING_DASHBOARD_EDIT_OTHER',
  WORK_TRACKING_MY_TRACKING_VIEW_OTHER: 'WORK_TRACKING_MY_TRACKING_VIEW_OTHER',
  WORK_TRACKING_MY_TRACKING_EDIT_OTHER: 'WORK_TRACKING_MY_TRACKING_EDIT_OTHER',
  WORK_TRACKING_SETTING_CHOOSE_LEAVE_TYPE_VIEW_OTHER: 'WORK_TRACKING_SETTING_CHOOSE_LEAVE_TYPE_VIEW_OTHER',
  WORK_TRACKING_SETTING_CHOOSE_LEAVE_TYPE_EDIT_OTHER: 'WORK_TRACKING_SETTING_CHOOSE_LEAVE_TYPE_EDIT_OTHER',
  WORK_TRACKING_SETTING_SHIFT_PROFILE_SETTING_VIEW_OTHER: 'WORK_TRACKING_SETTING_SHIFT_PROFILE_SETTING_VIEW_OTHER',
  WORK_TRACKING_SETTING_SHIFT_PROFILE_SETTING_EDIT_OTHER: 'WORK_TRACKING_SETTING_SHIFT_PROFILE_SETTING_EDIT_OTHER',
  TIMESHEET_MY_LOGWORK_VIEW_OTHER: 'TIMESHEET_MY_LOGWORK_VIEW_OTHER',
  TIMESHEET_MY_LOGWORK_EDIT_OTHER: 'TIMESHEET_MY_LOGWORK_EDIT_OTHER',
  TIMESHEET_REPORTS_VIEW_OTHER: 'TIMESHEET_REPORTS_VIEW_OTHER',
  TIMESHEET_REPORTS_EDIT_OTHER: 'TIMESHEET_REPORTS_EDIT_OTHER',
  TASK_MANAGEMENT_STORYLINE_VIEW_OTHER: 'TASK_MANAGEMENT_STORYLINE_VIEW_OTHER',
  TASK_MANAGEMENT_STORYLINE_EDIT_OTHER: 'TASK_MANAGEMENT_STORYLINE_EDIT_OTHER',
  TASK_MANAGEMENT_GIANTT_VIEW_OTHER: 'TASK_MANAGEMENT_GIANTT_VIEW_OTHER',
  TASK_MANAGEMENT_GIANTT_EDIT_OTHER: 'TASK_MANAGEMENT_GIANTT_EDIT_OTHER',
  TASK_MANAGEMENT_PROJECTS_VIEW_OTHER: 'TASK_MANAGEMENT_PROJECTS_VIEW_OTHER',
  TASK_MANAGEMENT_PROJECTS_EDIT_OTHER: 'TASK_MANAGEMENT_PROJECTS_EDIT_OTHER',
  TASK_MANAGEMENT_ACTIVE_SPRINT_KANBAN_BOARD_VIEW_OTHER: 'TASK_MANAGEMENT_ACTIVE_SPRINT_KANBAN_BOARD_VIEW_OTHER',
  TASK_MANAGEMENT_ACTIVE_SPRINT_KANBAN_BOARD_EDIT_OTHER: 'TASK_MANAGEMENT_ACTIVE_SPRINT_KANBAN_BOARD_EDIT_OTHER',
  TASK_MANAGEMENT_RELEASE_RELEASE_VIEW_OTHER: 'TASK_MANAGEMENT_RELEASE_RELEASE_VIEW_OTHER',
  TASK_MANAGEMENT_RELEASE_RELEASE_EDIT_OTHER: 'TASK_MANAGEMENT_RELEASE_RELEASE_EDIT_OTHER',
  TASK_MANAGEMENT_RELEASE_RELEASE_DETAIL_VIEW_OTHER: 'TASK_MANAGEMENT_RELEASE_RELEASE_DETAIL_VIEW_OTHER',
  TASK_MANAGEMENT_RELEASE_RELEASE_DETAIL_EDIT_OTHER: 'TASK_MANAGEMENT_RELEASE_RELEASE_DETAIL_EDIT_OTHER',
  TASK_MANAGEMENT_RELEASE_RELEASE_NOTE_VIEW_OTHER: 'TASK_MANAGEMENT_RELEASE_RELEASE_NOTE_VIEW_OTHER',
  TASK_MANAGEMENT_RELEASE_RELEASE_NOTE_EDIT_OTHER: 'TASK_MANAGEMENT_RELEASE_RELEASE_NOTE_EDIT_OTHER',
  TASK_MANAGEMENT_BACKLOG_VIEW_OTHER: 'TASK_MANAGEMENT_BACKLOG_VIEW_OTHER',
  TASK_MANAGEMENT_BACKLOG_EDIT_OTHER: 'TASK_MANAGEMENT_BACKLOG_EDIT_OTHER',
  TASK_MANAGEMENT_EPIC_EPIC_VIEW_OTHER: 'TASK_MANAGEMENT_EPIC_EPIC_VIEW_OTHER',
  TASK_MANAGEMENT_EPIC_EPIC_EDIT_OTHER: 'TASK_MANAGEMENT_EPIC_EPIC_EDIT_OTHER',
  TASK_MANAGEMENT_EPIC_DETAIL_VIEW_OTHER: 'TASK_MANAGEMENT_EPIC_DETAIL_VIEW_OTHER',
  TASK_MANAGEMENT_EPIC_DETAIL_EDIT_OTHER: 'TASK_MANAGEMENT_EPIC_DETAIL_EDIT_OTHER',
  TASK_MANAGEMENT_PROJECT_SETTING_GENERAL_VIEW_OTHER: 'TASK_MANAGEMENT_PROJECT_SETTING_GENERAL_VIEW_OTHER',
  TASK_MANAGEMENT_PROJECT_SETTING_GENERAL_EDIT_OTHER: 'TASK_MANAGEMENT_PROJECT_SETTING_GENERAL_EDIT_OTHER',
  TASK_MANAGEMENT_PROJECT_SETTING_PROJECT_MEMBERS_VIEW_OTHER: 'TASK_MANAGEMENT_PROJECT_SETTING_PROJECT_MEMBERS_VIEW_OTHER',
  TASK_MANAGEMENT_PROJECT_SETTING_PROJECT_MEMBERS_EDIT_OTHER: 'TASK_MANAGEMENT_PROJECT_SETTING_PROJECT_MEMBERS_EDIT_OTHER',
  TASK_MANAGEMENT_PROJECT_SETTING_BOARD_SETTINGS_VIEW_OTHER: 'TASK_MANAGEMENT_PROJECT_SETTING_BOARD_SETTINGS_VIEW_OTHER',
  TASK_MANAGEMENT_PROJECT_SETTING_BOARD_SETTINGS_EDIT_OTHER: 'TASK_MANAGEMENT_PROJECT_SETTING_BOARD_SETTINGS_EDIT_OTHER',
  TASK_MANAGEMENT_PROJECT_SETTING_TASK_TYPE_VIEW_OTHER: 'TASK_MANAGEMENT_PROJECT_SETTING_TASK_TYPE_VIEW_OTHER',
  TASK_MANAGEMENT_PROJECT_SETTING_TASK_TYPE_EDIT_OTHER: 'TASK_MANAGEMENT_PROJECT_SETTING_TASK_TYPE_EDIT_OTHER',
  TASK_MANAGEMENT_PROJECT_SETTING_PRIORITY_VIEW_OTHER: 'TASK_MANAGEMENT_PROJECT_SETTING_PRIORITY_VIEW_OTHER',
  TASK_MANAGEMENT_PROJECT_SETTING_PRIORITY_EDIT_OTHER: 'TASK_MANAGEMENT_PROJECT_SETTING_PRIORITY_EDIT_OTHER',
  TASK_MANAGEMENT_PROJECT_SETTING_STORYLINE_VIEW_OTHER: 'TASK_MANAGEMENT_PROJECT_SETTING_STORYLINE_VIEW_OTHER',
  TASK_MANAGEMENT_PROJECT_SETTING_STORYLINE_EDIT_OTHER: 'TASK_MANAGEMENT_PROJECT_SETTING_STORYLINE_EDIT_OTHER',
  TASK_MANAGEMENT_PROJECT_SETTING_LABEL_VIEW_OTHER: 'TASK_MANAGEMENT_PROJECT_SETTING_LABEL_VIEW_OTHER',
  TASK_MANAGEMENT_PROJECT_SETTING_LABEL_EDIT_OTHER: 'TASK_MANAGEMENT_PROJECT_SETTING_LABEL_EDIT_OTHER',
  TASK_MANAGEMENT_PROJECT_SETTING_HISTORY_VIEW_OTHER: 'TASK_MANAGEMENT_PROJECT_SETTING_HISTORY_VIEW_OTHER',
  TASK_MANAGEMENT_PROJECT_SETTING_HISTORY_EDIT_OTHER: 'TASK_MANAGEMENT_PROJECT_SETTING_HISTORY_EDIT_OTHER',
  TASK_MANAGEMENT_TASK_VIEW_OTHER: 'TASK_MANAGEMENT_TASK_VIEW_OTHER',
  TASK_MANAGEMENT_TASK_EDIT_OTHER: 'TASK_MANAGEMENT_TASK_EDIT_OTHER',
  // Task detail
  TASK_MANAGEMENT_TASK_DETAIL: 'TASK_MANAGEMENT_TASK_DETAIL',
  TASK_MANAGEMENT_TASK_DETAIL_EDIT_AFTER_DUE_DATE: 'TASK_MANAGEMENT_TASK_DETAIL_EDIT_AFTER_DUE_DATE',
  TASK_MANAGEMENT_TASK_DETAIL_VIEW_OTHER: 'TASK_MANAGEMENT_TASK_DETAIL_VIEW_OTHER',
  TASK_MANAGEMENT_TASK_DETAIL_EDIT_OTHER: 'TASK_MANAGEMENT_TASK_DETAIL_EDIT_OTHER',
  TASK_MANAGEMENT_TASK_DETAIL_VIEW_ADVANCE: 'TASK_MANAGEMENT_TASK_DETAIL_VIEW_ADVANCE',
  TASK_MANAGEMENT_TASK_DETAIL_EDIT_ADVANCE: 'TASK_MANAGEMENT_TASK_DETAIL_EDIT_ADVANCE',
  TASK_MANAGEMENT_TASK_DETAIL_EDIT_ASSIGNEE: 'TASK_MANAGEMENT_TASK_DETAIL_EDIT_ASSIGNEE',
  TASK_MANAGEMENT_TASK_DETAIL_EDIT_CC: 'TASK_MANAGEMENT_TASK_DETAIL_EDIT_CC',
  TASK_MANAGEMENT_TASK_DETAIL_EDIT_REPORTER: 'TASK_MANAGEMENT_TASK_DETAIL_EDIT_REPORTER',
  TASK_MANAGEMENT_TASK_DETAIL_COMMENT: 'TASK_MANAGEMENT_TASK_DETAIL_COMMENT',
  TASK_MANAGEMENT_TASK_DETAIL_COMMENT_VIEW_OTHER: 'TASK_MANAGEMENT_TASK_DETAIL_COMMENT_VIEW_OTHER',
  TASK_MANAGEMENT_TASK_DETAIL_COMMENT_EDIT_OTHER: 'TASK_MANAGEMENT_TASK_DETAIL_COMMENT_EDIT_OTHER',
  TASK_MANAGEMENT_TASK_DETAIL_COMMENT_EDIT: 'TASK_MANAGEMENT_TASK_DETAIL_COMMENT_EDIT',
  TASK_MANAGEMENT_TASK_DETAIL_COMMENT_DELETE: 'TASK_MANAGEMENT_TASK_DETAIL_COMMENT_DELETE',
  TASK_MANAGEMENT_TASK_DETAIL_COMMENT_CREATE: 'TASK_MANAGEMENT_TASK_DETAIL_COMMENT_CREATE',
  TASK_MANAGEMENT_TASK_DETAIL_EDIT: 'TASK_MANAGEMENT_TASK_DETAIL_EDIT',
  TASK_MANAGEMENT_TASK_DETAIL_EDIT_PRIVATE_FLAG: 'TASK_MANAGEMENT_TASK_DETAIL_EDIT_PRIVATE_FLAG',
  TASK_MANAGEMENT_TASK_DETAIL_DELETE: 'TASK_MANAGEMENT_TASK_DETAIL_DELETE',
  TASK_MANAGEMENT_TASK_DETAIL_CREATE: 'TASK_MANAGEMENT_TASK_DETAIL_CREATE',
  ORGANIZATION_USER_DETAIL_WORK_INFO_LOCATION_GROUP_TEAM_VIEW_ADVANCE: 'ORGANIZATION_USER_DETAIL_WORK_INFO_LOCATION_GROUP_TEAM_VIEW_ADVANCE',
  ORGANIZATION_USER_DETAIL_WORK_INFO_LOCATION_GROUP_TEAM_EDIT_ADVANCE: 'ORGANIZATION_USER_DETAIL_WORK_INFO_LOCATION_GROUP_TEAM_EDIT_ADVANCE',
  ORGANIZATION_USER_DETAIL_WORK_INFO_JOB_DATES_VIEW_ADVANCE: 'ORGANIZATION_USER_DETAIL_WORK_INFO_JOB_DATES_VIEW_ADVANCE',
  ORGANIZATION_USER_DETAIL_WORK_INFO_JOB_DATES_EDIT_ADVANCE: 'ORGANIZATION_USER_DETAIL_WORK_INFO_JOB_DATES_EDIT_ADVANCE',
  ORGANIZATION_USER_DETAIL_LEAVE_HISTORY_LEAVE_HISTORY_VIEW_ADVANCE: 'ORGANIZATION_USER_DETAIL_LEAVE_HISTORY_LEAVE_HISTORY_VIEW_ADVANCE',
  ORGANIZATION_USER_DETAIL_LEAVE_HISTORY_LEAVE_HISTORY_EDIT_ADVANCE: 'ORGANIZATION_USER_DETAIL_LEAVE_HISTORY_LEAVE_HISTORY_EDIT_ADVANCE',
  ORGANIZATION_USER_DETAIL_REPORT_HISTORY_REPORT_HISTORY_VIEW_ADVANCE: 'ORGANIZATION_USER_DETAIL_REPORT_HISTORY_REPORT_HISTORY_VIEW_ADVANCE',
  ORGANIZATION_USER_DETAIL_REPORT_HISTORY_REPORT_HISTORY_EDIT_ADVANCE: 'ORGANIZATION_USER_DETAIL_REPORT_HISTORY_REPORT_HISTORY_EDIT_ADVANCE',
  ORGANIZATION_USER_DETAIL_CONTACT_EDIT_ADVANCE: 'ORGANIZATION_USER_DETAIL_CONTACT_EDIT_ADVANCE',
  ORGANIZATION_USER_DETAIL_CONTACT_VIEW_ADVANCE: 'ORGANIZATION_USER_DETAIL_CONTACT_VIEW_ADVANCE',
  ORGANIZATION_USER_DETAIL_BANK_VIEW_ADVANCE: 'ORGANIZATION_USER_DETAIL_BANK_VIEW_ADVANCE',
  ORGANIZATION_USER_DETAIL_BANK_EDIT_ADVANCE: 'ORGANIZATION_USER_DETAIL_BANK_EDIT_ADVANCE',
  ORGANIZATION_USER_DETAIL_PERSONAL_BASIC_INFO_VIEW_ADVANCE: 'ORGANIZATION_USER_DETAIL_PERSONAL_BASIC_INFO_VIEW_ADVANCE',
  ORGANIZATION_USER_DETAIL_PERSONAL_BASIC_INFO_EDIT_ADVANCE: 'ORGANIZATION_USER_DETAIL_PERSONAL_BASIC_INFO_EDIT_ADVANCE',
  ORGANIZATION_USER_DETAIL_POLICIES_POLICIES_VIEW_ADVANCE: 'ORGANIZATION_USER_DETAIL_POLICIES_POLICIES_VIEW_ADVANCE',
  ORGANIZATION_USER_DETAIL_POLICIES_POLICIES_EDIT_ADVANCE: 'ORGANIZATION_USER_DETAIL_POLICIES_POLICIES_EDIT_ADVANCE',
  ORGANIZATION_USER_DETAIL_ONBOARD_MISSIONS_ONBOARD_MISSIONS_VIEW_ADVANCE: 'ORGANIZATION_USER_DETAIL_ONBOARD_MISSIONS_ONBOARD_MISSIONS_VIEW_ADVANCE',
  ORGANIZATION_USER_DETAIL_ONBOARD_MISSIONS_ONBOARD_MISSIONS_EDIT_ADVANCE: 'ORGANIZATION_USER_DETAIL_ONBOARD_MISSIONS_ONBOARD_MISSIONS_EDIT_ADVANCE',
  ORGANIZATION_USER_DETAIL_SALARY_SETTING_SALARY_SETTING_VIEW_ADVANCE: 'ORGANIZATION_USER_DETAIL_SALARY_SETTING_SALARY_SETTING_VIEW_ADVANCE',
  ORGANIZATION_USER_DETAIL_SALARY_SETTING_SALARY_SETTING_EDIT_ADVANCE: 'ORGANIZATION_USER_DETAIL_SALARY_SETTING_SALARY_SETTING_EDIT_ADVANCE',
  ORGANIZATION_USER_DETAIL_NOTIFICATION_NOTIFICATION_VIEW_ADVANCE: 'ORGANIZATION_USER_DETAIL_NOTIFICATION_NOTIFICATION_VIEW_ADVANCE',
  ORGANIZATION_USER_DETAIL_NOTIFICATION_NOTIFICATION_EDIT_ADVANCE: 'ORGANIZATION_USER_DETAIL_NOTIFICATION_NOTIFICATION_EDIT_ADVANCE',
  ORGANIZATION_LOCATION_VIEW_OTHER: 'ORGANIZATION_LOCATION_VIEW_OTHER',
  ORGANIZATION_LOCATION_EDIT_OTHER: 'ORGANIZATION_LOCATION_EDIT_OTHER',
  ORGANIZATION_TEAM_VIEW_OTHER: 'ORGANIZATION_TEAM_VIEW_OTHER',
  ORGANIZATION_TEAM_EDIT_OTHER: 'ORGANIZATION_TEAM_EDIT_OTHER',
  ORGANIZATION_GROUP_VIEW_OTHER: 'ORGANIZATION_GROUP_VIEW_OTHER',
  ORGANIZATION_GROUP_EDIT_OTHER: 'ORGANIZATION_GROUP_EDIT_OTHER',
  ORGANIZATION_USER_VIEW_OTHER: 'ORGANIZATION_USER_VIEW_OTHER',
  ORGANIZATION_USER_EDIT_OTHER: 'ORGANIZATION_USER_EDIT_OTHER',
  ORGANIZATION_SHIFT_SHIFT_PROFILE_VIEW_OTHER: 'ORGANIZATION_SHIFT_SHIFT_PROFILE_VIEW_OTHER',
  ORGANIZATION_SHIFT_SHIFT_PROFILE_EDIT_OTHER: 'ORGANIZATION_SHIFT_SHIFT_PROFILE_EDIT_OTHER',
  ORGANIZATION_SHIFT_SHIFT_VIEW_OTHER: 'ORGANIZATION_SHIFT_SHIFT_VIEW_OTHER',
  ORGANIZATION_SHIFT_SHIFT_EDIT_OTHER: 'ORGANIZATION_SHIFT_SHIFT_EDIT_OTHER',
  ORGANIZATION_ROLE_PERMISSION_VIEW_OTHER: 'ORGANIZATION_ROLE_PERMISSION_VIEW_OTHER',
  ORGANIZATION_ROLE_PERMISSION_EDIT_OTHER: 'ORGANIZATION_ROLE_PERMISSION_EDIT_OTHER',
  ORGANIZATION_HOLIDAY_VIEW_OTHER: 'ORGANIZATION_HOLIDAY_VIEW_OTHER',
  ORGANIZATION_HOLIDAY_EDIT_OTHER: 'ORGANIZATION_HOLIDAY_EDIT_OTHER',
  ORGANIZATION_INTEGRATION_SLACK_VIEW_OTHER: 'ORGANIZATION_INTEGRATION_SLACK_VIEW_OTHER',
  ORGANIZATION_INTEGRATION_SLACK_EDIT_OTHER: 'ORGANIZATION_INTEGRATION_SLACK_EDIT_OTHER',
  ORGANIZATION_INTEGRATION_JIRA_VIEW_OTHER: 'ORGANIZATION_INTEGRATION_JIRA_VIEW_OTHER',
  ORGANIZATION_INTEGRATION_JIRA_EDIT_OTHER: 'ORGANIZATION_INTEGRATION_JIRA_EDIT_OTHER',
  ORGANIZATION_BILLING_CURRENT_PLAN_EDIT_OTHER: 'ORGANIZATION_BILLING_CURRENT_PLAN_EDIT_OTHER',
  ORGANIZATION_BILLING_CURRENT_PLAN_VIEW_OTHER: 'ORGANIZATION_BILLING_CURRENT_PLAN_VIEW_OTHER',
  ORGANIZATION_BILLING_NEXT_CHARGE_ESTIMATE_EDIT_OTHER: 'ORGANIZATION_BILLING_NEXT_CHARGE_ESTIMATE_EDIT_OTHER',
  ORGANIZATION_BILLING_NEXT_CHARGE_ESTIMATE_VIEW_OTHER: 'ORGANIZATION_BILLING_NEXT_CHARGE_ESTIMATE_VIEW_OTHER',
  ORGANIZATION_BILLING_INFORMATION_VIEW_OTHER: 'ORGANIZATION_BILLING_INFORMATION_VIEW_OTHER',
  ORGANIZATION_BILLING_INFORMATION_EDIT_OTHER: 'ORGANIZATION_BILLING_INFORMATION_EDIT_OTHER',
  ORGANIZATION_BILLING_BILLING_VIEW_OTHER: 'ORGANIZATION_BILLING_BILLING_VIEW_OTHER',
  ORGANIZATION_BILLING_BILLING_EDIT_OTHER: 'ORGANIZATION_BILLING_BILLING_EDIT_OTHER',
  ORGANIZATION_BILLING_HISTORY_VIEW_OTHER: 'ORGANIZATION_BILLING_HISTORY_VIEW_OTHER',
  ORGANIZATION_BILLING_HISTORY_EDIT_OTHER: 'ORGANIZATION_BILLING_HISTORY_EDIT_OTHER',
  ORGANIZATION_BILLING_PAYMENT_METHOD_VIEW_OTHER: 'ORGANIZATION_BILLING_PAYMENT_METHOD_VIEW_OTHER',
  ORGANIZATION_BILLING_PAYMENT_METHOD_EDIT_OTHER: 'ORGANIZATION_BILLING_PAYMENT_METHOD_EDIT_OTHER',
  ORGANIZATION_ORGANIZATION_TREE_ORGANIZATION_TREE_VIEW_OTHER: 'ORGANIZATION_ORGANIZATION_TREE_ORGANIZATION_TREE_VIEW_OTHER',
  ORGANIZATION_ORGANIZATION_TREE_ORGANIZATION_TREE_EDIT_OTHER: 'ORGANIZATION_ORGANIZATION_TREE_ORGANIZATION_TREE_EDIT_OTHER',
  ORGANIZATION_ORGANIZATION_TREE_COMPANY_STRUCTURE_VIEW_OTHER: 'ORGANIZATION_ORGANIZATION_TREE_COMPANY_STRUCTURE_VIEW_OTHER',
  ORGANIZATION_ORGANIZATION_TREE_COMPANY_STRUCTURE_EDIT_OTHER: 'ORGANIZATION_ORGANIZATION_TREE_COMPANY_STRUCTURE_EDIT_OTHER',
  ORGANIZATION_ORGANIZATION_TREE_JOB_TITLE_VIEW_OTHER: 'ORGANIZATION_ORGANIZATION_TREE_JOB_TITLE_VIEW_OTHER',
  ORGANIZATION_ORGANIZATION_TREE_JOB_TITLE_EDIT_OTHER: 'ORGANIZATION_ORGANIZATION_TREE_JOB_TITLE_EDIT_OTHER',
  AUDIT_LOG_VIEW_OTHER: 'AUDIT_LOG_VIEW_OTHER',
  AUDIT_LOG_EDIT_OTHER: 'AUDIT_LOG_EDIT_OTHER',
  SETTINGS_DYNAMIC_FIELD_VIEW_OTHER: 'SETTINGS_DYNAMIC_FIELD_VIEW_OTHER',
  SETTINGS_DYNAMIC_FIELD_EDIT_OTHER: 'SETTINGS_DYNAMIC_FIELD_EDIT_OTHER',
  SETTINGS_LANGUAGE_VIEW_OTHER: 'SETTINGS_LANGUAGE_VIEW_OTHER',
  SETTINGS_LANGUAGE_EDIT_OTHER: 'SETTINGS_LANGUAGE_EDIT_OTHER',
  SETTINGS_NOTIFICATIONS_VIEW_OTHER: 'SETTINGS_NOTIFICATIONS_VIEW_OTHER',
  SETTINGS_NOTIFICATIONS_EDIT_OTHER: 'SETTINGS_NOTIFICATIONS_EDIT_OTHER',
  SETTINGS_DOCUMENT_TEMPLATE_VIEW_OTHER: 'SETTINGS_DOCUMENT_TEMPLATE_VIEW_OTHER',
  SETTINGS_DOCUMENT_TEMPLATE_EDIT_OTHER: 'SETTINGS_DOCUMENT_TEMPLATE_EDIT_OTHER',
  USER_DASHBOARD_UNSET_DUE_DATE_TASKS_VIEW_OTHER: 'USER_DASHBOARD_UNSET_DUE_DATE_TASKS_VIEW_OTHER',
  USER_DASHBOARD_UNSET_DUE_DATE_TASKS_EDIT_OTHER: 'USER_DASHBOARD_UNSET_DUE_DATE_TASKS_EDIT_OTHER',
  USER_DASHBOARD_BOOKMARKED_TASKS_VIEW_OTHER: 'USER_DASHBOARD_BOOKMARKED_TASKS_VIEW_OTHER',
  USER_DASHBOARD_BOOKMARKED_TASKS_EDIT_OTHER: 'USER_DASHBOARD_BOOKMARKED_TASKS_EDIT_OTHER',
  USER_DASHBOARD_YOUR_TASKS_VIEW_OTHER: 'USER_DASHBOARD_YOUR_TASKS_VIEW_OTHER',
  USER_DASHBOARD_YOUR_TASKS_EDIT_OTHER: 'USER_DASHBOARD_YOUR_TASKS_EDIT_OTHER',
  USER_DASHBOARD_OVERDUE_TASKS_VIEW_OTHER: 'USER_DASHBOARD_OVERDUE_TASKS_VIEW_OTHER',
  USER_DASHBOARD_OVERDUE_TASKS_EDIT_OTHER: 'USER_DASHBOARD_OVERDUE_TASKS_EDIT_OTHER',
  LEAVE_DASHBOARD_WHO_IS_WFH_TODAY_VIEW_OTHER: 'LEAVE_DASHBOARD_WHO_IS_WFH_TODAY_VIEW_OTHER',
  LEAVE_DASHBOARD_WHO_IS_WFH_TODAY_EDIT_OTHER: 'LEAVE_DASHBOARD_WHO_IS_WFH_TODAY_EDIT_OTHER',
  LEAVE_DASHBOARD_LEAVE_DASHBOARD_VIEW_OTHER: 'LEAVE_DASHBOARD_LEAVE_DASHBOARD_VIEW_OTHER',
  LEAVE_DASHBOARD_LEAVE_DASHBOARD_EDIT_OTHER: 'LEAVE_DASHBOARD_LEAVE_DASHBOARD_EDIT_OTHER',
  LEAVE_DASHBOARD_WHO_IS_OFF_TODAY_VIEW_OTHER: 'LEAVE_DASHBOARD_WHO_IS_OFF_TODAY_VIEW_OTHER',
  LEAVE_DASHBOARD_WHO_IS_OFF_TODAY_EDIT_OTHER: 'LEAVE_DASHBOARD_WHO_IS_OFF_TODAY_EDIT_OTHER',
  LEAVE_DASHBOARD_BALANCE_DETAIL_VIEW_OTHER: 'LEAVE_DASHBOARD_BALANCE_DETAIL_VIEW_OTHER',
  LEAVE_DASHBOARD_BALANCE_DETAIL_EDIT_OTHER: 'LEAVE_DASHBOARD_BALANCE_DETAIL_EDIT_OTHER',
  LEAVE_MY_REQUEST_VIEW_OTHER: 'LEAVE_MY_REQUEST_VIEW_OTHER',
  LEAVE_MY_REQUEST_EDIT_OTHER: 'LEAVE_MY_REQUEST_EDIT_OTHER',
  LEAVE_APPROVAL_VIEW_OTHER: 'LEAVE_APPROVAL_VIEW_OTHER',
  LEAVE_APPROVAL_EDIT_OTHER: 'LEAVE_APPROVAL_EDIT_OTHER',
  LEAVE_BALANCE_VIEW_OTHER: 'LEAVE_BALANCE_VIEW_OTHER',
  LEAVE_BALANCE_EDIT_OTHER: 'LEAVE_BALANCE_EDIT_OTHER',
  LEAVE_REPORT_VIEW_OTHER: 'LEAVE_REPORT_VIEW_OTHER',
  LEAVE_REPORT_EDIT_OTHER: 'LEAVE_REPORT_EDIT_OTHER',
  LEAVE_SETTING_CATEGORY_VIEW_OTHER: 'LEAVE_SETTING_CATEGORY_VIEW_OTHER',
  LEAVE_SETTING_CATEGORY_EDIT_OTHER: 'LEAVE_SETTING_CATEGORY_EDIT_OTHER',
  LEAVE_SETTING_CARRY_OVER_VIEW_OTHER: 'LEAVE_SETTING_CARRY_OVER_VIEW_OTHER',
  LEAVE_SETTING_CARRY_OVER_EDIT_OTHER: 'LEAVE_SETTING_CARRY_OVER_EDIT_OTHER',
  LEAVE_SETTING_TYPE_VIEW_OTHER: 'LEAVE_SETTING_TYPE_VIEW_OTHER',
  LEAVE_SETTING_TYPE_EDIT_OTHER: 'LEAVE_SETTING_TYPE_EDIT_OTHER',
  OKR_OKR_SETTINGS_SPACES_MEMBER: 'OKR_OKR_SETTINGS_SPACES_MEMBER',
  OKR_OKR_SETTINGS_SPACES_MEMBER_VIEW: 'OKR_OKR_SETTINGS_SPACES_MEMBER_VIEW',
  OKR_OKR_SETTINGS_SPACES_MEMBER_EDIT: 'OKR_OKR_SETTINGS_SPACES_MEMBER_EDIT',
  OKR_COMPANY: 'OKR_COMPANY',
  OKR_COMPANY_VIEW: 'OKR_COMPANY_VIEW',
  OKR_COMPANY_EDIT: 'OKR_COMPANY_EDIT',
  OKR_GROUP: 'OKR_GROUP',
  OKR_GROUP_VIEW: 'OKR_GROUP_VIEW',
  OKR_GROUP_EDIT: 'OKR_GROUP_EDIT',
  OKR_GROUP_VIEW_OTHER: 'OKR_GROUP_VIEW_OTHER',
  OKR_GROUP_EDIT_OTHER: 'OKR_GROUP_EDIT_OTHER',
  OKR_TEAM: 'OKR_TEAM',
  OKR_TEAM_VIEW: 'OKR_TEAM_VIEW',
  OKR_TEAM_EDIT: 'OKR_TEAM_EDIT',
  OKR_TEAM_VIEW_OTHER: 'OKR_TEAM_VIEW_OTHER',
  OKR_TEAM_EDIT_OTHER: 'OKR_TEAM_EDIT_OTHER',
  OKR_PERSONAL: 'OKR_PERSONAL',
  OKR_PERSONAL_VIEW: 'OKR_PERSONAL_VIEW',
  OKR_PERSONAL_EDIT: 'OKR_PERSONAL_EDIT',
  HR_PAYROLL_VIEW_OTHER: 'HR_PAYROLL_VIEW_OTHER',
  HR_PAYROLL_EDIT_OTHER: 'HR_PAYROLL_EDIT_OTHER',
  HR_HIRING_BOARD_HIRING_BOARD_VIEW_OTHER: 'HR_HIRING_BOARD_HIRING_BOARD_VIEW_OTHER',
  HR_HIRING_BOARD_HIRING_BOARD_EDIT_OTHER: 'HR_HIRING_BOARD_HIRING_BOARD_EDIT_OTHER',
  HR_HIRING_BOARD_CV_DETAIL_VIEW_OTHER: 'HR_HIRING_BOARD_CV_DETAIL_VIEW_OTHER',
  HR_HIRING_BOARD_CV_DETAIL_EDIT_OTHER: 'HR_HIRING_BOARD_CV_DETAIL_EDIT_OTHER',
  HR_POLICY_POLICY_VIEW_OTHER: 'HR_POLICY_POLICY_VIEW_OTHER',
  HR_POLICY_POLICY_EDIT_OTHER: 'HR_POLICY_POLICY_EDIT_OTHER',
  HR_POLICY_BENEFIT_RULE_EDIT_OTHER: 'HR_POLICY_BENEFIT_RULE_EDIT_OTHER',
  HR_POLICY_BENEFIT_RULE_VIEW_OTHER: 'HR_POLICY_BENEFIT_RULE_VIEW_OTHER',
  HR_ONBOARD_MANAGEMENT_VIEW_OTHER: 'HR_ONBOARD_MANAGEMENT_VIEW_OTHER',
  HR_ONBOARD_MANAGEMENT_EDIT_OTHER: 'HR_ONBOARD_MANAGEMENT_EDIT_OTHER',
  HR_SETTINGS_HR_MEMBER_VIEW_OTHER: 'HR_SETTINGS_HR_MEMBER_VIEW_OTHER',
  HR_SETTINGS_HR_MEMBER_EDIT_OTHER: 'HR_SETTINGS_HR_MEMBER_EDIT_OTHER',
  HR_SETTINGS_ONBOARD_MEMBER_VIEW_OTHER: 'HR_SETTINGS_ONBOARD_MEMBER_VIEW_OTHER',
  HR_SETTINGS_ONBOARD_MEMBER_EDIT_OTHER: 'HR_SETTINGS_ONBOARD_MEMBER_EDIT_OTHER',
  HR_SETTINGS_ONBOARD_MANAGEMENT_VIEW_OTHER: 'HR_SETTINGS_ONBOARD_MANAGEMENT_VIEW_OTHER',
  HR_SETTINGS_ONBOARD_MANAGEMENT_EDIT_OTHER: 'HR_SETTINGS_ONBOARD_MANAGEMENT_EDIT_OTHER',
  HR_SETTINGS_HIRING_BOARD_VIEW_OTHER: 'HR_SETTINGS_HIRING_BOARD_VIEW_OTHER',
  HR_SETTINGS_HIRING_BOARD_EDIT_OTHER: 'HR_SETTINGS_HIRING_BOARD_EDIT_OTHER',
  HR_SALARY_PROFILE_VIEW_OTHER: 'HR_SALARY_PROFILE_VIEW_OTHER',
  HR_SALARY_PROFILE_EDIT_OTHER: 'HR_SALARY_PROFILE_EDIT_OTHER',
  HR_PAY_SCHEDULE_VIEW_OTHER: 'HR_PAY_SCHEDULE_VIEW_OTHER',
  HR_PAY_SCHEDULE_EDIT_OTHER: 'HR_PAY_SCHEDULE_EDIT_OTHER',

  ORGANIZATION_USER_DETAIL_WORK_INFO_LOCATION_GROUP_TEAM_VIEW_OTHER: 'ORGANIZATION_USER_DETAIL_WORK_INFO_LOCATION_GROUP_TEAM_VIEW_OTHER',
  ORGANIZATION_USER_DETAIL_WORK_INFO_LOCATION_GROUP_TEAM_EDIT_OTHER: 'ORGANIZATION_USER_DETAIL_WORK_INFO_LOCATION_GROUP_TEAM_EDIT_OTHER',
  ORGANIZATION_USER_DETAIL_WORK_INFO_LOCATION_GROUP_TEAM_VIEW_OTHER_ADVANCE: 'ORGANIZATION_USER_DETAIL_WORK_INFO_LOCATION_GROUP_TEAM_VIEW_OTHER_ADVANCE',
  ORGANIZATION_USER_DETAIL_WORK_INFO_LOCATION_GROUP_TEAM_EDIT_OTHER_ADVANCE: 'ORGANIZATION_USER_DETAIL_WORK_INFO_LOCATION_GROUP_TEAM_EDIT_OTHER_ADVANCE',
  ORGANIZATION_USER_DETAIL_WORK_INFO_JOB_DATES_VIEW_OTHER: 'ORGANIZATION_USER_DETAIL_WORK_INFO_JOB_DATES_VIEW_OTHER',
  ORGANIZATION_USER_DETAIL_WORK_INFO_JOB_DATES_EDIT_OTHER: 'ORGANIZATION_USER_DETAIL_WORK_INFO_JOB_DATES_EDIT_OTHER',
  ORGANIZATION_USER_DETAIL_WORK_INFO_JOB_DATES_VIEW_OTHER_ADVANCE: 'ORGANIZATION_USER_DETAIL_WORK_INFO_JOB_DATES_VIEW_OTHER_ADVANCE',
  ORGANIZATION_USER_DETAIL_WORK_INFO_JOB_DATES_EDIT_OTHER_ADVANCE: 'ORGANIZATION_USER_DETAIL_WORK_INFO_JOB_DATES_EDIT_OTHER_ADVANCE',
  ORGANIZATION_USER_DETAIL_LEAVE_HISTORY_LEAVE_HISTORY_VIEW_OTHER: 'ORGANIZATION_USER_DETAIL_LEAVE_HISTORY_LEAVE_HISTORY_VIEW_OTHER',
  ORGANIZATION_USER_DETAIL_LEAVE_HISTORY_LEAVE_HISTORY_EDIT_OTHER: 'ORGANIZATION_USER_DETAIL_LEAVE_HISTORY_LEAVE_HISTORY_EDIT_OTHER',
  ORGANIZATION_USER_DETAIL_LEAVE_HISTORY_LEAVE_HISTORY_VIEW_OTHER_ADVANCE: 'ORGANIZATION_USER_DETAIL_LEAVE_HISTORY_LEAVE_HISTORY_VIEW_OTHER_ADVANCE',
  ORGANIZATION_USER_DETAIL_LEAVE_HISTORY_LEAVE_HISTORY_EDIT_OTHER_ADVANCE: 'ORGANIZATION_USER_DETAIL_LEAVE_HISTORY_LEAVE_HISTORY_EDIT_OTHER_ADVANCE',
  ORGANIZATION_USER_DETAIL_REPORT_HISTORY_REPORT_HISTORY_VIEW_OTHER: 'ORGANIZATION_USER_DETAIL_REPORT_HISTORY_REPORT_HISTORY_VIEW_OTHER',
  ORGANIZATION_USER_DETAIL_REPORT_HISTORY_REPORT_HISTORY_EDIT_OTHER: 'ORGANIZATION_USER_DETAIL_REPORT_HISTORY_REPORT_HISTORY_EDIT_OTHER',
  ORGANIZATION_USER_DETAIL_REPORT_HISTORY_REPORT_HISTORY_VIEW_OTHER_ADVANCE: 'ORGANIZATION_USER_DETAIL_REPORT_HISTORY_REPORT_HISTORY_VIEW_OTHER_ADVANCE',
  ORGANIZATION_USER_DETAIL_REPORT_HISTORY_REPORT_HISTORY_EDIT_OTHER_ADVANCE: 'ORGANIZATION_USER_DETAIL_REPORT_HISTORY_REPORT_HISTORY_EDIT_OTHER_ADVANCE',
  ORGANIZATION_USER_DETAIL_CONTACT_VIEW_OTHER: 'ORGANIZATION_USER_DETAIL_CONTACT_VIEW_OTHER',
  ORGANIZATION_USER_DETAIL_CONTACT_EDIT_OTHER: 'ORGANIZATION_USER_DETAIL_CONTACT_EDIT_OTHER',
  ORGANIZATION_USER_DETAIL_CONTACT_VIEW_OTHER_ADVANCE: 'ORGANIZATION_USER_DETAIL_CONTACT_VIEW_OTHER_ADVANCE',
  ORGANIZATION_USER_DETAIL_CONTACT_EDIT_OTHER_ADVANCE: 'ORGANIZATION_USER_DETAIL_CONTACT_EDIT_OTHER_ADVANCE',
  ORGANIZATION_USER_DETAIL_BANK_VIEW_OTHER: 'ORGANIZATION_USER_DETAIL_BANK_VIEW_OTHER',
  ORGANIZATION_USER_DETAIL_BANK_EDIT_OTHER: 'ORGANIZATION_USER_DETAIL_BANK_EDIT_OTHER',
  ORGANIZATION_USER_DETAIL_BANK_VIEW_OTHER_ADVANCE: 'ORGANIZATION_USER_DETAIL_BANK_VIEW_OTHER_ADVANCE',
  ORGANIZATION_USER_DETAIL_BANK_EDIT_OTHER_ADVANCE: 'ORGANIZATION_USER_DETAIL_BANK_EDIT_OTHER_ADVANCE',
  ORGANIZATION_USER_DETAIL_PERSONAL_BASIC_INFO_VIEW_OTHER: 'ORGANIZATION_USER_DETAIL_PERSONAL_BASIC_INFO_VIEW_OTHER',
  ORGANIZATION_USER_DETAIL_PERSONAL_BASIC_INFO_EDIT_OTHER: 'ORGANIZATION_USER_DETAIL_PERSONAL_BASIC_INFO_EDIT_OTHER',
  ORGANIZATION_USER_DETAIL_PERSONAL_BASIC_INFO_VIEW_OTHER_ADVANCE: 'ORGANIZATION_USER_DETAIL_PERSONAL_BASIC_INFO_VIEW_OTHER_ADVANCE',
  ORGANIZATION_USER_DETAIL_PERSONAL_BASIC_INFO_EDIT_OTHER_ADVANCE: 'ORGANIZATION_USER_DETAIL_PERSONAL_BASIC_INFO_EDIT_OTHER_ADVANCE',
  ORGANIZATION_USER_DETAIL_POLICIES_POLICIES_VIEW_OTHER: 'ORGANIZATION_USER_DETAIL_POLICIES_POLICIES_VIEW_OTHER',
  ORGANIZATION_USER_DETAIL_POLICIES_POLICIES_EDIT_OTHER: 'ORGANIZATION_USER_DETAIL_POLICIES_POLICIES_EDIT_OTHER',
  ORGANIZATION_USER_DETAIL_POLICIES_POLICIES_VIEW_OTHER_ADVANCE: 'ORGANIZATION_USER_DETAIL_POLICIES_POLICIES_VIEW_OTHER_ADVANCE',
  ORGANIZATION_USER_DETAIL_POLICIES_POLICIES_EDIT_OTHER_ADVANCE: 'ORGANIZATION_USER_DETAIL_POLICIES_POLICIES_EDIT_OTHER_ADVANCE',
  ORGANIZATION_USER_DETAIL_ONBOARD_MISSIONS_ONBOARD_MISSIONS_VIEW_OTHER: 'ORGANIZATION_USER_DETAIL_ONBOARD_MISSIONS_ONBOARD_MISSIONS_VIEW_OTHER',
  ORGANIZATION_USER_DETAIL_ONBOARD_MISSIONS_ONBOARD_MISSIONS_EDIT_OTHER: 'ORGANIZATION_USER_DETAIL_ONBOARD_MISSIONS_ONBOARD_MISSIONS_EDIT_OTHER',
  ORGANIZATION_USER_DETAIL_ONBOARD_MISSIONS_ONBOARD_MISSIONS_VIEW_OTHER_ADVANCE: 'ORGANIZATION_USER_DETAIL_ONBOARD_MISSIONS_ONBOARD_MISSIONS_VIEW_OTHER_ADVANCE',
  ORGANIZATION_USER_DETAIL_ONBOARD_MISSIONS_ONBOARD_MISSIONS_EDIT_OTHER_ADVANCE: 'ORGANIZATION_USER_DETAIL_ONBOARD_MISSIONS_ONBOARD_MISSIONS_EDIT_OTHER_ADVANCE',
  ORGANIZATION_USER_DETAIL_SALARY_SETTING_SALARY_SETTING_VIEW_OTHER: 'ORGANIZATION_USER_DETAIL_SALARY_SETTING_SALARY_SETTING_VIEW_OTHER',
  ORGANIZATION_USER_DETAIL_SALARY_SETTING_SALARY_SETTING_EDIT_OTHER: 'ORGANIZATION_USER_DETAIL_SALARY_SETTING_SALARY_SETTING_EDIT_OTHER',
  ORGANIZATION_USER_DETAIL_SALARY_SETTING_SALARY_SETTING_VIEW_OTHER_ADVANCE: 'ORGANIZATION_USER_DETAIL_SALARY_SETTING_SALARY_SETTING_VIEW_OTHER_ADVANCE',
  ORGANIZATION_USER_DETAIL_SALARY_SETTING_SALARY_SETTING_EDIT_OTHER_ADVANCE: 'ORGANIZATION_USER_DETAIL_SALARY_SETTING_SALARY_SETTING_EDIT_OTHER_ADVANCE',
  ORGANIZATION_USER_DETAIL_NOTIFICATION_NOTIFICATION_VIEW_OTHER: 'ORGANIZATION_USER_DETAIL_NOTIFICATION_NOTIFICATION_VIEW_OTHER',
  ORGANIZATION_USER_DETAIL_NOTIFICATION_NOTIFICATION_EDIT_OTHER: 'ORGANIZATION_USER_DETAIL_NOTIFICATION_NOTIFICATION_EDIT_OTHER',
  ORGANIZATION_USER_DETAIL_NOTIFICATION_NOTIFICATION_VIEW_OTHER_ADVANCE: 'ORGANIZATION_USER_DETAIL_NOTIFICATION_NOTIFICATION_VIEW_OTHER_ADVANCE',
  ORGANIZATION_USER_DETAIL_NOTIFICATION_NOTIFICATION_EDIT_OTHER_ADVANCE: 'ORGANIZATION_USER_DETAIL_NOTIFICATION_NOTIFICATION_EDIT_OTHER_ADVANCE',
  ORGANIZATION_INTEGRATION_VIEW: 'ORGANIZATION_INTEGRATION_VIEW',
  ORGANIZATION_INTEGRATION_EDIT: 'ORGANIZATION_INTEGRATION_EDIT',
  TASK_MANAGEMENT_PROJECT_SETTING_SWIMLANES: 'TASK_MANAGEMENT_PROJECT_SETTING_SWIMLANES',
  TASK_MANAGEMENT_PROJECT_SETTING_SWIMLANES_VIEW: 'TASK_MANAGEMENT_PROJECT_SETTING_SWIMLANES_VIEW',
  TASK_MANAGEMENT_PROJECT_SETTING_SWIMLANES_EDIT: 'TASK_MANAGEMENT_PROJECT_SETTING_SWIMLANES_EDIT',
  TASK_MANAGEMENT_PROJECT_SETTING_GIT: 'TASK_MANAGEMENT_PROJECT_SETTING_GIT',
  TASK_MANAGEMENT_PROJECT_SETTING_GIT_VIEW: 'TASK_MANAGEMENT_PROJECT_SETTING_GIT_VIEW',
  TASK_MANAGEMENT_PROJECT_SETTING_GIT_EDIT: 'TASK_MANAGEMENT_PROJECT_SETTING_GIT_EDIT',
  TASK_MANAGEMENT_PROJECT_SETTING_SLACK_INTEGRATION: 'TASK_MANAGEMENT_PROJECT_SETTING_SLACK_INTEGRATION',
  TASK_MANAGEMENT_PROJECT_SETTING_SLACK_INTEGRATION_VIEW: 'TASK_MANAGEMENT_PROJECT_SETTING_SLACK_INTEGRATION_VIEW',
  TASK_MANAGEMENT_PROJECT_SETTING_SLACK_INTEGRATION_EDIT: 'TASK_MANAGEMENT_PROJECT_SETTING_SLACK_INTEGRATION_EDIT',
  TASK_MANAGEMENT_TASK_FINDER: 'TASK_MANAGEMENT_TASK_FINDER',
  TASK_MANAGEMENT_TASK_FINDER_FILTER: 'TASK_MANAGEMENT_TASK_FINDER_FILTER',
  TASK_MANAGEMENT_TASK_FINDER_FILTER_MY_FILTER: 'TASK_MANAGEMENT_TASK_FINDER_FILTER_MY_FILTER',
  TASK_MANAGEMENT_TASK_FINDER_FILTER_MY_FILTER_VIEW: 'TASK_MANAGEMENT_TASK_FINDER_FILTER_MY_FILTER_VIEW',
  TASK_MANAGEMENT_TASK_FINDER_FILTER_MY_FILTER_EDIT: 'TASK_MANAGEMENT_TASK_FINDER_FILTER_MY_FILTER_EDIT',
  TASK_MANAGEMENT_TASK_FINDER_FILTER_SHARED_FILTER: 'TASK_MANAGEMENT_TASK_FINDER_FILTER_SHARED_FILTER',
  TASK_MANAGEMENT_TASK_FINDER_FILTER_SHARED_FILTER_VIEW: 'TASK_MANAGEMENT_TASK_FINDER_FILTER_SHARED_FILTER_VIEW',
  TASK_MANAGEMENT_TASK_FINDER_FILTER_SHARED_FILTER_EDIT: 'TASK_MANAGEMENT_TASK_FINDER_FILTER_SHARED_FILTER_EDIT',
  TASK_MANAGEMENT_TASK_FINDER_VIEW: 'TASK_MANAGEMENT_TASK_FINDER_VIEW',
  TASK_MANAGEMENT_TASK_FINDER_EDIT: 'TASK_MANAGEMENT_TASK_FINDER_EDIT',
  USER_DASHBOARD_NEED_FEEDBACK: 'USER_DASHBOARD_NEED_FEEDBACK',
  USER_DASHBOARD_NEED_FEEDBACK_VIEW: 'USER_DASHBOARD_NEED_FEEDBACK_VIEW',
  USER_DASHBOARD_NEED_FEEDBACK_EDIT: 'USER_DASHBOARD_NEED_FEEDBACK_EDIT',
  OKR_POPULATE: "OKR_POPULATE",
  OKR_POPULATE_VIEW: "OKR_POPULATE_VIEW",
  OKR_POPULATE_EDIT: "OKR_POPULATE_EDIT",
  OKR_POPULATE_VIEW_OTHER: "OKR_POPULATE_VIEW_OTHER",
  OKR_POPULATE_EDIT_OTHER: "OKR_POPULATE_EDIT_OTHER",
  TASK_MANAGEMENT_GROUP_STATUS: 'TASK_MANAGEMENT_GROUP_STATUS',
  TASK_MANAGEMENT_GROUP_STATUS_EDIT: 'TASK_MANAGEMENT_GROUP_STATUS_EDIT',
  ASSET_MANAGEMENT: 'ASSET_MANAGEMENT',
  ASSET_MANAGEMENT_VIEW: 'ASSET_MANAGEMENT_VIEW',
  ASSET_MANAGEMENT_EDIT: 'ASSET_MANAGEMENT_EDIT',
};

export const permissionName = {
  PAGE: 'Page',
  PAGE_MY_PROFILE: 'My Profile',
  PAGE_MY_PROFILE_EDIT: 'Edit',
  PAGE_MY_PROFILE_VIEW_ADVANCE: 'View Advance',
  PAGE_MY_PROFILE_EDIT_ADVANCE: 'Edit Advance',
  PAGE_MY_PROFILE_PERSONAL_EDIT: 'Edit',
  PAGE_MY_PROFILE_PERSONAL_VIEW_ADVANCE: 'View Advance',
  PAGE_MY_PROFILE_PERSONAL_EDIT_ADVANCE: 'Edit Advance',
  PAGE_MY_PROFILE_PERSONAL_BASIC_INFO_EDIT: 'Edit',
  PAGE_MY_PROFILE_PERSONAL_BASIC_INFO_VIEW_ADVANCE: 'View Advance',
  PAGE_MY_PROFILE_PERSONAL_BASIC_INFO_EDIT_ADVANCE: 'Edit Advance',
  PAGE_MY_PROFILE_CONTACT_EDIT: 'Edit',
  PAGE_MY_PROFILE_CONTACT_VIEW_ADVANCE: 'View Advance',
  PAGE_MY_PROFILE_CONTACT_EDIT_ADVANCE: 'Edit Advance',
  PAGE_MY_PROFILE_BANK_EDIT: 'Edit',
  PAGE_MY_PROFILE_BANK_VIEW_ADVANCE: 'View Advance',
  PAGE_MY_PROFILE_BANK_EDIT_ADVANCE: 'Edit Advance',
  PAGE_MY_PROFILE_PERSONAL: 'Personal',
  PAGE_MY_PROFILE_PERSONAL_BASIC_INFO: 'Basic Info',
  PAGE_MY_PROFILE_CONTACT: 'Contact',
  PAGE_MY_PROFILE_BANK: 'Bank',
  PAGE_MY_PROFILE_WORK_INFO: 'Work Info',
  PAGE_MY_PROFILE_WORK_INFO_EDIT:'Edit',
  PAGE_MY_PROFILE_WORK_INFO_VIEW_ADVANCE: 'View Advance',
  PAGE_MY_PROFILE_WORK_INFO_EDIT_ADVANCE: 'Edit Advance',
  PAGE_MY_PROFILE_WORK_INFO_LOCATION_GROUP_TEAM: 'Location/Group/Team',
  PAGE_MY_PROFILE_WORK_INFO_LOCATION_GROUP_TEAM_EDIT: 'Edit',
  PAGE_MY_PROFILE_WORK_INFO_LOCATION_GROUP_TEAM_VIEW_ADVANCE: 'View Advance',
  PAGE_MY_PROFILE_WORK_INFO_LOCATION_GROUP_TEAM_EDIT_ADVANCE: 'Edit Advance',
  PAGE_MY_PROFILE_WORK_INFO_JOB_DATES: 'Job Dates',
  PAGE_MY_PROFILE_WORK_INFO_JOB_DATES_EDIT: 'Edit',
  PAGE_MY_PROFILE_WORK_INFO_JOB_DATES_VIEW_ADVANCE: 'View Advance',
  PAGE_MY_PROFILE_WORK_INFO_JOB_DATES_EDIT_ADVANCE: 'Edit Advance',
  PAGE_MY_PROFILE_LEAVE_HISTORY: 'Leave History',
  PAGE_MY_PROFILE_LEAVE_HISTORY_EDIT: 'Edit',
  PAGE_MY_PROFILE_LEAVE_HISTORY_VIEW_ADVANCE: 'View Advance',
  PAGE_MY_PROFILE_LEAVE_HISTORY_EDIT_ADVANCE: 'Edit Advance',
  PAGE_MY_PROFILE_LEAVE_HISTORY_LEAVE_HISTORY: 'Leave History',
  PAGE_MY_PROFILE_LEAVE_HISTORY_LEAVE_HISTORY_EDIT: 'Edit',
  PAGE_MY_PROFILE_LEAVE_HISTORY_LEAVE_HISTORY_VIEW_ADVANCE: 'View Advance',
  PAGE_MY_PROFILE_LEAVE_HISTORY_LEAVE_HISTORY_EDIT_ADVANCE: 'Edit Advance',
  PAGE_MY_PROFILE_REPORT_HISTORY: 'Report History',
  PAGE_MY_PROFILE_REPORT_HISTORY_EDIT: 'Edit',
  PAGE_MY_PROFILE_REPORT_HISTORY_VIEW_ADVANCE: 'View Advance',
  PAGE_MY_PROFILE_REPORT_HISTORY_EDIT_ADVANCE: 'Edit Advance',
  PAGE_MY_PROFILE_REPORT_HISTORY_REPORT_HISTORY: 'Report History',
  PAGE_MY_PROFILE_REPORT_HISTORY_REPORT_HISTORY_EDIT: 'Edit',
  PAGE_MY_PROFILE_REPORT_HISTORY_REPORT_HISTORY_VIEW_ADVANCE: 'View Advance',
  PAGE_MY_PROFILE_REPORT_HISTORY_REPORT_HISTORY_EDIT_ADVANCE: 'Edit Advance',
  PAGE_MY_PROFILE_POLICIES: 'Policies',
  PAGE_MY_PROFILE_POLICIES_EDIT: 'Edit',
  PAGE_MY_PROFILE_POLICIES_VIEW_ADVANCE: 'View Advance',
  PAGE_MY_PROFILE_POLICIES_EDIT_ADVANCE: 'Edit Advance',
  PAGE_MY_PROFILE_POLICIES_POLICIES: 'Policies',
  PAGE_MY_PROFILE_POLICIES_POLICIES_EDIT: 'Edit',
  PAGE_MY_PROFILE_POLICIES_POLICIES_VIEW_ADVANCE: 'View Advance',
  PAGE_MY_PROFILE_POLICIES_POLICIES_EDIT_ADVANCE: 'Edit Advance',
  PAGE_MY_PROFILE_ONBOARD_MISSIONS: 'Onboard Missions',
  PAGE_MY_PROFILE_ONBOARD_MISSIONS_EDIT: 'Edit',
  PAGE_MY_PROFILE_ONBOARD_MISSIONS_VIEW_ADVANCE: 'View Advance',
  PAGE_MY_PROFILE_ONBOARD_MISSIONS_EDIT_ADVANCE: 'Edit Advance',
  PAGE_MY_PROFILE_ONBOARD_MISSIONS_ONBOARD_MISSIONS: 'Onboard Missions',
  PAGE_MY_PROFILE_ONBOARD_MISSIONS_ONBOARD_MISSIONS_EDIT: 'Edit',
  PAGE_MY_PROFILE_ONBOARD_MISSIONS_ONBOARD_MISSIONS_VIEW_ADVANCE: 'View Advance',
  PAGE_MY_PROFILE_ONBOARD_MISSIONS_ONBOARD_MISSIONS_EDIT_ADVANCE: 'Edit Advance',
  PAGE_MY_PROFILE_SALARY_SETTING: 'Salary Settings',
  PAGE_MY_PROFILE_SALARY_SETTING_EDIT: 'Edit',
  PAGE_MY_PROFILE_SALARY_SETTING_VIEW_ADVANCE: 'View Advance',
  PAGE_MY_PROFILE_SALARY_SETTING_EDIT_ADVANCE: 'Edit Advance',
  PAGE_MY_PROFILE_SALARY_SETTING_SALARY_SETTING: 'Salary Settings',
  PAGE_MY_PROFILE_SALARY_SETTING_SALARY_SETTING_EDIT: 'Edit',
  PAGE_MY_PROFILE_SALARY_SETTING_SALARY_SETTING_VIEW_ADVANCE: 'View Advance',
  PAGE_MY_PROFILE_SALARY_SETTING_SALARY_SETTING_EDIT_ADVANCE: 'Edit Advance',
  PAGE_MY_PROFILE_NOTIFICATION: 'Notification',
  PAGE_MY_PROFILE_NOTIFICATION_EDIT: 'Edit',
  PAGE_MY_PROFILE_NOTIFICATION_VIEW_ADVANCE: 'View Advance',
  PAGE_MY_PROFILE_NOTIFICATION_EDIT_ADVANCE: 'Edit Advance',
  PAGE_MY_PROFILE_NOTIFICATION_NOTIFICATION: 'Notification',
  PAGE_MY_PROFILE_NOTIFICATION_NOTIFICATION_EDIT: 'Edit',
  PAGE_MY_PROFILE_NOTIFICATION_NOTIFICATION_VIEW_ADVANCE: 'View Advance',
  PAGE_MY_PROFILE_NOTIFICATION_NOTIFICATION_EDIT_ADVANCE: 'Edit Advance',
  DOCUMENT_PROJECTS_EDIT_OTHER: 'Edit Another',
  DOCUMENT_PROJECTS_VIEW_OTHER: 'View Another',
  DOCUMENT_COMPANY_VIEW_OTHER: 'View Another',
  DOCUMENT_COMPANY_EDIT_OTHER: 'Edit Another',
  DOCUMENT_SYSTEM_FIELD_VIEW_OTHER: 'View Another',
  DOCUMENT_SYSTEM_FIELD_EDIT_OTHER: 'Edit Another',
  DOCUMENT_RECENT_VIEW_OTHER: 'View Another',
  DOCUMENT_RECENT_EDIT_OTHER: 'Edit Another',
  DOCUMENT_RECYCLE_BIN_VIEW_OTHER: 'View Another',
  DOCUMENT_RECYCLE_BIN_EDIT_OTHER: 'Edit Another',
  DOCUMENT_FILE_PROVIDER_VIEW_OTHER: 'View Another',
  DOCUMENT_FILE_PROVIDER_EDIT_OTHER: 'Edit Another',
  DOCUMENT_MANAGE_STORAGE_VIEW_OTHER: 'View Another',
  DOCUMENT_MANAGE_STORAGE_EDIT_OTHER: 'Edit Another',
  WORK_TRACKING_DASHBOARD_VIEW_OTHER: 'View Another',
  WORK_TRACKING_DASHBOARD_EDIT_OTHER: 'Edit Another',
  WORK_TRACKING_MY_TRACKING_VIEW_OTHER: 'View Another',
  WORK_TRACKING_MY_TRACKING_EDIT_OTHER: 'Edit Another',
  WORK_TRACKING_SETTING_CHOOSE_LEAVE_TYPE_VIEW_OTHER: 'View Another',
  WORK_TRACKING_SETTING_CHOOSE_LEAVE_TYPE_EDIT_OTHER: 'Edit Another',
  WORK_TRACKING_SETTING_SHIFT_PROFILE_SETTING_VIEW_OTHER: 'View Another',
  WORK_TRACKING_SETTING_SHIFT_PROFILE_SETTING_EDIT_OTHER: 'Edit Another',
  TIMESHEET_MY_LOGWORK_VIEW_OTHER: 'View Another',
  TIMESHEET_MY_LOGWORK_EDIT_OTHER: 'Edit Another',
  TIMESHEET_REPORTS_VIEW_OTHER: 'View Another',
  TIMESHEET_REPORTS_EDIT_OTHER: 'Edit Another',
  TASK_MANAGEMENT_STORYLINE_VIEW_OTHER: 'View Another',
  TASK_MANAGEMENT_STORYLINE_EDIT_OTHER: 'Edit Another',
  TASK_MANAGEMENT_GIANTT_VIEW_OTHER: 'View Another',
  TASK_MANAGEMENT_GIANTT_EDIT_OTHER: 'Edit Another',
  TASK_MANAGEMENT_PROJECTS_VIEW_OTHER: 'View Another',
  TASK_MANAGEMENT_PROJECTS_EDIT_OTHER: 'Edit Another',
  TASK_MANAGEMENT_ACTIVE_SPRINT_KANBAN_BOARD_VIEW_OTHER: 'View Another',
  TASK_MANAGEMENT_ACTIVE_SPRINT_KANBAN_BOARD_EDIT_OTHER: 'Edit Another',
  TASK_MANAGEMENT_RELEASE_RELEASE_VIEW_OTHER: 'View Another',
  TASK_MANAGEMENT_RELEASE_RELEASE_EDIT_OTHER: 'Edit Another',
  TASK_MANAGEMENT_RELEASE_RELEASE_DETAIL_VIEW_OTHER: 'View Another',
  TASK_MANAGEMENT_RELEASE_RELEASE_DETAIL_EDIT_OTHER: 'Edit Another',
  TASK_MANAGEMENT_RELEASE_RELEASE_NOTE_VIEW_OTHER: 'View Another',
  TASK_MANAGEMENT_RELEASE_RELEASE_NOTE_EDIT_OTHER: 'Edit Another',
  TASK_MANAGEMENT_BACKLOG_VIEW_OTHER: 'View Another',
  TASK_MANAGEMENT_BACKLOG_EDIT_OTHER: 'Edit Another',
  TASK_MANAGEMENT_EPIC_EPIC_VIEW_OTHER: 'View Another',
  TASK_MANAGEMENT_EPIC_EPIC_EDIT_OTHER: 'Edit Another',
  TASK_MANAGEMENT_EPIC_DETAIL_VIEW_OTHER: 'View Another',
  TASK_MANAGEMENT_EPIC_DETAIL_EDIT_OTHER: 'Edit Another',
  TASK_MANAGEMENT_PROJECT_SETTING_GENERAL_VIEW_OTHER: 'View Another',
  TASK_MANAGEMENT_PROJECT_SETTING_GENERAL_EDIT_OTHER: 'Edit Another',
  TASK_MANAGEMENT_PROJECT_SETTING_PROJECT_MEMBERS_VIEW_OTHER: 'View Another',
  TASK_MANAGEMENT_PROJECT_SETTING_PROJECT_MEMBERS_EDIT_OTHER: 'Edit Another',
  TASK_MANAGEMENT_PROJECT_SETTING_BOARD_SETTINGS_VIEW_OTHER: 'View Another',
  TASK_MANAGEMENT_PROJECT_SETTING_BOARD_SETTINGS_EDIT_OTHER: 'Edit Another',
  TASK_MANAGEMENT_PROJECT_SETTING_TASK_TYPE_VIEW_OTHER: 'View Another',
  TASK_MANAGEMENT_PROJECT_SETTING_TASK_TYPE_EDIT_OTHER: 'Edit Another',
  TASK_MANAGEMENT_PROJECT_SETTING_PRIORITY_VIEW_OTHER: 'View Another',
  TASK_MANAGEMENT_PROJECT_SETTING_PRIORITY_EDIT_OTHER: 'Edit Another',
  TASK_MANAGEMENT_PROJECT_SETTING_STORYLINE_VIEW_OTHER: 'View Another',
  TASK_MANAGEMENT_PROJECT_SETTING_STORYLINE_EDIT_OTHER: 'Edit Another',
  TASK_MANAGEMENT_PROJECT_SETTING_LABEL_VIEW_OTHER: 'View Another',
  TASK_MANAGEMENT_PROJECT_SETTING_LABEL_EDIT_OTHER: 'Edit Another',
  TASK_MANAGEMENT_PROJECT_SETTING_HISTORY_VIEW_OTHER: 'View Another',
  TASK_MANAGEMENT_PROJECT_SETTING_HISTORY_EDIT_OTHER: 'Edit Another',
  TASK_MANAGEMENT_TASK_VIEW_OTHER: 'View Another',
  TASK_MANAGEMENT_TASK_EDIT_OTHER: 'Edit Another',
  // Task Detail
  TASK_MANAGEMENT_TASK_DETAIL: 'Task',
  TASK_MANAGEMENT_TASK_DETAIL_EDIT_AFTER_DUE_DATE: 'Edit After Due Date',
  TASK_MANAGEMENT_TASK_DETAIL_VIEW_OTHER: 'View Other',
  TASK_MANAGEMENT_TASK_DETAIL_EDIT_OTHER: 'Edit Other',
  TASK_MANAGEMENT_TASK_DETAIL_VIEW_ADVANCE: 'View Advance',
  TASK_MANAGEMENT_TASK_DETAIL_EDIT_ADVANCE: 'Edit Advance',
  TASK_MANAGEMENT_TASK_DETAIL_EDIT_ASSIGNEE: 'Edit Assignee',
  TASK_MANAGEMENT_TASK_DETAIL_EDIT_CC: 'Edit CC',
  TASK_MANAGEMENT_TASK_DETAIL_EDIT_REPORTER: 'Edit Reporter',
  TASK_MANAGEMENT_TASK_DETAIL_COMMENT: 'Comment',
  TASK_MANAGEMENT_TASK_DETAIL_COMMENT_VIEW_OTHER: 'View Other',
  TASK_MANAGEMENT_TASK_DETAIL_COMMENT_EDIT_OTHER: 'Edit Other',
  TASK_MANAGEMENT_TASK_DETAIL_COMMENT_EDIT: 'Edit',
  TASK_MANAGEMENT_TASK_DETAIL_COMMENT_DELETE: 'Delete',
  TASK_MANAGEMENT_TASK_DETAIL_COMMENT_CREATE: 'Create',
  TASK_MANAGEMENT_TASK_DETAIL_EDIT: 'Edit',
  TASK_MANAGEMENT_TASK_DETAIL_EDIT_PRIVATE_FLAG: 'Edit Private Flag',
  TASK_MANAGEMENT_TASK_DETAIL_DELETE: 'Delete',
  TASK_MANAGEMENT_TASK_DETAIL_CREATE: 'Create',
  ORGANIZATION_USER_DETAIL_WORK_INFO_LOCATION_GROUP_TEAM_VIEW_ADVANCE: 'View Advance',
  ORGANIZATION_USER_DETAIL_WORK_INFO_LOCATION_GROUP_TEAM_EDIT_ADVANCE: 'Edit Advance',
  ORGANIZATION_USER_DETAIL_WORK_INFO_JOB_DATES_VIEW_ADVANCE: 'View Advance',
  ORGANIZATION_USER_DETAIL_WORK_INFO_JOB_DATES_EDIT_ADVANCE: 'Edit Advance',
  ORGANIZATION_USER_DETAIL_LEAVE_HISTORY_LEAVE_HISTORY_VIEW_ADVANCE: 'View Advance',
  ORGANIZATION_USER_DETAIL_LEAVE_HISTORY_LEAVE_HISTORY_EDIT_ADVANCE: 'Edit Advance',
  ORGANIZATION_USER_DETAIL_REPORT_HISTORY_REPORT_HISTORY_VIEW_ADVANCE: 'View Advance',
  ORGANIZATION_USER_DETAIL_REPORT_HISTORY_REPORT_HISTORY_EDIT_ADVANCE: 'Edit Advance',
  ORGANIZATION_USER_DETAIL_CONTACT_EDIT_ADVANCE: 'Edit Advance',
  ORGANIZATION_USER_DETAIL_CONTACT_VIEW_ADVANCE: 'View Advance',
  ORGANIZATION_USER_DETAIL_BANK_VIEW_ADVANCE: 'View Advance',
  ORGANIZATION_USER_DETAIL_BANK_EDIT_ADVANCE: 'Edit Advance',
  ORGANIZATION_USER_DETAIL_PERSONAL_BASIC_INFO_VIEW_ADVANCE: 'View Advance',
  ORGANIZATION_USER_DETAIL_PERSONAL_BASIC_INFO_EDIT_ADVANCE: 'Edit Advance',
  ORGANIZATION_USER_DETAIL_POLICIES_POLICIES_VIEW_ADVANCE: 'View Advance',
  ORGANIZATION_USER_DETAIL_POLICIES_POLICIES_EDIT_ADVANCE: 'Edit Advance',
  ORGANIZATION_USER_DETAIL_ONBOARD_MISSIONS_ONBOARD_MISSIONS_VIEW_ADVANCE: 'View Advance',
  ORGANIZATION_USER_DETAIL_ONBOARD_MISSIONS_ONBOARD_MISSIONS_EDIT_ADVANCE: 'Edit Advance',
  ORGANIZATION_USER_DETAIL_SALARY_SETTING_SALARY_SETTING_VIEW_ADVANCE: 'View Advance',
  ORGANIZATION_USER_DETAIL_SALARY_SETTING_SALARY_SETTING_EDIT_ADVANCE: 'Edit Advance',
  ORGANIZATION_USER_DETAIL_NOTIFICATION_NOTIFICATION_VIEW_ADVANCE: 'View Advance',
  ORGANIZATION_USER_DETAIL_NOTIFICATION_NOTIFICATION_EDIT_ADVANCE: 'Edit Advance',
  ORGANIZATION_LOCATION_VIEW_OTHER: 'View Another',
  ORGANIZATION_LOCATION_EDIT_OTHER: 'Edit Another',
  ORGANIZATION_TEAM_VIEW_OTHER: 'View Another',
  ORGANIZATION_TEAM_EDIT_OTHER: 'Edit Another',
  ORGANIZATION_GROUP_VIEW_OTHER: 'View Another',
  ORGANIZATION_GROUP_EDIT_OTHER: 'Edit Another',
  ORGANIZATION_USER_VIEW_OTHER: 'View Another',
  ORGANIZATION_USER_EDIT_OTHER: 'Edit Another',
  ORGANIZATION_SHIFT_SHIFT_PROFILE_VIEW_OTHER: 'View Another',
  ORGANIZATION_SHIFT_SHIFT_PROFILE_EDIT_OTHER: 'Edit Another',
  ORGANIZATION_SHIFT_SHIFT_VIEW_OTHER: 'View Another',
  ORGANIZATION_SHIFT_SHIFT_EDIT_OTHER: 'Edit Another',
  ORGANIZATION_ROLE_PERMISSION_VIEW_OTHER: 'View Another',
  ORGANIZATION_ROLE_PERMISSION_EDIT_OTHER: 'Edit Another',
  ORGANIZATION_HOLIDAY_VIEW_OTHER: 'View Another',
  ORGANIZATION_HOLIDAY_EDIT_OTHER: 'Edit Another',
  ORGANIZATION_INTEGRATION_SLACK_VIEW_OTHER: 'Edit Another',
  ORGANIZATION_INTEGRATION_SLACK_EDIT_OTHER: 'Edit Another',
  ORGANIZATION_INTEGRATION_JIRA_VIEW_OTHER: 'View Another',
  ORGANIZATION_INTEGRATION_JIRA_EDIT_OTHER: 'Edit Another',
  ORGANIZATION_BILLING_CURRENT_PLAN_EDIT_OTHER: 'Edit Another',
  ORGANIZATION_BILLING_CURRENT_PLAN_VIEW_OTHER: 'View Another',
  ORGANIZATION_BILLING_NEXT_CHARGE_ESTIMATE_EDIT_OTHER: 'Edit Another',
  ORGANIZATION_BILLING_NEXT_CHARGE_ESTIMATE_VIEW_OTHER: 'View Another',
  ORGANIZATION_BILLING_INFORMATION_VIEW_OTHER: 'View Another',
  ORGANIZATION_BILLING_INFORMATION_EDIT_OTHER: 'Edit Another',
  ORGANIZATION_BILLING_BILLING_VIEW_OTHER: 'View Another',
  ORGANIZATION_BILLING_BILLING_EDIT_OTHER: 'Edit Another',
  ORGANIZATION_BILLING_HISTORY_VIEW_OTHER: 'View Another',
  ORGANIZATION_BILLING_HISTORY_EDIT_OTHER: 'Edit Another',
  ORGANIZATION_BILLING_PAYMENT_METHOD_VIEW_OTHER: 'View Another',
  ORGANIZATION_BILLING_PAYMENT_METHOD_EDIT_OTHER: 'Edit Another',
  ORGANIZATION_ORGANIZATION_TREE_ORGANIZATION_TREE_VIEW_OTHER: 'View Another',
  ORGANIZATION_ORGANIZATION_TREE_ORGANIZATION_TREE_EDIT_OTHER: 'Edit Another',
  ORGANIZATION_ORGANIZATION_TREE_COMPANY_STRUCTURE_VIEW_OTHER: 'View Another',
  ORGANIZATION_ORGANIZATION_TREE_COMPANY_STRUCTURE_EDIT_OTHER: 'Edit Another',
  ORGANIZATION_ORGANIZATION_TREE_JOB_TITLE_VIEW_OTHER: 'View Another',
  ORGANIZATION_ORGANIZATION_TREE_JOB_TITLE_EDIT_OTHER: 'Edit Another',
  AUDIT_LOG_VIEW_OTHER: 'View Another',
  AUDIT_LOG_EDIT_OTHER: 'Edit Another',
  SETTINGS_DYNAMIC_FIELD_VIEW_OTHER: 'View Another',
  SETTINGS_DYNAMIC_FIELD_EDIT_OTHER: 'Edit Another',
  SETTINGS_LANGUAGE_VIEW_OTHER: 'View Another',
  SETTINGS_LANGUAGE_EDIT_OTHER: 'Edit Another',
  SETTINGS_NOTIFICATIONS_VIEW_OTHER: 'View Another',
  SETTINGS_NOTIFICATIONS_EDIT_OTHER: 'Edit Another',
  SETTINGS_DOCUMENT_TEMPLATE_VIEW_OTHER: 'View Another',
  SETTINGS_DOCUMENT_TEMPLATE_EDIT_OTHER: 'Edit Another',
  USER_DASHBOARD_UNSET_DUE_DATE_TASKS_VIEW_OTHER: 'View Another',
  USER_DASHBOARD_UNSET_DUE_DATE_TASKS_EDIT_OTHER: 'Edit Another',
  USER_DASHBOARD_BOOKMARKED_TASKS_VIEW_OTHER: 'View Another',
  USER_DASHBOARD_BOOKMARKED_TASKS_EDIT_OTHER: 'Edit Another',
  USER_DASHBOARD_YOUR_TASKS_VIEW_OTHER: 'View Another',
  USER_DASHBOARD_YOUR_TASKS_EDIT_OTHER: 'Edit Another',
  USER_DASHBOARD_OVERDUE_TASKS_VIEW_OTHER: 'View Another',
  USER_DASHBOARD_OVERDUE_TASKS_EDIT_OTHER: 'Edit Another',
  LEAVE_DASHBOARD_WHO_IS_WFH_TODAY_VIEW_OTHER: 'View Another',
  LEAVE_DASHBOARD_WHO_IS_WFH_TODAY_EDIT_OTHER: 'Edit Another',
  LEAVE_DASHBOARD_LEAVE_DASHBOARD_VIEW_OTHER: 'View Another',
  LEAVE_DASHBOARD_LEAVE_DASHBOARD_EDIT_OTHER: 'Edit Another',
  LEAVE_DASHBOARD_WHO_IS_OFF_TODAY_VIEW_OTHER: 'View Another',
  LEAVE_DASHBOARD_WHO_IS_OFF_TODAY_EDIT_OTHER: 'Edit Another',
  LEAVE_DASHBOARD_BALANCE_DETAIL_VIEW_OTHER: 'View Another',
  LEAVE_DASHBOARD_BALANCE_DETAIL_EDIT_OTHER: 'Edit Another',
  LEAVE_MY_REQUEST_VIEW_OTHER: 'View Another',
  LEAVE_MY_REQUEST_EDIT_OTHER: 'Edit Another',
  LEAVE_APPROVAL_VIEW_OTHER: 'View Another',
  LEAVE_APPROVAL_EDIT_OTHER: 'Edit Another',
  LEAVE_BALANCE_VIEW_OTHER: 'View Another',
  LEAVE_BALANCE_EDIT_OTHER: 'Edit Another',
  LEAVE_REPORT_VIEW_OTHER: 'View Another',
  LEAVE_REPORT_EDIT_OTHER: 'Edit Another',
  LEAVE_SETTING_CATEGORY_VIEW_OTHER: 'View Another',
  LEAVE_SETTING_CATEGORY_EDIT_OTHER: 'Edit Another',
  LEAVE_SETTING_CARRY_OVER_VIEW_OTHER: 'View Another',
  LEAVE_SETTING_CARRY_OVER_EDIT_OTHER: 'Edit Another',
  LEAVE_SETTING_TYPE_VIEW_OTHER: 'View Another',
  LEAVE_SETTING_TYPE_EDIT_OTHER: 'Edit Another',
  OKR_OKR_VIEW_OTHER: 'View Another',
  OKR_OKR_EDIT_OTHER: 'Edit Another',
  OKR_OKR_SETTINGS_SPACES_MEMBER: 'Spaces Member',
  OKR_OKR_SETTINGS_SPACES_MEMBER_VIEW: 'View',
  OKR_OKR_SETTINGS_SPACES_MEMBER_EDIT: 'Edit',
  OKR_COMPANY: 'Company',
  OKR_COMPANY_VIEW: 'View',
  OKR_COMPANY_EDIT: 'Edit',
  OKR_GROUP: 'Group',
  OKR_GROUP_VIEW: 'View',
  OKR_GROUP_EDIT: 'Edit',
  OKR_GROUP_VIEW_OTHER: 'View Other',
  OKR_GROUP_EDIT_OTHER: 'Edit Other',
  OKR_TEAM: 'Team',
  OKR_TEAM_VIEW: 'View',
  OKR_TEAM_EDIT: 'Edit',
  OKR_TEAM_VIEW_OTHER: 'View Other',
  OKR_TEAM_EDIT_OTHER: 'Edit Other',
  OKR_PERSONAL: 'Personal',
  OKR_PERSONAL_VIEW: 'View Other',
  OKR_PERSONAL_EDIT: 'Edit Other',
  HR_PAYROLL_VIEW_OTHER: 'View Another',
  HR_PAYROLL_EDIT_OTHER: 'Edit Another',
  HR_HIRING_BOARD_HIRING_BOARD_VIEW_OTHER: 'View Another',
  HR_HIRING_BOARD_HIRING_BOARD_EDIT_OTHER: 'Edit Another',
  HR_HIRING_BOARD_CV_DETAIL_VIEW_OTHER: 'View Another',
  HR_HIRING_BOARD_CV_DETAIL_EDIT_OTHER: 'Edit Another',
  HR_POLICY_POLICY_VIEW_OTHER: 'View Another',
  HR_POLICY_POLICY_EDIT_OTHER: 'Edit Another',
  HR_POLICY_BENEFIT_RULE_EDIT_OTHER: 'Edit Another',
  HR_POLICY_BENEFIT_RULE_VIEW_OTHER: 'View Another',
  HR_ONBOARD_MANAGEMENT_VIEW_OTHER: 'View Another',
  HR_ONBOARD_MANAGEMENT_EDIT_OTHER: 'Edit Another',
  HR_SETTINGS_HR_MEMBER_VIEW_OTHER: 'View Another',
  HR_SETTINGS_HR_MEMBER_EDIT_OTHER: 'Edit Another',
  HR_SETTINGS_ONBOARD_MEMBER_VIEW_OTHER: 'View Another',
  HR_SETTINGS_ONBOARD_MEMBER_EDIT_OTHER: 'Edit Another',
  HR_SETTINGS_ONBOARD_MANAGEMENT_VIEW_OTHER: 'View Another',
  HR_SETTINGS_ONBOARD_MANAGEMENT_EDIT_OTHER: 'Edit Another',
  HR_SETTINGS_HIRING_BOARD_VIEW_OTHER: 'View Another',
  HR_SETTINGS_HIRING_BOARD_EDIT_OTHER: 'Edit Another',
  HR_SALARY_PROFILE_VIEW_OTHER: 'View Another',
  HR_SALARY_PROFILE_EDIT_OTHER: 'Edit Another',
  HR_PAY_SCHEDULE_VIEW_OTHER: 'View Another',
  HR_PAY_SCHEDULE_EDIT_OTHER: 'Edit Another',

  USER_DASHBOARD: 'User Dashboard',
  USER_DASHBOARD_YOUR_TASKS: 'Your Tasks',
  USER_DASHBOARD_YOUR_TASKS_VIEW: 'View',
  USER_DASHBOARD_YOUR_TASKS_EDIT: 'Edit',
  USER_DASHBOARD_OVERDUE_TASKS: 'Overdue Tasks',
  USER_DASHBOARD_OVERDUE_TASKS_VIEW: 'View',
  USER_DASHBOARD_OVERDUE_TASKS_EDIT: 'Edit',
  USER_DASHBOARD_UNSET_DUE_DATE_TASKS: 'Unset Due Date Tasks',
  USER_DASHBOARD_UNSET_DUE_DATE_TASKS_VIEW: 'View',
  USER_DASHBOARD_UNSET_DUE_DATE_TASKS_EDIT: 'Edit',
  USER_DASHBOARD_BOOKMARKED_TASKS: 'Bookmarked Tasks',
  USER_DASHBOARD_BOOKMARKED_TASKS_VIEW: 'View',
  USER_DASHBOARD_BOOKMARKED_TASKS_EDIT: 'Edit',
  LEAVE: 'Leave',
  LEAVE_DASHBOARD: 'Dashboard',
  LEAVE_DASHBOARD_WHO_IS_WFH_TODAY: 'Who is WFH today',
  LEAVE_DASHBOARD_WHO_IS_WFH_TODAY_VIEW: 'View',
  LEAVE_DASHBOARD_WHO_IS_WFH_TODAY_EDIT: 'Edit',
  LEAVE_DASHBOARD_LEAVE_DASHBOARD: 'Leave Dashboard',
  LEAVE_DASHBOARD_LEAVE_DASHBOARD_VIEW: 'View',
  LEAVE_DASHBOARD_LEAVE_DASHBOARD_EDIT: 'Edit',
  LEAVE_DASHBOARD_WHO_IS_OFF_TODAY: 'Who is OFF today',
  LEAVE_DASHBOARD_WHO_IS_OFF_TODAY_VIEW: 'View',
  LEAVE_DASHBOARD_WHO_IS_OFF_TODAY_EDIT: 'Edit',
  LEAVE_DASHBOARD_BALANCE_DETAIL: 'Balance Detail',
  LEAVE_DASHBOARD_BALANCE_DETAIL_VIEW: 'View',
  LEAVE_DASHBOARD_BALANCE_DETAIL_EDIT: 'Edit',
  LEAVE_MY_REQUEST: 'My Request',
  LEAVE_MY_REQUEST_VIEW: 'View',
  LEAVE_MY_REQUEST_EDIT: 'Edit',
  LEAVE_APPROVAL: 'Approval',
  LEAVE_APPROVAL_VIEW: 'View',
  LEAVE_APPROVAL_EDIT: 'Edit',
  LEAVE_BALANCE: 'Balance',
  LEAVE_BALANCE_VIEW: 'View',
  LEAVE_BALANCE_EDIT: 'Edit',
  LEAVE_REPORT: 'Report',
  LEAVE_REPORT_VIEW: 'View',
  LEAVE_REPORT_EDIT: 'Edit',
  LEAVE_SETTING: 'Setting',
  LEAVE_SETTING_CATEGORY: 'Category',
  LEAVE_SETTING_CATEGORY_VIEW: 'View',
  LEAVE_SETTING_CATEGORY_EDIT: 'Edit',
  LEAVE_SETTING_TYPE: 'Type',
  LEAVE_SETTING_TYPE_VIEW: 'View',
  LEAVE_SETTING_TYPE_EDIT: 'Edit',
  LEAVE_SETTING_CARRY_OVER: 'Carry Over',
  LEAVE_SETTING_CARRY_OVER_VIEW: 'View',
  LEAVE_SETTING_CARRY_OVER_EDIT: 'Edit',
  WORK_TRACKING: 'Work Tracking',
  WORK_TRACKING_DASHBOARD: 'Dashboard',
  WORK_TRACKING_DASHBOARD_VIEW: 'View',
  WORK_TRACKING_DASHBOARD_EDIT: 'Edit',
  WORK_TRACKING_MY_TRACKING: 'My Tracking',
  WORK_TRACKING_MY_TRACKING_VIEW: 'View',
  WORK_TRACKING_MY_TRACKING_EDIT: 'Edit',
  WORK_TRACKING_SETTING: 'Setting',
  WORK_TRACKING_SETTING_CHOOSE_LEAVE_TYPE: 'Choose Leave Type',
  WORK_TRACKING_SETTING_CHOOSE_LEAVE_TYPE_VIEW: 'View',
  WORK_TRACKING_SETTING_CHOOSE_LEAVE_TYPE_EDIT: 'Edit',
  WORK_TRACKING_SETTING_SHIFT_PROFILE_SETTING: 'Shift Profile Setting',
  WORK_TRACKING_SETTING_SHIFT_PROFILE_SETTING_VIEW: 'View',
  WORK_TRACKING_SETTING_SHIFT_PROFILE_SETTING_EDIT: 'Edit',
  TIMESHEET: 'Timesheet',
  TIMESHEET_MY_LOGWORK: 'My Logwork',
  TIMESHEET_MY_LOGWORK_VIEW: 'View',
  TIMESHEET_MY_LOGWORK_EDIT: 'Edit',
  TIMESHEET_REPORTS: 'Reports',
  TIMESHEET_REPORTS_VIEW: 'View',
  TIMESHEET_REPORTS_EDIT: 'Edit',
  TIMESHEET_SETTING_ADMIN: 'Timesheet Admin',
  TASK_MANAGEMENT: 'Task Management',
  TASK_MANAGEMENT_GIANTT: 'Gantt',
  TASK_MANAGEMENT_GIANTT_VIEW: 'View',
  TASK_MANAGEMENT_GIANTT_EDIT: 'Edit',
  TASK_MANAGEMENT_PROJECTS: 'Projects',
  TASK_MANAGEMENT_PROJECTS_VIEW: 'View',
  TASK_MANAGEMENT_PROJECTS_EDIT: 'Edit',
  TASK_MANAGEMENT_TASK: 'Tasks',
  TASK_MANAGEMENT_TASK_VIEW: 'View',
  TASK_MANAGEMENT_TASK_EDIT: 'Edit',
  TASK_MANAGEMENT_ACTIVE_SPRINT_KANBAN_BOARD: 'Active Sprint/Kanban Board',
  TASK_MANAGEMENT_ACTIVE_SPRINT_KANBAN_BOARD_VIEW: 'View',
  TASK_MANAGEMENT_RELEASE: 'Release',
  TASK_MANAGEMENT_RELEASE_RELEASE: 'Release',
  TASK_MANAGEMENT_RELEASE_RELEASE_VIEW: 'View',
  TASK_MANAGEMENT_RELEASE_RELEASE_EDIT: 'Edit',
  TASK_MANAGEMENT_RELEASE_RELEASE_DETAIL: 'Release Detail',
  TASK_MANAGEMENT_RELEASE_RELEASE_DETAIL_VIEW: 'View',
  TASK_MANAGEMENT_RELEASE_RELEASE_DETAIL_EDIT: 'Edit',
  TASK_MANAGEMENT_RELEASE_RELEASE_NOTE: 'Release Note',
  TASK_MANAGEMENT_RELEASE_RELEASE_NOTE_VIEW: 'View',
  TASK_MANAGEMENT_RELEASE_RELEASE_NOTE_EDIT: 'Edit',
  TASK_MANAGEMENT_BACKLOG: 'Backlog',
  TASK_MANAGEMENT_BACKLOG_VIEW: 'View',
  TASK_MANAGEMENT_SPRINT: 'Sprint',
  TASK_MANAGEMENT_SPRINT_VIEW: 'View',
  TASK_MANAGEMENT_SPRINT_EDIT: 'Edit',
  TASK_MANAGEMENT_EPIC: 'Epic',
  TASK_MANAGEMENT_EPIC_VIEW_EPIC_SCREEN: 'View Epic Screen',
  TASK_MANAGEMENT_EPIC_DETAIL: 'Detail',
  TASK_MANAGEMENT_EPIC_DETAIL_VIEW: 'View',
  TASK_MANAGEMENT_EPIC_DETAIL_EDIT: 'Edit',
  TASK_MANAGEMENT_PROJECT_SETTING: 'Project Setting',
  TASK_MANAGEMENT_PROJECT_SETTING_GENERAL: 'General',
  TASK_MANAGEMENT_PROJECT_SETTING_GENERAL_VIEW: 'View',
  TASK_MANAGEMENT_PROJECT_SETTING_GENERAL_EDIT: 'Edit',
  TASK_MANAGEMENT_PROJECT_SETTING_PROJECT_MEMBERS: 'Project Members',
  TASK_MANAGEMENT_PROJECT_SETTING_PROJECT_MEMBERS_VIEW: 'View',
  TASK_MANAGEMENT_PROJECT_SETTING_PROJECT_MEMBERS_EDIT: 'Edit',
  TASK_MANAGEMENT_PROJECT_SETTING_BOARD_SETTINGS: 'Board Settings',
  TASK_MANAGEMENT_PROJECT_SETTING_BOARD_SETTINGS_VIEW: 'View',
  TASK_MANAGEMENT_PROJECT_SETTING_BOARD_SETTINGS_EDIT: 'Edit',
  TASK_MANAGEMENT_PROJECT_SETTING_TASK_TYPE: 'Task Type',
  TASK_MANAGEMENT_PROJECT_SETTING_TASK_TYPE_VIEW: 'View',
  TASK_MANAGEMENT_PROJECT_SETTING_TASK_TYPE_EDIT: 'Edit',
  TASK_MANAGEMENT_PROJECT_SETTING_PRIORITY: 'Priority',
  TASK_MANAGEMENT_PROJECT_SETTING_PRIORITY_VIEW: 'View',
  TASK_MANAGEMENT_PROJECT_SETTING_PRIORITY_EDIT: 'Edit',
  TASK_MANAGEMENT_PROJECT_SETTING_STORYLINE: 'Storyline',
  TASK_MANAGEMENT_PROJECT_SETTING_STORYLINE_VIEW: 'View',
  TASK_MANAGEMENT_PROJECT_SETTING_STORYLINE_EDIT: 'Edit',
  TASK_MANAGEMENT_PROJECT_SETTING_LABEL: 'Label',
  TASK_MANAGEMENT_PROJECT_SETTING_LABEL_VIEW: 'View',
  TASK_MANAGEMENT_PROJECT_SETTING_LABEL_EDIT: 'Edit',
  TASK_MANAGEMENT_PROJECT_SETTING_HISTORY: 'History',
  TASK_MANAGEMENT_PROJECT_SETTING_HISTORY_VIEW: 'View',
  TASK_MANAGEMENT_PROJECT_SETTING_HISTORY_EDIT: 'Edit',
  TASK_MANAGEMENT_STORYLINE: 'Storyline',
  TASK_MANAGEMENT_STORYLINE_VIEW_STORYLINE_SCREEN: 'View Storyline Screen',
  TASK_MANAGEMENT_STORYLINE_DETAIL: 'Detail',
  TASK_MANAGEMENT_STORYLINE_DETAIL_VIEW: 'View',
  TASK_MANAGEMENT_STORYLINE_DETAIL_EDIT: 'Edit',
  TASK_MANAGEMENT_TASK_TEMPLATE: 'Task Template',
  TASK_MANAGEMENT_TASK_TEMPLATE_VIEW_TASK_TEMPLATE_SCREEN: 'View Task Template Screen',
  TASK_MANAGEMENT_TASK_TEMPLATE_DETAIL: 'Detail',
  TASK_MANAGEMENT_TASK_TEMPLATE_DETAIL_VIEW: 'View',
  TASK_MANAGEMENT_TASK_TEMPLATE_DETAIL_EDIT: 'Edit',
  DOCUMENT: 'Document',
  DOCUMENT_PROJECTS: 'Projects',
  DOCUMENT_PROJECTS_VIEW: 'View',
  DOCUMENT_PROJECTS_EDIT: 'Edit',
  DOCUMENT_COMPANY: 'Company',
  DOCUMENT_COMPANY_VIEW: 'View',
  DOCUMENT_COMPANY_EDIT: 'Edit',
  DOCUMENT_SYSTEM_FIELD: 'System field',
  DOCUMENT_SYSTEM_FIELD_VIEW: 'View',
  DOCUMENT_SYSTEM_FIELD_EDIT: 'Edit',
  DOCUMENT_RECENT: 'Recent',
  DOCUMENT_RECENT_VIEW: 'View',
  DOCUMENT_RECENT_EDIT: 'Edit',
  DOCUMENT_RECYCLE_BIN: 'Recycle Bin',
  DOCUMENT_RECYCLE_BIN_VIEW: 'View',
  DOCUMENT_RECYCLE_BIN_EDIT: 'Edit',
  DOCUMENT_FILE_PROVIDER: 'File Providers',
  DOCUMENT_FILE_PROVIDER_VIEW: 'View',
  DOCUMENT_FILE_PROVIDER_EDIT: 'Edit',
  DOCUMENT_MANAGE_STORAGE: 'Manage storage',
  DOCUMENT_MANAGE_STORAGE_VIEW: 'View',
  DOCUMENT_MANAGE_STORAGE_EDIT: 'Edit',
  OKR: 'OKR',
  OKR_OKR_SETTINGS: 'OKR Settings',
  OKR_OKR_SETTINGS_SPACES: 'Spaces',
  OKR_OKR_SETTINGS_SPACES_VIEW: 'View',
  OKR_OKR_SETTINGS_SPACES_EDIT: 'Edit',
  OKR_OKR_SETTINGS_TIMELINE: 'Timelines',
  OKR_OKR_SETTINGS_TIMELINES_VIEW: 'View',
  OKR_OKR_SETTINGS_TIMELINES_EDIT: 'Edit',
  OKR_OKR_SETTINGS_METRICS: 'Metrics',
  OKR_OKR_SETTINGS_METRICS_VIEW: 'View',
  OKR_OKR_SETTINGS_METRICS_EDIT: 'Edit',
  ORGANIZATION: 'Organization',
  ORGANIZATION_USER_DETAIL: 'User Detail',
  ORGANIZATION_USER_DETAIL_PERSONAL: 'Personal',
  ORGANIZATION_USER_DETAIL_PERSONAL_BASIC_INFO: 'Basic Info',
  ORGANIZATION_USER_DETAIL_PERSONAL_BASIC_INFO_VIEW: 'View',
  ORGANIZATION_USER_DETAIL_PERSONAL_BASIC_INFO_EDIT: 'Edit',
  ORGANIZATION_USER_DETAIL_CONTACT: 'Contact',
  ORGANIZATION_USER_DETAIL_CONTACT_VIEW: 'View',
  ORGANIZATION_USER_DETAIL_CONTACT_EDIT: 'Edit',
  ORGANIZATION_USER_DETAIL_BANK: 'Bank',
  ORGANIZATION_USER_DETAIL_BANK_VIEW: 'View',
  ORGANIZATION_USER_DETAIL_BANK_EDIT: 'Edit',
  ORGANIZATION_USER_DETAIL_WORK_INFO: 'Work Info',
  ORGANIZATION_USER_DETAIL_WORK_INFO_LOCATION_GROUP_TEAM: 'Location/Group/Team',
  ORGANIZATION_USER_DETAIL_WORK_INFO_LOCATION_GROUP_TEAM_VIEW: 'View',
  ORGANIZATION_USER_DETAIL_WORK_INFO_LOCATION_GROUP_TEAM_EDIT: 'Edit',
  ORGANIZATION_USER_DETAIL_WORK_INFO_JOB_DATES: 'Job Dates',
  ORGANIZATION_USER_DETAIL_WORK_INFO_JOB_DATES_VIEW: 'View',
  ORGANIZATION_USER_DETAIL_WORK_INFO_JOB_DATES_EDIT: 'Edit',
  ORGANIZATION_USER_DETAIL_LEAVE_HISTORY: 'Leave History',
  ORGANIZATION_USER_DETAIL_LEAVE_HISTORY_LEAVE_HISTORY: 'Leave History',
  ORGANIZATION_USER_DETAIL_LEAVE_HISTORY_LEAVE_HISTORY_VIEW: 'View',
  ORGANIZATION_USER_DETAIL_LEAVE_HISTORY_LEAVE_HISTORY_EDIT: 'Edit',
  ORGANIZATION_USER_DETAIL_REPORT_HISTORY: 'Report History',
  ORGANIZATION_USER_DETAIL_REPORT_HISTORY_REPORT_HISTORY: 'Report History',
  ORGANIZATION_USER_DETAIL_REPORT_HISTORY_REPORT_HISTORY_VIEW: 'View',
  ORGANIZATION_USER_DETAIL_REPORT_HISTORY_REPORT_HISTORY_EDIT: 'Edit',
  ORGANIZATION_USER_DETAIL_POLICIES: 'Policies',
  ORGANIZATION_USER_DETAIL_POLICIES_POLICIES: 'Policies',
  ORGANIZATION_USER_DETAIL_POLICIES_POLICIES_VIEW: 'View',
  ORGANIZATION_USER_DETAIL_POLICIES_POLICIES_EDIT: 'Edit',
  ORGANIZATION_USER_DETAIL_ONBOARD_MISSIONS: 'Onboard Missions',
  ORGANIZATION_USER_DETAIL_ONBOARD_MISSIONS_ONBOARD_MISSIONS:
    'Onboard Missions',
  ORGANIZATION_USER_DETAIL_ONBOARD_MISSIONS_ONBOARD_MISSIONS_VIEW: 'View',
  ORGANIZATION_USER_DETAIL_ONBOARD_MISSIONS_ONBOARD_MISSIONS_EDIT: 'Edit',
  ORGANIZATION_USER_DETAIL_SALARY_SETTING: 'Salary Setting',
  ORGANIZATION_USER_DETAIL_SALARY_SETTING_SALARY_SETTING: 'Salary Setting',
  ORGANIZATION_USER_DETAIL_SALARY_SETTING_SALARY_SETTING_VIEW: 'View',
  ORGANIZATION_USER_DETAIL_SALARY_SETTING_SALARY_SETTING_EDIT: 'Edit',
  ORGANIZATION_USER_DETAIL_NOTIFICATION: 'Notification',
  ORGANIZATION_USER_DETAIL_NOTIFICATION_NOTIFICATION: 'Notification',
  ORGANIZATION_USER_DETAIL_NOTIFICATION_NOTIFICATION_VIEW: 'View',
  ORGANIZATION_USER_DETAIL_NOTIFICATION_NOTIFICATION_EDIT: 'Edit',
  ORGANIZATION_LOCATION: 'Location',
  ORGANIZATION_LOCATION_VIEW: 'View',
  ORGANIZATION_LOCATION_EDIT: 'Edit',
  ORGANIZATION_TEAM: 'Team',
  ORGANIZATION_TEAM_VIEW: 'View',
  ORGANIZATION_TEAM_EDIT: 'Edit',
  ORGANIZATION_GROUP: 'Group',
  ORGANIZATION_GROUP_VIEW: 'View',
  ORGANIZATION_GROUP_EDIT: 'Edit',
  ORGANIZATION_USER: 'User',
  ORGANIZATION_USER_VIEW: 'View',
  ORGANIZATION_USER_EDIT: 'Edit',
  ORGANIZATION_SHIFT: 'Shift',
  ORGANIZATION_SHIFT_SHIFT: 'Shift',
  ORGANIZATION_SHIFT_SHIFT_VIEW: 'View',
  ORGANIZATION_SHIFT_SHIFT_EDIT: 'Edit',
  ORGANIZATION_SHIFT_SHIFT_PROFILE: 'Shift Profile',
  ORGANIZATION_SHIFT_SHIFT_PROFILE_VIEW: 'View',
  ORGANIZATION_SHIFT_SHIFT_PROFILE_EDIT: 'Edit',
  ORGANIZATION_ROLE_PERMISSION: 'Role & Permission',
  ORGANIZATION_ROLE_PERMISSION_VIEW: 'View',
  ORGANIZATION_ROLE_PERMISSION_EDIT: 'Edit',
  ORGANIZATION_HOLIDAY: 'Holiday',
  ORGANIZATION_HOLIDAY_VIEW: 'View',
  ORGANIZATION_HOLIDAY_EDIT: 'Edit',
  ORGANIZATION_INTEGRATION: 'Integration',
  ORGANIZATION_INTEGRATION_SLACK: 'Slack',
  ORGANIZATION_INTEGRATION_SLACK_VIEW: 'View',
  ORGANIZATION_INTEGRATION_SLACK_EDIT: 'Edit',
  ORGANIZATION_INTEGRATION_JIRA: 'Jira',
  ORGANIZATION_INTEGRATION_JIRA_VIEW: 'View',
  ORGANIZATION_INTEGRATION_JIRA_EDIT: 'Edit',
  ORGANIZATION_BILLING: 'Billing',
  ORGANIZATION_BILLING_CURRENT_PLAN: 'Current Plan',
  ORGANIZATION_BILLING_CURRENT_PLAN_VIEW: 'View',
  ORGANIZATION_BILLING_CURRENT_PLAN_EDIT: 'Edit',
  ORGANIZATION_BILLING_NEXT_CHARGE_ESTIMATE: 'Next Charge Estimate',
  ORGANIZATION_BILLING_NEXT_CHARGE_ESTIMATE_VIEW: 'View',
  ORGANIZATION_BILLING_NEXT_CHARGE_ESTIMATE_EDIT: 'Edit',
  ORGANIZATION_BILLING_INFORMATION: 'Billing Information',
  ORGANIZATION_BILLING_INFORMATION_VIEW: 'View',
  ORGANIZATION_BILLING_INFORMATION_EDIT: 'Edit',
  ORGANIZATION_BILLING_HISTORY: 'Bill History',
  ORGANIZATION_BILLING_HISTORY_VIEW: 'View',
  ORGANIZATION_BILLING_HISTORY_EDIT: 'Edit',
  ORGANIZATION_BILLING_PAYMENT_METHOD: 'Payment Method',
  ORGANIZATION_BILLING_PAYMENT_METHOD_VIEW: 'View',
  ORGANIZATION_BILLING_PAYMENT_METHOD_EDIT: 'Edit',
  ORGANIZATION_BILLING_BILLING: 'Billing',
  ORGANIZATION_BILLING_BILLING_VIEW: 'View',
  ORGANIZATION_BILLING_BILLING_EDIT: 'Edit',
  ORGANIZATION_ORGANIZATION_TREE: 'Organization Tree',
  ORGANIZATION_ORGANIZATION_TREE_ORGANIZATION_TREE: 'Organization Tree',
  ORGANIZATION_ORGANIZATION_TREE_ORGANIZATION_TREE_VIEW: 'View',
  ORGANIZATION_ORGANIZATION_TREE_ORGANIZATION_TREE_EDIT: 'Edit',
  ORGANIZATION_ORGANIZATION_TREE_COMPANY_STRUCTURE: 'Company Structure',
  ORGANIZATION_ORGANIZATION_TREE_COMPANY_STRUCTURE_VIEW: 'View',
  ORGANIZATION_ORGANIZATION_TREE_COMPANY_STRUCTURE_EDIT: 'Edit',
  ORGANIZATION_ORGANIZATION_TREE_JOB_TITLE: 'Job Title',
  ORGANIZATION_ORGANIZATION_TREE_JOB_TITLE_VIEW: 'View',
  ORGANIZATION_ORGANIZATION_TREE_JOB_TITLE_EDIT: 'Edit',
  HR: 'HR',
  HR_HIRING_BOARD: 'Hiring Board',
  HR_HIRING_BOARD_HIRING_BOARD: 'Hiring Board',
  HR_HIRING_BOARD_HIRING_BOARD_VIEW: 'View',
  HR_HIRING_BOARD_HIRING_BOARD_EDIT: 'Edit',
  HR_HIRING_BOARD_CV_DETAIL: 'CV Detail',
  HR_HIRING_BOARD_CV_DETAIL_VIEW: 'View',
  HR_HIRING_BOARD_CV_DETAIL_EDIT: 'Edit',
  HR_POLICY: 'Policy',
  HR_POLICY_POLICY: 'Policy',
  HR_POLICY_POLICY_VIEW: 'View',
  HR_POLICY_POLICY_EDIT: 'Edit',
  HR_POLICY_BENEFIT_RULE: 'Benefit/Rule',
  HR_POLICY_BENEFIT_RULE_VIEW: 'View',
  HR_POLICY_BENEFIT_RULE_EDIT: 'Edit',
  HR_ONBOARD_MANAGEMENT: 'Onboard Management',
  HR_ONBOARD_MANAGEMENT_VIEW: 'View',
  HR_ONBOARD_MANAGEMENT_EDIT: 'Edit',
  HR_SETTINGS: 'Settings',
  HR_SETTINGS_HR_MEMBER: 'HR Member',
  HR_SETTINGS_HR_MEMBER_VIEW: 'View',
  HR_SETTINGS_HR_MEMBER_EDIT: 'Edit',
  HR_SETTINGS_ONBOARD_MEMBER: 'Onboard Member',
  HR_SETTINGS_ONBOARD_MEMBER_VIEW: 'View',
  HR_SETTINGS_ONBOARD_MEMBER_EDIT: 'Edit',
  HR_SETTINGS_ONBOARD_MANAGEMENT: 'Onboard Management',
  HR_SETTINGS_ONBOARD_MANAGEMENT_VIEW: 'View',
  HR_SETTINGS_ONBOARD_MANAGEMENT_EDIT: 'Edit',
  HR_SETTINGS_HIRING_BOARD: 'Hiring Board',
  HR_SETTINGS_HIRING_BOARD_VIEW_LIST_STATUS: 'View',
  HR_SETTINGS_HIRING_BOARD_ADD_COLUMN_STATUS: 'Edit',
  HR_SETTINGS_HIRING_BOARD_VIEW: 'View',
  HR_SETTINGS_HIRING_BOARD_EDIT: 'Edit',
  HR_SALARY_PROFILE: 'Salary Profile',
  HR_SALARY_PROFILE_VIEW: 'View',
  HR_SALARY_PROFILE_EDIT: 'Edit',
  HR_PAY_SCHEDULE: 'Pay Schedule',
  HR_PAY_SCHEDULE_VIEW: 'View',
  HR_PAY_SCHEDULE_EDIT: 'Edit',
  HR_PAYROLL: 'Payroll',
  HR_PAYROLL_VIEW: 'View',
  HR_PAYROLL_EDIT: 'Edit',
  AUDIT_LOG: 'Audit Log',
  AUDIT_LOG_VIEW: 'View',
  AUDIT_LOG_EDIT: 'Edit',
  SETTINGS: 'Settings',
  SETTINGS_DYNAMIC_FIELD: 'Dynamic Field',
  SETTINGS_DYNAMIC_FIELD_VIEW: 'View',
  SETTINGS_DYNAMIC_FIELD_EDIT: 'Edit',
  SETTINGS_LANGUAGE: 'Language',
  SETTINGS_LANGUAGE_VIEW: 'View',
  SETTINGS_LANGUAGE_EDIT: 'Edit',
  SETTINGS_NOTIFICATIONS: 'Notifications',
  SETTINGS_NOTIFICATIONS_VIEW: 'View',
  SETTINGS_NOTIFICATIONS_EDIT: 'Edit',
  SETTINGS_DOCUMENT_TEMPLATE: 'Document Template',
  SETTINGS_DOCUMENT_TEMPLATE_VIEW: 'View',
  SETTINGS_DOCUMENT_TEMPLATE_EDIT: 'Edit',
  SETTINGS_EVENT_LOCATION: 'Event Location',
  SETTINGS_EVENT_LOCATION_VIEW: 'View',
  SETTINGS_EVENT_LOCATION_EDIT: 'Edit',
  CUSTOMERS: 'Customers',
  CUSTOMERS_VIEW: 'View',
  CUSTOMERS_EDIT: 'Edit',

  ORGANIZATION_USER_DETAIL_WORK_INFO_LOCATION_GROUP_TEAM_VIEW_OTHER: 'View Another',
  ORGANIZATION_USER_DETAIL_WORK_INFO_LOCATION_GROUP_TEAM_EDIT_OTHER: 'Edit Another',
  ORGANIZATION_USER_DETAIL_WORK_INFO_LOCATION_GROUP_TEAM_VIEW_OTHER_ADVANCE: 'View Another Advance',
  ORGANIZATION_USER_DETAIL_WORK_INFO_LOCATION_GROUP_TEAM_EDIT_OTHER_ADVANCE: 'Edit Another Advance',
  ORGANIZATION_USER_DETAIL_WORK_INFO_JOB_DATES_VIEW_OTHER: 'View Another',
  ORGANIZATION_USER_DETAIL_WORK_INFO_JOB_DATES_EDIT_OTHER: 'Edit Another',
  ORGANIZATION_USER_DETAIL_WORK_INFO_JOB_DATES_VIEW_OTHER_ADVANCE: 'View Another Advance',
  ORGANIZATION_USER_DETAIL_WORK_INFO_JOB_DATES_EDIT_OTHER_ADVANCE: 'Edit Another Advance',
  ORGANIZATION_USER_DETAIL_LEAVE_HISTORY_LEAVE_HISTORY_VIEW_OTHER: 'View Another',
  ORGANIZATION_USER_DETAIL_LEAVE_HISTORY_LEAVE_HISTORY_EDIT_OTHER: 'Edit Another',
  ORGANIZATION_USER_DETAIL_LEAVE_HISTORY_LEAVE_HISTORY_VIEW_OTHER_ADVANCE: 'View Another Advance',
  ORGANIZATION_USER_DETAIL_LEAVE_HISTORY_LEAVE_HISTORY_EDIT_OTHER_ADVANCE: 'Edit Another Advance',
  ORGANIZATION_USER_DETAIL_REPORT_HISTORY_REPORT_HISTORY_VIEW_OTHER: 'View Another',
  ORGANIZATION_USER_DETAIL_REPORT_HISTORY_REPORT_HISTORY_EDIT_OTHER: 'Edit Another',
  ORGANIZATION_USER_DETAIL_REPORT_HISTORY_REPORT_HISTORY_VIEW_OTHER_ADVANCE: 'View Another Advance',
  ORGANIZATION_USER_DETAIL_REPORT_HISTORY_REPORT_HISTORY_EDIT_OTHER_ADVANCE: 'Edit Another Advance',
  ORGANIZATION_USER_DETAIL_CONTACT_VIEW_OTHER: 'View Another',
  ORGANIZATION_USER_DETAIL_CONTACT_EDIT_OTHER: 'Edit Another',
  ORGANIZATION_USER_DETAIL_CONTACT_VIEW_OTHER_ADVANCE: 'View Another Advance',
  ORGANIZATION_USER_DETAIL_CONTACT_EDIT_OTHER_ADVANCE: 'Edit Another Advance',
  ORGANIZATION_USER_DETAIL_BANK_VIEW_OTHER: 'View Another',
  ORGANIZATION_USER_DETAIL_BANK_EDIT_OTHER: 'Edit Another',
  ORGANIZATION_USER_DETAIL_BANK_VIEW_OTHER_ADVANCE: 'View Another Advance',
  ORGANIZATION_USER_DETAIL_BANK_EDIT_OTHER_ADVANCE: 'Edit Another Advance',
  ORGANIZATION_USER_DETAIL_PERSONAL_BASIC_INFO_VIEW_OTHER: 'View Another',
  ORGANIZATION_USER_DETAIL_PERSONAL_BASIC_INFO_EDIT_OTHER: 'Edit Another',
  ORGANIZATION_USER_DETAIL_PERSONAL_BASIC_INFO_VIEW_OTHER_ADVANCE: 'View Another Advance',
  ORGANIZATION_USER_DETAIL_PERSONAL_BASIC_INFO_EDIT_OTHER_ADVANCE: 'Edit Another Advance',
  ORGANIZATION_USER_DETAIL_POLICIES_POLICIES_VIEW_OTHER: 'View Another',
  ORGANIZATION_USER_DETAIL_POLICIES_POLICIES_EDIT_OTHER: 'Edit Another',
  ORGANIZATION_USER_DETAIL_POLICIES_POLICIES_VIEW_OTHER_ADVANCE: 'View Another Advance',
  ORGANIZATION_USER_DETAIL_POLICIES_POLICIES_EDIT_OTHER_ADVANCE: 'Edit Another Advance',
  ORGANIZATION_USER_DETAIL_ONBOARD_MISSIONS_ONBOARD_MISSIONS_VIEW_OTHER: 'View Another',
  ORGANIZATION_USER_DETAIL_ONBOARD_MISSIONS_ONBOARD_MISSIONS_EDIT_OTHER: 'Edit Another',
  ORGANIZATION_USER_DETAIL_ONBOARD_MISSIONS_ONBOARD_MISSIONS_VIEW_OTHER_ADVANCE: 'View Another Advance',
  ORGANIZATION_USER_DETAIL_ONBOARD_MISSIONS_ONBOARD_MISSIONS_EDIT_OTHER_ADVANCE: 'Edit Another Advance',
  ORGANIZATION_USER_DETAIL_SALARY_SETTING_SALARY_SETTING_VIEW_OTHER: 'View Another',
  ORGANIZATION_USER_DETAIL_SALARY_SETTING_SALARY_SETTING_EDIT_OTHER: 'Edit Another',
  ORGANIZATION_USER_DETAIL_SALARY_SETTING_SALARY_SETTING_VIEW_OTHER_ADVANCE: 'View Another Advance',
  ORGANIZATION_USER_DETAIL_SALARY_SETTING_SALARY_SETTING_EDIT_OTHER_ADVANCE: 'Edit Another Advance',
  ORGANIZATION_USER_DETAIL_NOTIFICATION_NOTIFICATION_VIEW_OTHER: 'View Another',
  ORGANIZATION_USER_DETAIL_NOTIFICATION_NOTIFICATION_EDIT_OTHER: 'Edit Another',
  ORGANIZATION_USER_DETAIL_NOTIFICATION_NOTIFICATION_VIEW_OTHER_ADVANCE: 'View Another Advance',
  ORGANIZATION_USER_DETAIL_NOTIFICATION_NOTIFICATION_EDIT_OTHER_ADVANCE: 'Edit Another Advance',
  ORGANIZATION_INTEGRATION_VIEW: 'View',
  ORGANIZATION_INTEGRATION_EDIT: 'Edit',
  TASK_MANAGEMENT_PROJECT_SETTING_SWIMLANES: 'Swimlane',
  TASK_MANAGEMENT_PROJECT_SETTING_SWIMLANES_VIEW: 'View',
  TASK_MANAGEMENT_PROJECT_SETTING_SWIMLANES_EDIT: 'Edit',
  TASK_MANAGEMENT_PROJECT_SETTING_GIT: 'Git',
  TASK_MANAGEMENT_PROJECT_SETTING_GIT_VIEW: 'View',
  TASK_MANAGEMENT_PROJECT_SETTING_GIT_EDIT: 'Edit',
  TASK_MANAGEMENT_PROJECT_SETTING_SLACK_INTEGRATION: 'Slack Integration',
  TASK_MANAGEMENT_PROJECT_SETTING_SLACK_INTEGRATION_VIEW: 'View',
  TASK_MANAGEMENT_PROJECT_SETTING_SLACK_INTEGRATION_EDIT: 'Edit',
  TASK_MANAGEMENT_TASK_FINDER: 'Task Finder',
  TASK_MANAGEMENT_TASK_FINDER_FILTER: 'Filter',
  TASK_MANAGEMENT_TASK_FINDER_FILTER_MY_FILTER: 'My Filters',
  TASK_MANAGEMENT_TASK_FINDER_FILTER_MY_FILTER_VIEW: 'View',
  TASK_MANAGEMENT_TASK_FINDER_FILTER_MY_FILTER_EDIT: 'Edit',
  TASK_MANAGEMENT_TASK_FINDER_FILTER_SHARED_FILTER: 'Shared Filter',
  TASK_MANAGEMENT_TASK_FINDER_FILTER_SHARED_FILTER_VIEW: 'View',
  TASK_MANAGEMENT_TASK_FINDER_FILTER_SHARED_FILTER_EDIT: 'Edit',
  TASK_MANAGEMENT_TASK_FINDER_VIEW: 'View',
  TASK_MANAGEMENT_TASK_FINDER_EDIT: 'Edit',
  USER_DASHBOARD_NEED_FEEDBACK: 'Need Feedback',
  USER_DASHBOARD_NEED_FEEDBACK_VIEW: 'View',
  USER_DASHBOARD_NEED_FEEDBACK_EDIT: 'Edit',
  OKR_POPULATE: "Populate",
  OKR_POPULATE_VIEW: "View",
  OKR_POPULATE_EDIT: "Edit",
  OKR_POPULATE_VIEW_OTHER: "View Another",
  OKR_POPULATE_EDIT_OTHER: "Edit Another",
  TASK_MANAGEMENT_GROUP_STATUS: 'Group Status',
  TASK_MANAGEMENT_GROUP_STATUS_EDIT: 'Edit',
  ASSET_MANAGEMENT: 'Asset Management',
  ASSET_MANAGEMENT_VIEW: 'View',
  ASSET_MANAGEMENT_EDIT: 'Edit',
};

export enum permissionOrder {
  // TODO: order of permission code

  // Task Management
  TASK_MANAGEMENT_PROJECTS,
  TASK_MANAGEMENT_TASK_DETAIL,
  TASK_MANAGEMENT_TASK_FINDER,
  TASK_MANAGEMENT_BACKLOG,
  TASK_MANAGEMENT_ACTIVE_SPRINT_KANBAN_BOARD,
  TASK_MANAGEMENT_RELEASE,
  TASK_MANAGEMENT_GIANTT,
  TASK_MANAGEMENT_EPIC,
  TASK_MANAGEMENT_STORYLINE,
  TASK_MANAGEMENT_TASK_TEMPLATE,
  TASK_MANAGEMENT_SPRINT,
  TASK_MANAGEMENT_GROUP_STATUS,
  TASK_MANAGEMENT_PROJECT_SETTING,

  // OKR
  OKR_OKR_SETTINGS_SPACES,
  OKR_OKR_SETTINGS_SPACES_MEMBER,
  OKR_OKR_SETTINGS_TIMELINE,
  OKR_OKR_SETTINGS_METRICS,
  //

  // TODO: order of permission code
}

// export const modulePermissions: PermissionSettingItem[] = [
//   {
//     code: permissionCode.LEAVE,
//     isCollapsed: false,
//     isChecked: false,
//     permissions: [
//       {
//         code: permissionCode.LEAVE_DASHBOARD,
//         isChecked: false,
//       },
//       {
//         code: permissionCode.LEAVE_MY_REQUEST,
//         isChecked: false,
//       },
//       {
//         code: permissionCode.LEAVE_APPROVAL,
//         isChecked: false,
//         isCollapsed: false,
//         permissions: [
//           {
//             code: permissionCode.APPROVAL_VIEW,
//             isChecked: false,
//           },
//           {
//             code: permissionCode.LEAVE_APPROVAL_APPROVE_REJECT,
//             isChecked: false,
//           },
//         ]
//       },
//       {
//         code: permissionCode.LEAVE_BALANCE,
//         isChecked: false,
//         isCollapsed: false,
//         permissions: [
//           {
//             code: permissionCode.LEAVE_BALANCE_VIEW,
//             isChecked: false,
//           },
//           {
//             code: permissionCode.LEAVE_BALANCE_EDIT,
//             isChecked: false,
//           },
//         ]
//       },
//       {
//         code: permissionCode.LEAVE_REPORT,
//         isChecked: false,
//         isCollapsed: false,
//         permissions: [
//           {
//             code: permissionCode.LEAVE_REPORT_VIEW,
//             isChecked: false,
//           },
//           {
//             code: permissionCode.LEAVE_REPORT_EXPORT,
//             isChecked: false,
//           },
//         ]
//       },
//       {
//         code: permissionCode.LEAVE_SETTING_CATEGORY,
//         isChecked: false,
//         isCollapsed: false,
//         permissions: [
//           {
//             code: permissionCode.LEAVE_SETTING_CATEGORY_VIEW,
//             isChecked: false,
//           },
//           {
//             code: permissionCode.LEAVE_SETTING_CATEGORY_EDIT,
//             isChecked: false,
//           },
//         ]
//       },
//       {
//         code: permissionCode.LEAVE_SETTING_TYPE,
//         isChecked: false,
//         isCollapsed: false,
//         permissions: [
//           {
//             code: permissionCode.LEAVE_SETTING_TYPE_VIEW,
//             isChecked: false,
//           },
//           {
//             code: permissionCode.LEAVE_SETTING_TYPE_EDIT,
//             isChecked: false,
//           },
//         ]
//       },
//     ]
//   },
//   {
//     code: permissionCode.WORK_TRACKING,
//     isChecked: false,
//     isCollapsed: false,
//     permissions: [
//       {
//         code: permissionCode.WORK_TRACKING_MY_TRACKING,
//         isChecked: false,
//       },
//       {
//         code: permissionCode.WORK_TRACKING_MANAGER_DASHBOARD,
//         isChecked: false,
//         isCollapsed: false,
//         permissions: [
//           {
//             code: permissionCode.WORK_TRACKING_MANAGER_DASHBOARD_JOINED_PROJECTS,
//             isChecked: false,
//           },
//           {
//             code: permissionCode.WORK_TRACKING_MANAGER_DASHBOARD_ALL_PROJECTS,
//             isChecked: false,
//           }
//         ],
//       },
//       {
//         code: permissionCode.WORK_TRACKING_SETTING,
//         isChecked: false,
//         isCollapsed: false,
//         permissions: [
//           {
//             code: permissionCode.WORK_TRACKING_SETTING_VIEW,
//             isChecked: false,
//           },
//           {
//             code: permissionCode.WORK_TRACKING_SETTING_EDIT,
//             isChecked: false,
//           },
//         ]
//       },
//     ]
//   },
//   {
//     code: permissionCode.TIMESHEET,
//     isChecked: false,
//     isCollapsed: false,
//     permissions: [
//       {
//         code: permissionCode.TIMESHEET_MY_LOGWORK,
//         isChecked: false,
//       },
//       {
//         code: permissionCode.TIMESHEET_REPORTS,
//         isChecked: false,
//         isCollapsed: false,
//         permissions: [
//           {
//             code: permissionCode.TIMESHEET_REPORTS_VIEW_JOINED_PROJECTS,
//             isChecked: false,
//           },
//           {
//             code: permissionCode.TIMESHEET_REPORTS_VIEW_ALL_PROJECTS,
//             isChecked: false,
//           },
//           {
//             code: permissionCode.TIMESHEET_REPORTS_EXPORT,
//             isChecked: false,
//           },
//         ],
//       },
//       {
//         code: permissionCode.TIMESHEET_LOGWORK_FOR_ANOTHER_USER,
//         isChecked: false,
//       },
//     ]
//   },
//   {
//     code: permissionCode.TASK_MANAGEMENT,
//     isChecked: false,
//     isCollapsed: false,
//     permissions: [
//       {
//         code: permissionCode.TASK_MANAGEMENT_PROJECTS,
//         isChecked: false,
//         isCollapsed: false,
//         permissions: [
//           {
//             code: permissionCode.TASK_MANAGEMENT_PROJECTS_VIEW_JOINED_PROJECTS,
//             isChecked: false,
//           },
//           {
//             code: permissionCode.TASK_MANAGEMENT_PROJECTS_VIEW_ALL_PROJECTS,
//             isChecked: false,
//           },
//           {
//             code: permissionCode.TASK_MANAGEMENT_PROJECTS_CREATE,
//             isChecked: false,
//           },
//           {
//             code: permissionCode.TASK_MANAGEMENT_PROJECTS_EDIT_ALL_PROJECTS,
//             isChecked: false,
//           },
//         ],
//       },
//       {
//         code: permissionCode.TASK_MANAGEMENT_RELEASE,
//         isChecked: false,
//         isCollapsed: false,
//         permissions: [
//           {
//             code: permissionCode.TASK_MANAGEMENT_RELEASE_VIEW,
//             isChecked: false,
//           },
//           {
//             code: permissionCode.TASK_MANAGEMENT_RELEASE_EDIT,
//             isChecked: false,
//           },
//         ],
//       },
//       {
//         code: permissionCode.TASK_MANAGEMENT_BACKLOG,
//         isChecked: false,
//         isCollapsed: false,
//         permissions: [
//           {
//             code: permissionCode.TASK_MANAGEMENT_BACKLOG_TASK_EDIT_SPRINT_OR_RELEASE,
//             isChecked: false,
//           },
//           {
//             code: permissionCode.TASK_MANAGEMENT_BACKLOG_DELETE_TASK,
//             isChecked: false,
//           },
//           {
//             code: permissionCode.TASK_MANAGEMENT_BACKLOG_MANAGE_SPRINT,
//             isChecked: false,
//           },
//           {
//             code: permissionCode.TASK_MANAGEMENT_BACKLOG_START_SPRINT,
//             isChecked: false,
//           },
//           {
//             code: permissionCode.TASK_MANAGEMENT_BACKLOG_COMPLETE_SPRINT,
//             isChecked: false,
//           },
//         ],
//       },
//       {
//         code: permissionCode.TASK_MANAGEMENT_EPIC,
//         isChecked: false,
//         isCollapsed: false,
//         permissions: [
//           {
//             code: permissionCode.TASK_MANAGEMENT_EPIC_VIEW,
//             isChecked: false,
//           },
//           {
//             code: permissionCode.TASK_MANAGEMENT_EPIC_CREATE_EDIT,
//             isChecked: false,
//           },
//           {
//             code: permissionCode.TASK_MANAGEMENT_EPIC_DELETE_EPIC,
//             isChecked: false,
//           },
//           {
//             code: permissionCode.TASK_MANAGEMENT_EPIC_ADD_LINK_TASK,
//             isChecked: false,
//           },
//         ],
//       },
//       {
//         code: permissionCode.TASK_MANAGEMENT_PROJECT_SETTING,
//         isChecked: false,
//         isCollapsed: false,
//         permissions: [
//           {
//             code: permissionCode.TASK_MANAGEMENT_PROJECT_SETTING_GENERAL,
//             isChecked: false,
//             isCollapsed: false,
//             permissions: [
//               {
//                 code: permissionCode.TASK_MANAGEMENT_PROJECT_SETTING_GENERAL_VIEW,
//                 isChecked: false,
//               },
//               {
//                 code: permissionCode.TASK_MANAGEMENT_PROJECT_SETTING_GENERAL_EDIT,
//                 isChecked: false,
//               },
//             ]
//           },
//           {
//             code: permissionCode.TASK_MANAGEMENT_PROJECT_SETTING_MEMBERS,
//             isChecked: false,
//             isCollapsed: false,
//             permissions: [
//               {
//                 code: permissionCode.TASK_MANAGEMENT_PROJECT_SETTING_MEMBERS_VIEW,
//                 isChecked: false,
//               },
//               {
//                 code: permissionCode.TASK_MANAGEMENT_PROJECT_SETTING_MEMBERS_EDIT,
//                 isChecked: false,
//               },
//             ]
//           },
//           {
//             code: permissionCode.TASK_MANAGEMENT_PROJECT_SETTING_BOARD_SETTINGS,
//             isChecked: false,
//             isCollapsed: false,
//             permissions: [
//               {
//                 code: permissionCode.TASK_MANAGEMENT_PROJECT_SETTING_BOARD_SETTINGS_VIEW,
//                 isChecked: false,
//               },
//               {
//                 code: permissionCode.TASK_MANAGEMENT_PROJECT_SETTING_BOARD_SETTINGS_EDIT,
//                 isChecked: false,
//               },
//             ]
//           },
//         ],
//       },
//     ],
//   },
//   {
//     code: permissionCode.DOCUMENT,
//     isChecked: false,
//     isCollapsed: false,
//     permissions: [
//       {
//         code: permissionCode.DOCUMENT_PROJECTS,
//         isChecked: false,
//         isCollapsed: false,
//         permissions: [
//           {
//             code: permissionCode.DOCUMENT_PROJECTS_JOINED_PROJECTS_VIEW,
//             isChecked: false,
//           },
//           {
//             code: permissionCode.DOCUMENT_PROJECTS_JOINED_PROJECTS_ADD_EDIT_FOLDER,
//             isChecked: false,
//           },
//         ],
//       },
//       {
//         code: permissionCode.DOCUMENT_PROJECTS_ALL_PROJECTS,
//         isChecked: false,
//         isCollapsed: false,
//         permissions: [
//           {
//             code: permissionCode.DOCUMENT_PROJECTS_ALL_PROJECTS_VIEW,
//             isChecked: false,
//           },
//           {
//             code: permissionCode.DOCUMENT_PROJECTS_ALL_PROJECTS_ADD_EDIT_FOLDER,
//             isChecked: false,
//           },
//         ],
//       },
//       {
//         code: permissionCode.DOCUMENT_COMPANY,
//         isChecked: false,
//         isCollapsed: false,
//         permissions: [
//           {
//             code: permissionCode.DOCUMENT_COMPANY_VIEW,
//             isChecked: false,
//           },
//           {
//             code: permissionCode.DOCUMENT_COMPANY_ADD_EDIT_FOLDER,
//             isChecked: false,
//           },
//         ],
//       },
//       {
//         code: permissionCode.DOCUMENT_SETTING,
//         isChecked: false,
//       }
//     ],
//   },
//   {
//     code: permissionCode.ORGANIZATION,
//     isChecked: false,
//     isCollapsed: false,
//     permissions: [
//       {
//         code: permissionCode.ORGANIZATION_LOCATION,
//         isChecked: false,
//         isCollapsed: false,
//         permissions: [
//           {
//             code: permissionCode.ORGANIZATION_LOCATION_VIEW,
//             isChecked: false,
//           },
//           {
//             code: permissionCode.ORGANIZATION_LOCATION_EDIT,
//             isChecked: false,
//           },
//         ],
//       },
//       {
//         code: permissionCode.ORGANIZATION_TEAM,
//         isChecked: false,
//         isCollapsed: false,
//         permissions: [
//           {
//             code: permissionCode.ORGANIZATION_TEAM_VIEW,
//             isChecked: false,
//           },
//           {
//             code: permissionCode.ORGANIZATION_TEAM_EDIT,
//             isChecked: false,
//           },
//         ],
//       },
//       {
//         code: permissionCode.ORGANIZATION_GROUP,
//         isChecked: false,
//         isCollapsed: false,
//         permissions: [
//           {
//             code: permissionCode.ORGANIZATION_GROUP_VIEW,
//             isChecked: false,
//           },
//           {
//             code: permissionCode.ORGANIZATION_GROUP_EDIT,
//             isChecked: false,
//           },
//         ],
//       },
//       {
//         code: permissionCode.ORGANIZATION_USER,
//         isChecked: false,
//         isCollapsed: false,
//         permissions: [
//           {
//             code: permissionCode.ORGANIZATION_USER_VIEW,
//             isChecked: false,
//           },
//           {
//             code: permissionCode.ORGANIZATION_USER_EDIT,
//             isChecked: false,
//           },
//         ],
//       },
//       {
//         code: permissionCode.ORGANIZATION_SHIFT,
//         isChecked: false,
//         isCollapsed: false,
//         permissions: [
//           {
//             code: permissionCode.ORGANIZATION_SHIFT_VIEW,
//             isChecked: false,
//           },
//           {
//             code: permissionCode.ORGANIZATION_SHIFT_EDIT,
//             isChecked: false,
//           },
//         ],
//       },
//       {
//         code: permissionCode.ORGANIZATION_SHIFT_PROFILE,
//         isChecked: false,
//         isCollapsed: false,
//         permissions: [
//           {
//             code: permissionCode.ORGANIZATION_SHIFT_PROFILE_VIEW,
//             isChecked: false,
//           },
//           {
//             code: permissionCode.ORGANIZATION_SHIFT_PROFILE_EDIT,
//             isChecked: false,
//           },
//         ],
//       },
//       {
//         code: permissionCode.ORGANIZATION_ROLE,
//         isChecked: false,
//         isCollapsed: false,
//         permissions: [
//           {
//             code: permissionCode.ORGANIZATION_ROLE_VIEW,
//             isChecked: false,
//           },
//           {
//             code: permissionCode.ORGANIZATION_ROLE_EDIT,
//             isChecked: false,
//           },
//         ],
//       },
//       {
//         code: permissionCode.ORGANIZATION_PERMISSION,
//         isChecked: false,
//         isCollapsed: false,
//         permissions: [
//           {
//             code: permissionCode.ORGANIZATION_PERMISSION_VIEW,
//             isChecked: false,
//           },
//           {
//             code: permissionCode.ORGANIZATION_PERMISSION_EDIT,
//             isChecked: false,
//           },
//         ],
//       },
//       {
//         code: permissionCode.ORGANIZATION_HOLIDAY,
//         isChecked: false,
//         isCollapsed: false,
//         permissions: [
//           {
//             code: permissionCode.ORGANIZATION_HOLIDAY_VIEW,
//             isChecked: false,
//           },
//           {
//             code: permissionCode.ORGANIZATION_HOLIDAY_EDIT,
//             isChecked: false,
//           },
//         ],
//       },
//       {
//         code: permissionCode.ORGANIZATION_INTEGRATION,
//         isChecked: false,
//         isCollapsed: false,
//         permissions: [
//           {
//             code: permissionCode.ORGANIZATION_INTEGRATION_VIEW,
//             isChecked: false,
//           },
//           {
//             code: permissionCode.ORGANIZATION_INTEGRATION_EDIT,
//             isChecked: false,
//           },
//         ],
//       },
//       {
//         code: permissionCode.ORGANIZATION_BILLING_OVERVIEW,
//         isChecked: false,
//         isCollapsed: false,
//         permissions: [
//           {
//             code: permissionCode.ORGANIZATION_BILLING_OVERVIEW_VIEW,
//             isChecked: false,
//           },
//           {
//             code: permissionCode.ORGANIZATION_BILLING_OVERVIEW_EDIT,
//             isChecked: false,
//           },
//         ],
//       },
//     ],
//   },
// ];
