import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ColumnsDropdownComponent } from './columns-dropdown.component';
import { SearchInputModule } from '../search-input/search-input.module';
import { DropdownModule } from '../dropdown/dropdown.module';
import { XPipesModule } from 'src/@xcorp/pipes/x-pipes.module';
import { I18nComponent } from '../i18n/i18n.component';
import { CdkDropList, CdkDrag, CdkDragHandle } from '@angular/cdk/drag-drop';
@NgModule({
  declarations: [ColumnsDropdownComponent],
  imports: [
    CommonModule,
    I18nComponent,
    SearchInputModule,
    DropdownModule,
    XPipesModule,
    CdkDrag,
    CdkDragHandle,
    CdkDropList
  ],
  exports: [ColumnsDropdownComponent]
})
export class ColumnsDropdownModule {}
