import { Component, Input, OnInit } from '@angular/core';
import { Group } from 'src/app/shared/_models';
import { GroupService } from 'src/app/site-management/group/_services/group.service';
import { SearchOverlayBaseComponent } from '../search-overlay-base.component';
import { OPTIONS_PER_PAGE } from 'src/app/shared/_utils/consts';

@Component({
  selector: 'app-search-overlay-group',
  templateUrl: './search-overlay-group.component.html',
  styleUrls: ['./search-overlay-group.component.scss']
})
export class SearchOverlayGroupComponent extends SearchOverlayBaseComponent<Group> {
  @Input() displayLabels = 2;

  constructor(
    private groupService: GroupService,
  ) {
    super();
    this.config.title = 'Group';
    this.config.multiple = true;
  }

  getSearchFn() {
    return (keyword?: string) => {
      this.config.searchSubscription?.unsubscribe();
      this.config.loading = true;
      const query = `page=0&size=${OPTIONS_PER_PAGE}&keyword=${keyword}`;
      this.config.searchSubscription = this.groupService.getWithQuery(query).subscribe(
        (res) => {
          this.config.items = res;
          this.config.loading = false;
        },
        (error) => {
          this.config.items = [];
          this.config.loading = false;
        }
      );
    };
  }

  getGroupLabels() {
    return this.config.selecteds.map(e => e.name);
  }
}
