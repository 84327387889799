import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { CustomListColumn, IconType } from 'src/app/shared';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'x-columns-dropdown',
  templateUrl: './columns-dropdown.component.html',
  styleUrls: ['./columns-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ColumnsDropdownComponent {
  @Input() dropdownColumns: CustomListColumn[] = [];
  @Input() refreshDropdownAfterClick: boolean = false;

  @Output() change = new EventEmitter<CustomListColumn[]>();
  @Output() updateConfig = new EventEmitter<Record<string, boolean>>();

  keyword: string;
  type = IconType;

  constructor(private _cdr : ChangeDetectorRef) {}

  onSearchColumn(keyword: string) {
    this.keyword = keyword;
  }

  updateColumn(column: CustomListColumn) {
    const isSelect = column.selected !== false;
    column.selected = !isSelect;
    this.change.emit(this.dropdownColumns);
    this.updateConfig.emit(this.getColumnConfig(this.dropdownColumns));
  }

  drop(event: CdkDragDrop<CustomListColumn[]>) {
    moveItemInArray(
      this.dropdownColumns,
      event.previousIndex,
      event.currentIndex
    );
    this.updateConfig.emit(this.getColumnConfig(this.dropdownColumns));
  }
  getColumnConfig(columns: CustomListColumn[]) {
    return columns.reduce(
      (res, cur) => ({ ...res, [cur.key]: cur.selected }),
      {}
    );
  }
}
