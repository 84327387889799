import { UntypedFormControl } from "@angular/forms";
import { CustomListColumn, DatePeriod, FilterItem, Group, Metrics, Okr, OkrIndicator, OkrKeyResult, OkrMetricReport, OkrMetricSearchRequest, OkrMyFollowUp, OkrObjectiveType, OkrTask, OkrTimelineEntity, OkrWorkspace, SelectOption, Team, User } from "src/app/shared";

export const OKR_DEFAULT_SORT = 'tree.nestedSet.lft,asc';
export const OKR_CUSTOM_PERIOD = 'Custom Date';
export const OKR_ALL_YEAR_PERIOD = 'All Year';

export interface OkrChildTimelineForm {
  timeline: OkrTimelineEntity;
  current?: number;
  expected?: number;
  start?: number;
  justify?: number;
}

export enum OkrTemplateStatus {
  RUNNING = 'RUNNING',
  COMPLETED = 'COMPLETED',
}


export enum MemberType {
  GROUP = 'group',
  TEAM = 'team',
  USER = 'user'
}

export enum TimelineLevelType {
  MULTI_YEAR = 'MULTI_YEAR',
  YEARLY = 'YEARLY',
  SEMIANNUAL = 'SEMI_ANNUAL',
  QUARTERLY= 'QUARTERLY',
  MONTHLY= 'MONTHLY',
  WEEKLY= 'WEEKLY',
  DAILY= 'DAILY',
}

export enum OkrBlockViewMode {
  ROW = 'ROW',
  BLOCK = 'BLOCK',
}

export enum OkrMetricViewMode {
  FULL = 'FULL',
  SHORT = 'SHORT',
}

export enum OkrProgressType {
  BLOCK = 'BLOCK',
  LINE = 'LINE',
  CIRCLE = 'CIRCLE'
}

export enum OkrTimelineView {
  BLOCK = 'BLOCK',
  ROW = 'ROW',
}

export enum OkrValueMode {
  FULL = 'FULL',
  ONLY_VALUE = 'ONLY_VALUE',
}

export enum TimelineLevelLabel {
  YEARLY = 'Yearly',
  SEMIANNUAL = 'Semi-Annual',
  QUARTERLY= 'Quarterly',
  MONTHLY= 'Monthly',
  WEEKLY= 'Weekly',
  DAILY= 'Daily',
}

export class TimelineLevelTypeOptions {
  static get options(): SelectOption<TimelineLevelType>[] {
    return Object.keys(TimelineLevelLabel).map((key) => {
      return {
        name: TimelineLevelLabel[key],
        value: TimelineLevelType[key]
      };
    });
  }
}

export enum OkrMyBoardStatus {
  TO_DO= 'TO_DO',
  IN_PROGRESS= 'IN_PROGRESS',
  COMPLETED= 'COMPLETED',
  KEY_RESULT_UN_CHECKIN= 'KEY_RESULT_UN_CHECKIN',
}

export interface OkrMyBoardStatusData extends OkrMyBoardStatusReport {
  label: string;
  colorCode: string;
}

export interface TimelineTreeItem {
  id?: number;
  title: string;
  startDate: string;
  endDate: string;
  timelineCategory: TimelineLevelType;
  children?: TimelineTreeItem[];
  workspaceId?: number;
  deleted?: boolean;
  //
  _isCollapse?: boolean;
}


export enum OkrType {
  INCREMENTAL = 'INCREMENTAL',
  AVERAGE = 'AVERAGE',
  SUM = 'SUM',
}

export const okrTypes: SelectOption[] = [
  {
    name: 'SUM',
    value: OkrType.SUM,
  },
  {
    name: 'AVG',
    value: OkrType.AVERAGE,
  },
  {
    name: 'INC',
    value: OkrType.INCREMENTAL,
  },
];

export const EXPECTED_PERCENT_DEFAULT = 100;

export const OKR_ROW_FIRST_LEVEL = 1;

export enum OkrMyBoardtype {
  KPI = 'KPI',
  OBJECTIVE = 'OBJECTIVE',
}

export interface OkrMyBoardPayload {
  timelineIds: number[];
  type: OkrMyBoardtype;
  unCheckin?: boolean;
  indicators?: OkrIndicator[];
  metricFilters?: OkrMetricSearchRequest[];
  searchText?: string;
  statusReports?: OkrMyBoardStatus[];
}

export interface OkrCheckCategory {
  objective: boolean;
  kpi: boolean;
}


export interface OkrCheckIndicatorRequest {
  workspaceId: number;
  year: number;
}


export interface OkrMyBoardFilter {
  control: UntypedFormControl;
  items: OkrTimelineEntity[];
  hidden?: boolean;
}

export interface OkrMyBoardSettings {
  version: string;
  viewOptions: OkrMyBoardCacheOptions;
  kpi: OkrMyBoardCacheTab;
  objective: OkrMyBoardCacheTab;
  filterBy?: {
    indicator?: { name: string, value: OkrIndicator }[];
  }
}

export interface OkrMyBoardCacheTab {
  unpinMetrics?: Metrics[],
}

export interface OkrMyBoardCacheOptions {
  viewKpi: boolean;
  viewObjective: boolean;
  viewUncheckin: boolean;
}

export interface OkrMyBoardReportRequest {
  workspaceId: number;
  timelineId: number;
  order?: string;
  type: OkrMyBoardtype;
  unCheckin?: boolean;
  indicators?: OkrIndicator[];
  searchText?: string;
}

export interface OkrMyBoardReport {
  metrics: OkrMetricReport[];
  statusReports: OkrMyBoardStatusReport[];
}

export interface OkrMyBoardStatusReport {
  status?: OkrMyBoardStatus;
  total?: number;
  totalKeyResult?: number;
}

export enum OkrColumnKey {
  NAME = 'NAME',
  DESCRIPTION = 'DESCRIPTION',
  USER = 'USER',
  GROUP = 'GROUP',
  TEAM = 'TEAM',
  ASSIGN_TO = 'ASSIGN TO',
  METRIC = 'METRIC',
  RESULT = 'RESULT',
  PROGRESS = 'PROGRESS',
  DUEDATE = 'DUEDATE',
  TIMELINE = 'TIMELINE',
  ACTION = 'ACTION',
}

export enum OkrColumnLabel {
  NAME = 'Name',
  DESCRIPTION = 'Description',
  USER = 'User',
  GROUP = 'Group',
  TEAM = 'Team',
  ASSIGN_TO = 'Assign To',
  METRIC = 'Metric',
  RESULT = 'Result',
  PROGRESS = 'Progress',
  TIMELINE = 'Timeline',
  DUEDATE = 'Due Date',
  ACTION = 'Actions',
}

export class OkrDefaultColumns {
  static get options(): CustomListColumn[] {
    return Object.keys(OkrColumnKey).map((key) => {
      return {
        name: OkrColumnLabel[key],
        key: OkrColumnKey[key],
        selected: true,
      };
    });
  }

  static get checkInTree(): CustomListColumn[] {
    // show only NAME, RESULT
    const validKeys = [OkrColumnKey.NAME, OkrColumnKey.RESULT];
    return Object.keys(OkrColumnKey).map((key) => {
      const okrKey = OkrColumnKey[key as keyof typeof OkrColumnKey];
      return {
        name: OkrColumnLabel[key],
        key: okrKey,
        selected: validKeys.includes(okrKey),
      };
    });
  }

  static get okrLink(): CustomListColumn[] {
    // show only NAME, USER
    const validKeys = [OkrColumnKey.NAME, OkrColumnKey.USER];
    return Object.keys(OkrColumnKey).map((key) => {
      const okrKey = OkrColumnKey[key as keyof typeof OkrColumnKey];
      return {
        name: OkrColumnLabel[key],
        key: okrKey,
        selected: validKeys.includes(okrKey),
      };
    });
  }
}

export class OkrFilterCache {
  followUp?: SelectOption<OkrMyFollowUp>[];
  objectiveType?: SelectOption<OkrObjectiveType>[];
  assignee?: User[];
  group?: Group[];
  team?: Team[];
  dueDate?: SelectOption<DatePeriod>[];
  indicator?: SelectOption<OkrIndicator>[];
  metricFilters?: OkrMetricReport[];
}

export interface OkrCache {
  columns?: CustomListColumn[];
  unpinMetrics?: Metrics[];
  expandedItems?: string[];
}

export interface OkrBoardRequestCache {
  year?: number;
  timeline?: OkrTimelineEntity;
  filter?: OkrFilterCache;
  version?: string;
}

export interface OkrMassUpdateRequest {
  timelineId: number;
  expected: number;
  start: number;
  current?: number;
}
export const OkrAdminRole = 'okr_admin';

export interface OkrMetricPayload {
  metricId: number;
  type: OkrType;
}

export type OkrSelectedStyle = 'default' | 'checkbox';

export interface OkrSelectedItem {
  objective?: Okr;
  kr?: OkrKeyResult;
  task?: OkrTask;
}

export interface OkrToggleTrigger {
  key?: string;
  isExpanded: boolean;
}

export enum OkrSelectType {
  Objective = 'Objective',
  KeyResult = 'Key Result',
  Task = 'Task',
}

export interface OkrSelectedTrigger {
  key?: string;
  id?: number;
  isLinked?: boolean;
}

export interface OkrTemplatePayload {
  batch: {
    teams: number[];
    groups: number[];
    assignees: number[];
  },
  title: string;
  description: string;
  okrTemplateId: number;
  override: boolean;
  year: number;
}

export interface OkrTemplate {
  id: number;
  groups: OkrTemplateMember[];
  teams: OkrTemplateMember[];
  assignees: OkrTemplateMember[];
  title: string;
  description: string;
  okrTemplate: Okr;
  status: OkrTemplateStatus;
}

export interface OkrTemplateMember {
  id: number;
  name: string;
}

export interface OkrMember {
  id: number;
  name: string;
  type: MemberType;
  avatar?: string;
}

export enum OkrDropdownMode {
  SELECTION = 'selection',
  DROPDOWN = 'dropdown',
}
