import { OkrChildTimelineForm, OkrType } from "./okr.model";

export enum TimelineValue {
  CURRENT = 'current',
  EXPECTED = 'expected' ,
  START = 'start'
}

export interface MassUpdateOptions {
  list: OkrChildTimelineForm[];
  item: OkrChildTimelineForm;
  value: number;
  position: TimelineValue;
  type: OkrType;
  populateType?: PopulateType;
  selectedSibling?: OkrChildTimelineForm[];
  divideByLevel?: MassUpdateLevel;
}


export interface MassUpdateItemSibling {
  item: OkrChildTimelineForm;
  checked: boolean;
}


export enum MassUpdateFormCtrlName {
  LATEST_ITEM = "LATEST_ITEM",
  TYPE = 'populateType',
  SELECTED_TIMELINE = 'selected',
  LEVEL = 'level',
}

export enum PopulateType {
  ALL_CHILD = 'ALL_CHILD',
  ALL_SIBLING = 'ALL_SIBLING',
  SELECTED_SIBLING = 'SELECTED_SIBLING',
}

export enum MassUpdateLevel {
  MONTH = 'MONTH',
  WEEK = 'WEEK',
  DAY = 'DAY',
  QUARTER =  'QUARTER'
}

export const MassUpdateLevelList = [
  {
    label: 'Quarter',
    value: MassUpdateLevel.QUARTER
  },
  {
    label: 'Month',
    value: MassUpdateLevel.MONTH
  },
  {
    label: 'Week',
    value: MassUpdateLevel.WEEK
  },
  {
    label: 'Day',
    value: MassUpdateLevel.DAY
  },
];

export const DEFAULT_TIMELINE_LFT = 0;
